import { defineComponent, type App, isVNode, type VNode, type VNodeNormalizedChildren, Fragment } from 'vue';

import PfDataList from './PfDataList.vue';
import PfDataListItem from './PfDataListItem.vue';
import PfDataListCell from './PfDataListCell.vue';
import PfDataListContent from './PfDataListContent.vue';
import PfInputGroup from './PfInputGroup.vue';
import PfFormSelect from './PfFormSelect.vue';
import PfFormSelectOption from './PfFormSelectOption.vue';
import PfSelect from './PfSelect.vue';
import PfSelectOption from './PfSelectOption.vue';
import PfSpinner from './PfSpinner.vue';
import PfSwitch from './PfSwitch.vue';
import PfTab from './PfTab.vue';
import PfTabs from './PfTabs.vue';
import PfToolbarItem from './PfToolbarItem.vue';
import PfTooltip from './PfTooltip.vue';

import PfBullseye from '@vue-patternfly/core/layouts/Bullseye.vue';
import PfFlex from '@vue-patternfly/core/layouts/Flex/Flex.vue';
import PfFlexItem from '@vue-patternfly/core/layouts/Flex/FlexItem.vue';
import PfGallery from '@vue-patternfly/core/layouts/Gallery/Gallery.vue';
import PfGalleryItem from '@vue-patternfly/core/layouts/Gallery/GalleryItem.vue';
import PfGrid from '@vue-patternfly/core/layouts/Grid/Grid.vue';
import PfGridItem from '@vue-patternfly/core/layouts/Grid/GridItem.vue';
import PfLevel from '@vue-patternfly/core/layouts/Level/Level.vue';
import PfLevelItem from '@vue-patternfly/core/layouts/Level/LevelItem.vue';
import PfSplit from '@vue-patternfly/core/layouts/Split/Split.vue';
import PfSplitItem from '@vue-patternfly/core/layouts/Split/SplitItem.vue';
import PfStack from '@vue-patternfly/core/layouts/Stack/Stack.vue';
import PfStackItem from '@vue-patternfly/core/layouts/Stack/StackItem.vue';

export function findChildrenVNodes(vnodes: VNode[] | VNodeNormalizedChildren | undefined): VNode[] {
  if (!Array.isArray(vnodes)) {
    return [];
  }

  return vnodes
    .filter(n => isVNode(n) && n.type !== Comment)
    .map(n => isVNode(n) && n.type === Fragment ? findChildrenVNodes(n.children) : n)
    .flat() as VNode[];
}

// Declare install function executed by Vue.use()
export function install(app: App) {
  delete app._context.components.PfSelect;
  delete app._context.components.PfSpinner;
  delete app._context.components.PfTooltip;

  app.component('PfDataList', PfDataList);
  app.component('PfDataListItem', PfDataListItem);
  app.component('PfDataListCell', PfDataListCell);
  app.component('PfDataListContent', PfDataListContent);
  app.component('PfInputGroup', PfInputGroup);
  app.component('PfFormSelect', PfFormSelect);
  app.component('PfFormSelectOption', PfFormSelectOption);
  app.component('PfSelect', PfSelect);
  app.component('PfSelectOption', PfSelectOption);
  app.component('PfSpinner', PfSpinner);
  app.component('PfSwitch', PfSwitch);
  app.component('PfTab', PfTab);
  app.component('PfTabs', PfTabs);
  app.component('PfToolbarItem', PfToolbarItem);
  app.component('PfTooltip', PfTooltip);

  app.component('PfBullseye', PfBullseye);
  app.component('PfFlex', PfFlex);
  app.component('PfFlexItem', PfFlexItem);
  app.component('PfGallery', PfGallery);
  app.component('PfGalleryItem', PfGalleryItem);
  app.component('PfGrid', PfGrid);
  app.component('PfGridItem', PfGridItem);
  app.component('PfLevel', PfLevel);
  app.component('PfLevelItem', PfLevelItem);
  app.component('PfSplit', PfSplit);
  app.component('PfSplitItem', PfSplitItem);
  app.component('PfStack', PfStack);
  app.component('PfStackItem', PfStackItem);
}

// Create module definition for Vue.use()
const plugin = {
  install,
};

type DisabledComponent = ReturnType<typeof defineComponent<{
  componentDisabled: {
    type: BooleanConstructor;
    required: true;
  };
}>>;

export default plugin;

declare module 'vue' {
  export interface GlobalComponents {
    PfIcon: DisabledComponent;
    PfInputGroup: typeof PfInputGroup;
    PfDataList: typeof PfDataList;
    PfDataListItem: typeof PfDataListItem;
    PfDataListCell: typeof PfDataListCell;
    PfDataListContent: typeof PfDataListContent;
    PfFormSelect: typeof PfFormSelect;
    PfFormSelectOption: typeof PfFormSelectOption;
    PfSelect: typeof PfSelect;
    PfSelectOption: typeof PfSelectOption;
    PfSpinner: typeof PfSpinner;
    PfSwitch: typeof PfSwitch;
    PfTab: typeof PfTab;
    PfTabs: typeof PfTabs;
    PfToolbarItem: typeof PfToolbarItem;
    PfTooltip: typeof PfTooltip;

    PfBullseye: typeof PfBullseye;
    PfFlex: typeof PfFlex;
    PfFlexItem: typeof PfFlexItem;
    PfGallery: typeof PfGallery;
    PfGalleryItem: typeof PfGalleryItem;
    PfGrid: typeof PfGrid;
    PfGridItem: typeof PfGridItem;
    PfLevel: typeof PfLevel;
    PfLevelItem: typeof PfLevelItem;
    PfSplit: typeof PfSplit;
    PfSplitItem: typeof PfSplitItem;
    PfStack: typeof PfStack;
    PfStackItem: typeof PfStackItem;
  }
}
