<template>
  <div class="riepilogo-wrapper">
    <ul>
      <wizard-summary-option
        v-if="wizard.askDomain"
        :name="$t('Nome a Dominio')"
        :value="wizard.nomeDominio"
        editStep="WizardStepDominio"
        ouia-id="wizard-summary-dominio-nome"
      />

      <template v-if="wizard.askRegistrazione && wizard.nomeDominio && wizard.dominioRegistrabile">
        <wizard-summary-option
          :name="$t('Registrazione Dominio')"
          :value="wizard.siNo(wizard.domainAction === 'registrazione')"
          editStep="WizardStepDominio"
          ouia-id="wizard-summary-dominio-registrazione"
        />
        <wizard-summary-option
          v-if="wizard.domainAction"
          :name="$t('Attivazione DNS')"
          :value="wizard.siNo(['registrazione', 'dns'].includes(wizard.domainAction))"
          editStep="WizardStepDominio"
          ouia-id="wizard-summary-dominio-dns"
        />
      </template>

      <wizard-summary-option
        v-if="wizard.registraDominio && wizard.dominioRegistrabile"
        :name="$t('Attivazione Registrazione Premium')"
        :value="wizard.siNo(wizard.registrazionePremium)"
        editStep="WizardStepDominio"
        ouia-id="wizard-summary-dominio-premium"
      />
    </ul>
  </div>
</template>


<script lang="ts" setup>
import WizardSummaryOption from './wizard-summary-option.vue';
import { useWizard } from '../composition';

const wizard = useWizard();
</script>
