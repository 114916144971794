<template>
  <page :name="$t('Listino prezzi')" :empty="products.length == 0" :state="[productsState, ccpState]">
    <pf-toolbar ref="toolbar" view="list" views="" style="position:sticky; top: 60px; z-index:1">
      <template #filter>
        <x-input v-model="q" type="search" :placeholder="$t('Filtra')" />
      </template>

      <currency-toggle v-if="!login.currency_locked" id="currency-dropdown" :prefix="$t('Valuta')" />

      <pf-dropdown id="invoicing-dropdown" menu-right :text="$t('Fatturazione') + ': ' + $t(selectDurata)">
        <li>
          <a role="menuitem" @click.prevent="selectDurata = 'Annuale'">
            {{ $t('Annuale') }}
          </a>
        </li>
        <li>
          <a role="menuitem" @click.prevent="selectDurata = 'Mensile'">
            {{ $t('Mensile') }}
          </a>
        </li>
      </pf-dropdown>
      <pf-button v-if="login.is_reseller" @click="showCcpForm = true">
        <edit-icon />  {{ $t('Modifica ricarico') }}
      </pf-button>
    </pf-toolbar>

    <section v-for="category of Object.values(sortedCategories)" :key="category.name" style="margin-top: 1em">
      <h2>
        {{ category.name.replace(/(^| — )\d+\.\s*/g, '$1') }}
        <pf-button v-if="login.is_reseller && ricaricoByCategoria(category.name) != undefined" @click="showCcpForm = true" class="pull-right">
          <edit-icon /> {{ $t('Ricarico personalizzato: +{0}%', [ricaricoByCategoria(category.name)]) }}
        </pf-button>
      </h2>
      <p class="description">
        {{ category.description }}
      </p>
      <product-list
        :items="catalog[category.name]"
        :currency="login.currency"
        :hide-plus-vat="login.iva < 0.01"
      />
    </section>
    <pf-modal
      v-if="login.is_reseller"
      :show="showCcpForm"
      :title="$t('Modifica il ricarico per i tuoi clienti')"
      confirm-button=""
      cancel-button=""
      @cancel="showCcpForm = false"
    >
      <category-custom-prices-form :category-custom-prices="ccp" @submit="ccpSubmit" @close="showCcpForm = false"/>
    </pf-modal>
  </page>
</template>

<style scoped>
h2 {
  margin-top: 50px;
  font-weight: 400;
}
p.description {
    font-size: 16px;
    margin: 20px 0;
    font-weight: 300;
    max-width: 800px;
}
</style>

<script lang="ts" setup>
import { $t as _t } from '@/i18n';
import EditIcon from '@vue-patternfly/icons/edit-alt-icon';
import { asyncComputedList } from '@common/asyncComputed';
import { any } from '@common/utils';
import XInput from '@/components/x-input.vue';
import ProductList from '../components/product-list.vue';
import Page from '../components/page.vue';
import CurrencyToggle from '../components/currency-toggle.vue';
import CategoryCustomPricesForm from '../components/category-custom-prices-form.vue';
import { useLoginStore } from '@/store/login';
import { CategoryCustomPricesResource, CompanyResource, type CatalogProduct } from '@/resources';
import { computed, ref } from 'vue';
import { PRODUCTCATEGORY } from '@/common/consts.ts';

export type NormalizedCatalogProduct = CatalogProduct & {
  prezzo: number;
  prezzo_scontato: number;
  prezzo_ivato: number;
  prezzo_customer?: number | null;
};

defineOptions({
  name: 'CatalogPage',
});

type PrefixedCategory = `${string}. ${typeof PRODUCTCATEGORY[number]}`;

const categoryDescription: Record<PrefixedCategory, string|null> = {
  '00. Internet Domain': _t("Con oltre 200 estensioni TLD, gestione DNS e redirect, la registrazione del dominio offre un servizio affidabile e flessibile, assicurando una presenza online sempre raggiungibile, con fatturazione annuale."),
  '01. Internet Domain — Add On': _t("Offriamo servizi esclusivi per la gestione e la protezione avanzata dei domini, dalla registrazione premium completa alla gestione avanzata del solo DNS."),
  '02. Cloud Hosting': _t("Offriamo servizi esclusivi per la gestione e la protezione avanzata dei domini, dalla registrazione premium completa alla gestione avanzata del solo DNS."),
  '03. Cloud Hosting — Windows': _t("Garantisce velocità, sicurezza e stabilità. Con Plesk, protezione avanzata e backup automatici, offre risorse scalabili e affidabilità, adattandosi a ogni esigenza, dalle basi solide di Eclipse alla massima potenza di Oblivion."),
  '04. Cloud Hosting — Mail': _t("Garantisce affidabilità, sicurezza e prestazioni elevate. Con storage All-flash e backup in location separata, offre caselle illimitate e supporto proattivo, con soluzioni scalabili da 10 GB per esigenze base fino a 120 GB per massima capacità."),
  '05. Cloud Reseller': _t("Garantisce flessibilità, autonomia e ottimizzazione dei costi. Con infrastruttura avanzata e supporto operativo, offre gestione reseller scalabile e trasferimento clienti. Disponibile in versione self-managed per il massimo controllo o managed per assistenza completa."),
  '06. Cloud Server': _t("Garantisce prestazioni elevate e risorse scalabili in tempo reale. Flessibile e adattabile a ogni esigenza, offre un’infrastruttura potente e affidabile per ottimizzare costi e performance senza compromessi."),
  '07. Host Server': _t("Infrastruttura dedicata e sicura per applicazioni aziendali e servizi business-critical. Offre prestazioni elevate, risorse esclusive e scalabilità, con storage all-flash distribuito e protezione avanzata."),
  '08. Cloud Server — Stack': _t("Soluzioni avanzate per ambienti Linux e Windows. Con configurazioni ottimizzate e sicurezza integrata, offrono massima personalizzazione e gestione efficiente per qualsiasi progetto."),
  '09. Cloud & Host server — Add On': _t("Strumenti avanzati software e hardware per potenziare sicurezza e performance. Dalle licenze Microsoft, alla protezione Imunify360 e risorse scalabili, per garantire massima flessibilità e ottimizzazione per ogni esigenza."),
  '10. Storage': _t("Garantisce storage cloud flessibile e sicuro. Con soluzioni scalabili, ridondanti e ad alte prestazioni, offre gestione avanzata per archiviazione dati, server e email, adattandosi a qualsiasi esigenza operativa."),
  '11. Backup': _t("Garantisce backup sicuro e gestito con storage All-flash da 100 GB. Con soluzioni da Acronis a IBM Spectrum Protect, offre protezione avanzata e recupero rapido, adattandosi a qualsiasi esigenza, dalla gestione essenziale alle soluzioni enterprise."),
  '12. SSL Certificates': _t("Garantisce protezione avanzata per siti e aziende. Con opzioni da SSL base a certificati Wildcard ed EV, offre sicurezza scalabile e copertura assicurativa fino a 250.000€, adattandosi a ogni esigenza, dalla protezione essenziale alla massima affidabilità."),
  '13. Posta elettronica certificata': _t("Garantisce una gestione PEC sicura e professionale con caselle certificate su dominio personalizzato. Con opzioni per archiviazione, spazio aggiuntivo e notifiche SMS, offre soluzioni scalabili per aziende e professionisti."),
  '14. Internet Domain — Other TLD': _t("Con oltre 200 estensioni TLD, gestione DNS e redirect, la registrazione del dominio offre un servizio affidabile e flessibile, assicurando una presenza online sempre raggiungibile, con fatturazione annuale."),
  '15. Development': null,
  '16. Datacenter': null,
  '17. Commissions': null,
  '18. Comarketing': null,
  '19. Other': null,
};

const login = useLoginStore();

const { state: productsState, result: products } = asyncComputedList(async () => {
  if (login.id_azienda) {
    return new CompanyResource().priceList(login.id_azienda).get();
  }
  return [];
});

const q = ref('');
const selectDurata = ref('Annuale');
const showCcpForm = ref(false);

const { state: ccpState, result: ccp } = asyncComputedList(() => {
  if (login.is_reseller) {
    return new CategoryCustomPricesResource().get();
  }
  return [];
});

const sortedCategories = computed(() => {
  const categories = Object.keys(catalog.value);
  return categories
    .sort()
    .map((c) => {
      return {
        name: c,
        description: categoryDescription[c],
      }
    });
});

const catalog = computed(() => {
  const categorized: Record<PrefixedCategory, NormalizedCatalogProduct[]> = {};

  const filter = any(q.value);
  for (const item of products.value) {
    if (filter(item) && filtroDurata(item)) {
      const category: PrefixedCategory = `${item.posizione_categoria}. ${item.categoria}`;

      if (!Object.prototype.hasOwnProperty.call(categorized, category)) {
        categorized[category] = [];
      }

      categorized[category].push({
        ...item,
        prezzo: login.currency == 'EUR' ? item.prezzo_eur : item.prezzo_chf,
        prezzo_scontato: login.currency == 'EUR' ? item.prezzo_scontato_eur : item.prezzo_scontato_chf,
        prezzo_ivato: login.currency == 'EUR' ? item.prezzo_ivato_eur : item.prezzo_ivato_chf,
        prezzo_customer: login.currency == 'EUR' ? item.prezzo_clienti_eur : item.prezzo_clienti_chf,
      });
    }
  }
  return categorized;
});

function filtroDurata(prodotto: CatalogProduct) {
  return prodotto.durata == 'Una tantum' || prodotto.durata == selectDurata.value;
};

function ricaricoByCategoria(category: string) {
  if (login.is_reseller) {
    return category ? ccp.value.filter(v => v.category == category.replace(/(^| — )\d+\.\s*/g, '$1'))[0]?.markup : 0;
  }
  return undefined;
}

function ccpSubmit() {
  showCcpForm.value = false;
  ccpState.update();
  productsState.update();
}
</script>
