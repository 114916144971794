<template>
  <page :name="name" :title="title" :description="description" :state="asyncComputed" sticky>
    <template #breadcrumbs>
      <router-link v-slot="{ navigate }" custom :to="{ name: 'tickets' }">
        <li>
          <a @click="navigate">{{ $t('Tickets') }}</a>
        </li>
      </router-link>
    </template>

    <span v-if="ticket.urgente" class="label btn-danger">
      {{ $t('Urgente') }}
    </span>

    <pf-empty-chart v-if="ticket.id && (!messages || !messages.length)" :style="{ 'font-size': '110%' }">
      {{ $t('Nessun messaggio presente') }}
    </pf-empty-chart>

    <div v-else-if="ticket.id" class="list-group list-view-pf list-view-pf-view">
      <pf-list-group-item v-for="row of messages" :key="row.id" stacked>
        <pf-list-item :class="{ 'list-group-item-response': !!row.operatore }">
          <template #left>
            <img :src="avatar(row)" class="pficon list-view-pf-icon-md">
            <div v-if="row.operatore" class="label label-primary">
              {{ row.operatore }}
            </div>
          </template>

          <template #heading>
            <span class="message-date">{{ $datetime(row.data) }}</span>
          </template>

          <template #description>
            <iframe-autoheight
              v-if="row.operatore"
              referrerpolicy="no-referrer"
              sandbox="allow-same-origin allow-popups"
              :srcdoc="messageHtml(row.messaggio)"
            />
            <template v-else>{{ row.messaggio }}</template>

            <div
              v-if="row.allegati.length > 0 || row.id_offerta_allegata || row.id_fattura_allegata"
              class="message-attachments"
            >
              {{ $t('Allegati') }}{{ ': ' }}
              <template v-if="row.allegati.length > 0">
                <a v-for="file in row.allegati" :key="file.id" :href="`/rest/ticket_attachment/${file.id}`">
                  <paperclip-icon />
                </a>
              </template>
              <a
                v-if="row.id_offerta_allegata"
                :key="row.id_offerta_allegata"
                :href="`/rest/offer-pdf/${row.id_offerta_allegata}`"
              >
                <paperclip-icon /> {{ $t('Offerta nr {0}', [row.id_offerta_allegata]) }}
              </a>
              <a
                v-if="row.id_fattura_allegata"
                :key="row.id_fattura_allegata"
                :href="`/rest/invoice-pdf/${row.id_fattura_allegata}`"
              >
                <paperclip-icon />
                {{ $t('Fattura {0}', [`${row.nr_fattura}${row.serie_fattura} / ${row.anno_fattura}`]) }}
              </a>
            </div>
          </template>
        </pf-list-item>
      </pf-list-group-item>
    </div>


    <div v-if="sending" class="loading-state-pf">
      <div class="spinner spinner-lg blank-slate-pf-icon" />
      <h3 class="blank-slate-pf-main-action">
        {{ $t('Attendere. Invio in corso...') }}
      </h3>
    </div>

    <x-form
      v-if="ticket.stato == 'aperto' || !ticket.id"
      v-show="!sending"
      ref="form"
      encoding="json"
      :action="'/rest/tickets' + (ticket.id ? `/${ticket.id}/messages` : '')"
      :additional-data="additionalData"
      :confirm-message="confirmMessage"
      xhr
      @submit="send"
      @sending="sending = $event"
    >
      <form-group v-if="!ticket.id" :name="$t('Titolo')" required>
        <x-input id="ticketTitle" name="titolo" type="text" :placeholder="$t('Titolo')" required />
      </form-group>

      <form-group :name="$t('Messaggio')" required>
        <textarea
          id="messageText"
          name="messaggio"
          class="form-control"
          :placeholder="placeholderMessaggio"
          rows="3"
          required
        />
      </form-group>

      <template v-if="areWeClosed">
        <pf-notification v-if="!ticket.id" type="warning">
          {{ $t('Al ticket verrà data risposta in orario di ufficio. In caso di urgenze selezionare la checkbox "Urgente"') }}
        </pf-notification>
        <pf-notification v-else-if="!ticket.urgente" type="warning">
          {{ $t('Al messaggio verrà data risposta in orario di ufficio. In caso di urgenze aprire un nuovo ticket di tipo "Urgente"') }}
        </pf-notification>
      </template>

      <form-group v-if="!ticket.id" :name="$t('Urgente')" no-label>
        <label>
          <input v-model="urgente" name="urgente" type="checkbox" value="1">
          {{ $t('Urgente') }}
          <span v-if="login.is_premium">
            ({{ $t('Incluso in Prime') }})
          </span>
          <span v-else>
            ({{ $t('il ticket verrà fatturato al costo di {0}', [formatCurrency(urgentTicketCost, login.currency)]) }})
          </span>
        </label>
      </form-group>

      <div v-if="attachments.length" class="form-group list-attachments">
        <label>{{ $t('Allegati') }}: </label>
        <span v-for="(file, index) in attachments" :key="index" class="label label-default">
          <paperclip-icon />
          {{ file.name }} ({{ $size(file.size) }})
          <a href="#" class="pf-remove-button" @click.prevent="attachments.splice(index, 1)">
            <span class="pficon pficon-close" aria-hidden="true" />
            <span class="sr-only">{{ $t('Rimuovi') }}</span>
          </a>
        </span>
      </div>

      <template #submit>
        <paper-plane-icon /> {{ $t('Invia') }}
      </template>

      <template #actions>
        <label class="btn btn-default">
          <paperclip-icon />
          {{ $t('Aggiungi allegati') }}
          <input
            ref="attachments"
            type="file"
            multiple
            style="opacity: 0; position: absolute"
            @change="refreshAttachments"
          >
        </label>
      </template>
    </x-form>
  </page>
</template>

<style lang="scss" scoped>
@import 'bootstrap/variables';

.list-attachments > .label {
  display: inline-block;
  margin: 0 0 5px 5px;
}

iframe {
  border: 0;
  width: 100%;
}

.list-view-pf {
  :deep(.list-view-pf-left) {
    align-self: baseline;
  }

  :deep(.list-view-pf-description) {
    width: 100%;
  }

  .message-date,
  .message-attachments {
    font-size: 12px;
  }

  .message-attachments {
    font-weight: 600;
    padding-top: 1em;
    margin-top: 1em;
    border-top: 1px solid $table-border-color;

    > a::after {
      content: ', ';
      display: inline-block;
      color: $text-color;
      white-space: pre-wrap;
    }

    > a:last-child::after {
      display: none;
    }
  }

  .label {
    line-height: 30px;
    padding: 0 10px;
  }

  :deep(.list-group-item-text) {
    white-space: pre-line;
  }

  .list-view-pf-main-info.list-group-item-response {
    flex-direction: row-reverse;

    .list-group-item-heading,
    .list-group-item-text {
      text-align: right;
    }
  }

  @media (min-width: $screen-md) {
    .list-view-pf-main-info {
      padding-right: 25%;

      &.list-group-item-response {
        padding-right: 0;
        padding-left: 25%;
      }
    }
  }
}
</style>

<script lang="ts">
import md5 from 'nano-md5';

import Page from '@/components/page.vue';
import XForm from '@/components/x-form.vue';
import FormGroup from '@/components/form-group.vue';
import IframeAutoheight from '@common/IframeAutoheight.vue';
import XInput from '@/components/x-input.vue';
import PaperclipIcon from '@vue-patternfly/icons/paperclip-icon';
import PaperPlaneIcon from '@vue-patternfly/icons/paper-plane-icon';

import { setupAsyncComputed } from '@common/asyncComputed';
import { formatCurrency } from '@common/utils';
import { useLoginStore } from '@/store/login';
import { useAppStore } from '@/store/app';
import { type PHPDateTime, type Ticket, UrgentTicketCostResource, AreWeClosedResource, type TicketMessage, TicketResource } from '@/resources';
import { defineComponent } from 'vue';
import { $t } from '@/i18n';

const defaulTicket: Ticket = {
  id: null,
  titolo: '',
  urgente: false,
  data: null,
  stato: 'aperto',
  nr_messaggi: 0,
};

export default defineComponent({
  name: 'TicketPage',

  components: {
    Page,
    XForm,
    FormGroup,
    IframeAutoheight,
    XInput,
    PaperclipIcon,
    PaperPlaneIcon,
  },

  setup() {
    const login = useLoginStore();
    return {
      login,
      ...setupAsyncComputed({
        ticket: {
          async get(this: any): Promise<Ticket> {
            if (this.$route.params.id == 'new') {
              return defaulTicket;
            }
            return await new TicketResource().get(this.$route.params.id, defaulTicket);
          },
          default: defaulTicket,
        },

        messages: {
          async get(this: any): Promise<TicketMessage[]> {
            if (!this.$route.params.id || this.$route.params.id == 'new') {
              return [] as TicketMessage[];
            }
            return await new TicketResource().messages(this.$route.params.id).get();
          },
          default: [] as TicketMessage[],
        },

        urgentTicketCost: {
          get(this: any) {
            return new UrgentTicketCostResource().get(1);
          },
          default: 120.0,
        },

        areWeClosed: {
          get(this: any) {
            return new AreWeClosedResource().get(1);
          },
          default: false,
        },
      }),
    };
  },

  data(this: void) {
    return {
      attachments: [] as File[],
      urgente: false,
      sending: false,
    };
  },

  computed: {
    name() {
      return this.ticket.id ? $t('Ticket: #{0}', [this.ticket.id]) : $t('Nuovo ticket');
    },

    title() {
      return (this.ticket.id ? $t('Ticket: {0}', [this.ticket.titolo]) : $t('Nuovo ticket')) + (this.ticket.urgente ? ` [${$t('Urgente')}]` : '');
    },

    description() {
      return this.ticket.id
        ? $t('#{0}', [this.ticket.id])
        : $t('Contatta il nostro supporto tecnico');
    },

    placeholderMessaggio() {
      return this.ticket.id
        ? $t('Testo del messaggio')
        : $t(
          'Specificare eventuale dominio di riferimento, IP del server dedicato o indirizzo del server cloud ed in generale un riferimento al servizio sul quale si richiede assistenza. Se possibile indicare come è possibile riprodurre il problema.',
        );
    },

    confirmMessage() {
      return this.urgentTicketCost > 0 && this.urgente && !this.login.is_premium
        ? $t(
          'Il ticket è stato impostato come urgente e verrà quindi fatturato al costo di {0}. Vuoi procedere?',
          [formatCurrency(this.urgentTicketCost, this.login.currency)],
        )
        : undefined;
    },

    additionalData() {
      return { attachments: this.attachments };
    },
  },

  methods: {
    send(e: Event | undefined, responseData: unknown) {
      const app = useAppStore();
      this.attachments = [];
      if (this.ticket.id) {
        app.toast($t('Risposta inviata'), 'success');
        this.asyncComputed.messages.update();
      } else {
        const newTicket = responseData as {
          id: number;
          data: PHPDateTime;
          messaggio: string;
          stato: 'aperto' | 'chiuso';
          titolo: string;
          attachments: any[];
          extra_time: boolean | null;
          urgente: boolean | null;
        };
        app.toast(!newTicket.urgente && newTicket.extra_time ? $t('Ticket inviato. Riceverai risposta in orario di ufficio. Grazie!') : $t('Ticket inviato. Lo staff ti risponderà appena possibile. Grazie!'), 'success');
        this.$router.push({ name: 'ticket', params: { id: newTicket.id } });
      }
    },

    messageHtml(messaggio: string) {
      const appStylesheet = document.querySelector<HTMLLinkElement>('link[rel=stylesheet]')?.outerHTML ?? '';

      return `<html><head>${appStylesheet}<style>
html, body {
  padding: 0;
  margin: 0;
}

body {
  font-size: 9pt;
  background-color: transparent;
}

body > :first-child {
  margin-top: 0;
}

body > :last-child {
  margin-bottom: 0;
}

a {
  color: #80cfff;
  text-decoration: none;
}

blockquote {
  margin: 4pt 0 4pt 0;
  padding: 0 0 0 1em;
  border-left: 2px solid;
  unicode-bidi: -webkit-plaintext
}

blockquote,
blockquote blockquote blockquote blockquote,
blockquote blockquote blockquote blockquote blockquote blockquote blockquote {
  border-color: #82ff95;
}

blockquote blockquote,
blockquote blockquote blockquote blockquote blockquote,
blockquote blockquote blockquote blockquote blockquote blockquote blockquote blockquote {
  border-color: #69ce78;
}

blockquote blockquote blockquote,
blockquote blockquote blockquote blockquote blockquote blockquote,
blockquote blockquote blockquote blockquote blockquote blockquote blockquote blockquote blockquote {
  border-color: #4b9456;
}

::-webkit-scrollbar {
  transition: width .2s linear;
  width: 6px;
  height: 6px
}

::-webkit-scrollbar-thumb {
  transition: background-color .2s linear;
  background-color: #aaa;
  background-color: #aaaaaa80;
  border-radius: 4px
}

::-webkit-scrollbar-thumb:hover {
  background-color: #999;
  background-color: #999999b3
}

::-webkit-scrollbar-track {
  background-color: transparent
}
</style></head>
<body class="pf-v5-c-content">${messaggio}</body></html>`;
    },

    avatar(message: TicketMessage) {
      return `https://www.gravatar.com/avatar/${md5(message.utente ?? '')}?s=50&d=retro`;
    },

    refreshAttachments(e: Event) {
      if (!(e.target instanceof HTMLInputElement) || !e.target.files) {
        return;
      }

      for (const file of e.target.files) {
        this.attachments.push(file);
      }
      try {
        e.target.value = '';
      } catch {
        // ignore
      }
    },

    formatCurrency,
  },
});
</script>
