<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Configurazione')">
    <template v-if="wizard.askSiteTransfer || wizard.askMailTransfer">
      <div class="question-wrapper">
        <h3>{{ $t('Attivazione')}}</h3>
        <label>
          {{ $t('Questo servizio è') }}:

          <input type="checkbox" :checked="wizard.newService !== undefined" required class="required-hided" />
          <pf-toggle
            v-model="wizard.newService"
            :on-value="true"
            :off-value="false"
            :on-text="$t('Una nuova attivazione')"
            :off-text="$t('Un servizio già esistente da trasferire su Artera')"
            off-class="btn-success"
          />
        </label>
      </div>

      <label v-if="wizard.askSiteTransfer && wizard.newService === false">
        {{ $t('Vuoi che ci occupiamo noi del trasferimento del sito web?') }}:

        <input type="checkbox" :checked="wizard.siteTransfer !== undefined" required class="required-hided" />
        <pf-toggle
          v-model="wizard.siteTransfer"
          :on-value="true"
          :off-value="false"
          :on-text="$t('Sì')"
          :off-text="$t('No')"
        />
        <form-help-tooltip :text="$t('Al termine dell\'ordine ti chiederemo tutti i dati necessari per effettuare il trasferimento dall\'attuale provider')" />
      </label>

      <label v-if="wizard.askMailTransfer && wizard.newService === false">
        {{ $t('Vuoi che ci occupiamo noi del trasferimento delle mail?') }}:

        <input type="checkbox" :checked="wizard.mailTransfer !== undefined" required class="required-hided" />
        <pf-toggle
          v-model="wizard.mailTransfer"
          :on-value="true"
          :off-value="false"
          :on-text="$t('Sì')"
          :off-text="$t('No')"
        />
        <form-help-tooltip :text="$t('Al termine dell\'ordine ti chiederemo di fornirci l\'attuale server IMAP e la lista delle caselle attive con le relative password')" />
      </label>
      <p v-if="wizard.askMailTransfer && wizard.newService === false">{{ $t("IMPORTANTE: per poter procedere con il trasferimento delle caselle mail è necessario che il servizio IMAP sia attivo sull'attuale provider") }}</p>
    </template>

    <div v-if="wizard.askTest" class="question-wrapper">
      <label>
        <h3>{{ $t('Vuoi attivare questo servizio in prova per 30 giorni?') }}</h3>

        <pf-toggle
          v-model="wizard.freePeriod"
          :on-value="30"
          :off-value="0"
          :on-text="$t('Sì')"
          :off-text="$t('No')"
        />
      </label>

      <pf-toggle
        v-if="wizard.freePeriod > 0"
        v-model="wizard.renewAfterFreePeriod"
        :on-value="true"
        :off-value="false"
        :on-text="$t('Rinnova dopo il periodo gratuito')"
        :off-text="$t('Disdici dopo il periodo gratuito')"
      />
    </div>

    <template v-if="wizard.askDb || wizard.askPhp || wizard.askTecnologie">
      <h3>{{ $t('Configurazione applicativa')}}</h3>
      <div class="question-wrapper" v-if="wizard.askDb">
        <h3>{{ $t('Versione MySQL')}}</h3>
        <p>{{ $t('Indicaci quale versione di MySQL vuoi attivare.') }}</p>
        <wizard-question-options name="mysql" v-model="wizard.db" :options="wizard.opzioniDb" :null-label="$t('Non necessario')"/>
      </div>

      <div class="question-wrapper" v-if="wizard.askPhp">
        <h3>{{ $t('Versione PHP')}}</h3>
        <p>{{ $t('Indicaci quale versione di PHP vuoi attivare.') }}</p>
        <wizard-question-options name="php" v-model="wizard.php" :options="wizard.filteredOpzioniPhp" :null-label="$t('Non necessario')"/>
      </div>

      <div class="question-wrapper" v-if="wizard.askTecnologie">
        <h3>{{ $t('Tecnologie e applicativi')}}</h3>
        <p>{{ $t('Indicaci quali di queste tecnologie verranno utilizzate.') }}</p>
        <wizard-question-tecnologie v-model="wizard.tecnologie" />
      </div>
    </template>
  </wizard-form>
</template>

<style scoped>
label {
  display: block;
}
.required-hided{
  position: relative;
  width: 1px;
  height:1px;
  left: 25px;
}
</style>

<script lang="ts" setup>
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import WizardQuestionTecnologie from '../questions/wizard-question-tecnologie.vue';
import wizardQuestionOptions from '../questions/wizard-question-options.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import WizardForm from '../wizard-form.vue';
import { default as PfToggle } from '../../toggle.vue';

defineOptions({
  name: 'WizardStepConfigurazione',
});

const props = defineProps<OUIAProps>();
const ouiaProps = useOUIAProps(props);

const { wizard, hidden, isEnabled } = useWizardStep({
  isEnabled: wizard => wizard.askTest || wizard.askTecnologie || wizard.askDb || wizard.askPhp || wizard.askSiteTransfer || wizard.askMailTransfer,
});

defineExpose({
  isEnabled,
});
</script>
