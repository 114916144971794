<template>
  <div v-if="wizard.askSsl || wizard.askAddonSla || wizard.askAddonStorage" class="riepilogo-wrapper">
    <ul>
      <wizard-summary-option
        v-if="wizard.askSsl"
        :name="$t('Certificato SSL')"
        :value="wizard.codiceSsl ? wizard.codiceSsl : $t('Non attivare')"
        editStep="WizardStepAddon"
        ouia-id="wizard-summary-addon-ssl"
      />
      <wizard-summary-option
        v-if="wizard.askAddonSla"
        :name="$t('Assistenza Premium')"
        :value="wizard.addonSla ? $t('Richiesta') : $t('Non richiesta')"
        editStep="WizardStepAddon"
        ouia-id="wizard-summary-addon-sla"
      />
      <wizard-summary-option
        v-if="wizard.askAddonStorage"
        :name="$t('Storage aggiuntivo')"
        editStep="WizardStepAddon"
        ouia-id="wizard-summary-storage"
      >
        <strong>{{ wizard.codiceAddonStorage ?? $t('No') }} <template v-if="wizard.codiceAddonStorage">x {{ wizard.qtAddonStorage }}</template></strong>
      </wizard-summary-option>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import WizardSummaryOption from './wizard-summary-option.vue';
import { useWizard } from '../composition';
const wizard = useWizard();
</script>
