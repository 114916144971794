<template>
  <div v-for="(v, k) in wizard.paths" :key="k">
    <template v-if="v">
      <label>
        <input
          type="radio"
          :name="name"
          :required="required"
          :value="k"
          :checked="k === modelValue"
          @change="emit('update:modelValue', k)"
        >
        {{ v.label }} {{ ' ' }}
      </label>
      <div v-if="v.description" class="radio-description">
        {{ v.description }}
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useWizardQuestion } from '../composition';
import type { WizardInjection } from '../wizard.vue';

type ValueType = WizardInjection['path'];

defineProps<{
  modelValue: ValueType;
  name: string;
  required?: boolean;
}>();

const emit = defineEmits<{
  (name: 'update:modelValue', v: ValueType): void;
}>();

const { wizard } = useWizardQuestion();
</script>
