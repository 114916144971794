import { inject, unref } from 'vue';
import { asyncComputed } from '@common/asyncComputed';
import { type Company, CompanyInjectionKey, CompanyResource } from './resources';
import { useLoginStore } from './store/login';
import type { RouteLocation } from 'vue-router';

/**
 * Compute the destination URL to redirect to after a successful login or signup process.
 * @param param0 The route query parameters.
 */
export function loginDestination({ ref, antk, aid, uid, tok }: { ref?: string, antk?: string, aid?: string, uid?: string, tok?: string; }): string {
  ref = (!ref || ref === '/') ? (localStorage.getItem('ref') ?? undefined) : ref;

  // consume the stored referrer url, we only want to use it once
  localStorage.removeItem('ref');

  // remove affiliation tokens after successful login/signup
  localStorage.removeItem('afid');
  localStorage.removeItem('aftk');

  if (antk) {
    return `/auth/accept-invite/${antk}?ref=${encodeURIComponent(ref || '/')}`;
  }

  if (aid && uid && tok) {
    return `/auth/authorize-user/${aid};${uid};${tok}?ref=${encodeURIComponent(ref || '/users')}`;
  }

  if (!ref) {
    return '/';
  }

  const protocolPos = ref.indexOf('://');

  if (protocolPos >= 0) {
    const pathPos = ref.indexOf('/', protocolPos + 3);
    if (pathPos < 0) {
      return '/';
    }
    ref = ref.substring(pathPos);
  }

  if ((/^\/auth\//).exec(ref)) {
    return '/';
  }

  return ref;
}

export function useCompany() {
  const company = inject(CompanyInjectionKey, null);

  if (company && unref(company)?.id) {
    return asyncComputed(() => unref(company), unref(company));
  }

  const defaultCompany: Company = {
    ack_marketing: false,
    ack_privacy: false,
    ack_terms: false,
    cap: '',
    citta: '',
    cod_pagamento: '',
    codice_fiscale: null,
    contratto_cliente: false,
    currency: 'EUR',
    currency_locked: false,
    email_pec: null,
    fatturante_strid: '',
    fatturazione_mensile: false,
    hasCompleteBillingData: false,
    id: 0,
    id_pagamento: 0,
    indirizzo: '',
    is_verified_billing: false,
    iva: '',
    lingua: '',
    locked: false,
    metodi_pagamento: [],
    pagamento: '',
    partita_iva: '',
    provincia: '',
    ragione_sociale: '',
    sdi: null,
    stato: '',
    telefono: null,
    tipologia: '',
    use_credit: false,
    id_main_skill: null,
    requirements: [],
    cashback_months: [],
    disableRecurringPayments: false,
    approval: null,
  };

  return asyncComputed(
    () => new CompanyResource().get(0),
    defaultCompany,
  );
}

export function routeIsGranted(route: RouteLocation) {
  const login = useLoginStore();

  return route.matched.every(r => {
    return typeof r.meta.grant !== 'function' || r.meta.grant(login);
  });
}

export function routeIsVisible(route: RouteLocation) {
  const login = useLoginStore();

  return route.matched.every(r => {
    return typeof r.meta.visible !== 'function' || r.meta.visible(login);
  });
}
