<template>
  <li v-bind="ouiaProps">
    {{ name }}:
    <slot><strong>{{ value }}</strong></slot>
  <template v-if="editStep">
    {{ ' ' }}
    <pf-button variant="link" @click="wizard.goToStep(editStep)">
      <edit-icon />
    </pf-button>
  </template>
  </li>
</template>

<style scoped>
:deep(.btn.btn-link) {
  color: #000;
  padding: 0;
}
</style>

<script lang="ts" setup>
import EditIcon from '@vue-patternfly/icons/edit-alt-icon';
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { useWizard } from '../composition';

const props = defineProps<OUIAProps & {
  name: string,
  value?: string,
  editStep?: string,
}>();
const ouiaProps = useOUIAProps(props);


const wizard = useWizard();
</script>
