<template>
  <page
    :name="$t('Progetti')"
    :description="$t('Descrivi la tua esigenza, le sue caratteristiche e le specifiche tecniche e ti aiuteremo a trovare un fornitore affidabile tra le aziende della nostra rete di partner')"
    :state="{ requirementsState, projectsState }"
    :loading="projectsState.updating || requirementsState.updating"
  >

    <template v-if="projects.length">
      <h2>{{ $t("Progetti presentati") }}</h2>

      <project-list variant="project" :projects="projects" />
    </template>

    <div class="panel panel-default">
      <div class="panel-heading">
        <h2>{{ $t("Presenta un tuo progetto") }}</h2>
      </div>
      <div class="panel-body">
        <div v-if="sending" class="blank-slate-pf">
          <div class="spinner spinner-lg blank-slate-pf-icon" />
          <h3 class="blank-slate-pf-main-action">
            {{ _t('Attendi solo pochi secondi...') }}
          </h3>
        </div>
        <form v-else role="form" @submit.prevent="submit">
          <div>
            <form-group :name="$t('Nome del progetto')" required>
              <x-input
                id="projectName"
                v-model="project.nome"
                name="nome"
                type="text"
                required
                maxlength="250"
              />
            </form-group>
          </div>

          <div>
            <form-group :name="$t('Descrizione')" required>
              <textarea
                id="projectDesc"
                v-model="project.descrizione"
                name="descrizione"
                class="form-control"
                required
                maxlength="250"
                rows="5"
              />
            </form-group>
          </div>

          <div>
            <form-group :name="$t('Dominio')">
              <x-input
                id="projectDom"
                v-model="project.dominio"
                name="name"
                type="domain"
              />
            </form-group>
          </div>

          <div>
            <form-group :name="$t('Tempistiche indicative')">
              <textarea
                id="projectTempi"
                v-model="project.tempistiche"
                name="descrizione"
                class="form-control"
                maxlength="250"
                rows="5"
              />
            </form-group>
          </div>

          <div>
            <form-group :name="$t('Budget indicativo')">
              <textarea
                id="projectBdg"
                v-model="project.budget"
                name="descrizione"
                class="form-control"
                maxlength="250"
                rows="5"
              />
            </form-group>
          </div>

          <div>
            <form-group
              :name="$t('I tuoi contatti')"
              required
              :help-tooltip="$t('Inserisci qui la tua email e il numero di telefono a cui essere contatto per richieste relative al progetto. Li invieremo solo al partner selezionato.')"
            >
              <textarea
                id="projectContatti"
                v-model="project.contatti"
                name="contatti"
                class="form-control"
                maxlength="250"
                rows="5"
                required
              />
              <label><input type="checkbox" required name="authorization" /> {{ $t("Autorizzo l'invio dei miei dati al partner selezionato") }}</label>
            </form-group>
          </div>

          <h3 class="skill_title">{{ _t('Quali sono le skill richieste per la realizzazione del progetto?') }} *</h3>
          <div class="switchlist">
            <div v-for="skill in [...skills]" :key="skill.id" class="switch">
              <toggle
                v-model="project.id_requirements"
                :on-value="skill.id"
                name="requisiti[]"
                :off-text="_t('No')"
                :on-text="_t('Sì')"

              >
                {{ skill.nome }}
              </toggle>
            </div>
          </div>
          <!-- Input nascosto per mostrare che il campo skill è obbligatorio -->
          <input v-model="newProjectSelectedSkills" type="checkbox" required class="input-hidden">

          <h3 class="skill_title">{{ _t('Tecnologie') }}</h3>
          <p>{{ _t('Con quali tecnologie dovrà essere sviluppato?') }}</p>
          <div class="switchlist">
            <div v-for="tech in techSkills" :key="tech.id" class="switch">
              <toggle
                v-model="project.id_requirements"
                :on-value="tech.id"
                name="requisiti[]"
                :off-text="_t('No')"
                :on-text="_t('Sì')"
              >
                {{ tech.nome }}
              </toggle>
            </div>
          </div>

          <h3 class="skill_title">{{ _t('Settori merceologici') }}</h3>
          <p>{{ _t('In quale settore merceologico operi?') }}</p>
          <div class="switchlist">
            <div v-for="bs in businessSectors" :key="bs.id" class="switch">
              <toggle
                v-model="project.id_requirements"
                :on-value="bs.id"
                name="requisiti[]"
                :off-text="_t('No')"
                :on-text="_t('Sì')"
              >
                {{ bs.nome }}
              </toggle>
            </div>
          </div>

          <div class="form-buttons">
            <pf-button id="submit-project" type="submit" variant="primary">
              {{ $t('Invia') }}
            </pf-button> <em>{{ ' ' }}* {{ $t('Campo obbligatorio') }}</em>
          </div>
        </form>
      </div>
    </div>
  </page>
</template>

<style lang="scss" scoped>
.panel-heading h2 {
  margin: 5px 0;
}
.panel-body {
  max-width: 600px;
}
h3.skill_title{
  margin-top: 40px;
}
.switchlist {
  column-width: 250px;

  .switch {
    margin-bottom: 5px;
    min-width: 250px;
  }
}

.form-buttons {
  margin-top: 40px;
}

#submit-project {
  margin-right: 10px;
}
</style>

<script lang="ts" setup>
import { $t as _t } from '@/i18n';
import { ref, computed } from 'vue';
import { asyncComputedList } from '@common/asyncComputed';
import { useLoginStore } from '../store/login';
import Page from '../components/page.vue';
import { RequirementsResource, type Requirements, ProjectResource, type ProjectCreate } from '../resources';
import Toggle from '../components/toggle.vue';
import XInput from '../components/x-input.vue';
import cloneDeep from 'lodash-es/cloneDeep';
import ProjectList from '../components/project-list.vue';
import FormGroup from '@/components/form-group.vue';

const login = useLoginStore();

const emptyProject: ProjectCreate = {
  nome: '',
  id_requirements: [],
  skills: [],
  tech_skills: [],
  business_sectors: [],
  contatti: login.email,
};
const project = ref(cloneDeep(emptyProject));

function requirementsByType(tipo: Requirements['tipo']) {
  return requirements.value.filter(r => r.tipo === tipo).sort((a, b) => a.nome < b.nome ? -1 : 1 );
}

const { state: requirementsState, result: requirements } = asyncComputedList(() => new RequirementsResource().get());
const { state: projectsState, result: projects } = asyncComputedList(() => new ProjectResource().get());

const skills = computed(() => requirementsByType('skill'));
const techSkills = computed(() => requirementsByType('tech skill'));
const businessSectors = computed(() => requirementsByType('business sector'));

const newProjectSelectedSkills = computed(() => (skills.value.filter(r => project.value.id_requirements.includes(r.id))).length > 0);

const sending = ref(false);

async function submit() {
  sending.value = true;
  const projectRes = new ProjectResource();

  try{
    await projectRes.save(project.value);
  } finally {
    sending.value = false;
  }
  project.value = cloneDeep(emptyProject);
  projectsState.update();
}
</script>
