import { createApp } from 'vue';
import Admin from './admin.vue';

import VuePatternfly from 'vue-patternfly';
import Pf3Compat from '@common/pf3-compat';
import Pf5Compat from '@common/pf5-compat';

import * as Sentry from '@sentry/vue';

import i18n, { $t } from './i18n';
import { createPinia } from 'pinia';
import appGlobals from './app-globals';
import { useLoginStore } from './store/login';

import router from './router';
import { setDefaultFixedTimezone } from '@common/date';

setDefaultFixedTimezone();

const app = createApp(Admin);
app.use(createPinia());

if (typeof import.meta.env.SENTRY_DSN !== 'undefined' && import.meta.env.SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: typeof import.meta.env.SENTRY_DSN === 'string' ? import.meta.env.SENTRY_DSN : undefined,
    release: typeof import.meta.env.SENTRY_RELEASE === 'string' ? import.meta.env.SENTRY_RELEASE : undefined,
    environment: !window.location.hostname.endsWith('.net') ? 'development' :
      window.location.hostname.includes('-staging.') ? 'staging' : 'production',
    allowUrls: [/https:\/\/admin(?:-staging)?\.artera\.net/, /https?:\/\/admin\.artera\.vm/],
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/admin(?:-staging)?\.artera\.net\//, /^https:\/\/admin\.artera\.vm\//, /^\//],
    ignoreErrors: [
      'ResizeObserver loop',
      'Request aborted',
      'Request failed with status code 400',
      'Request failed with status code 401',
      'Request failed with status code 429',
      'Request failed with status code 500',
      'Request failed with status code 503',
      'Network Error',
    ],
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception && event.event_id) {
        try {
          console.log('Sentry exception:', event.exception);
        } catch {
          // ignore
        }

        const user = {} as Required<NonNullable<Parameters<typeof Sentry.showReportDialog>[0]>>['user'];
        try {
          const login = useLoginStore();
          user.email = login.username ?? undefined;
          user.name = login.azienda;
        } catch {
          // ignore
        }

        Sentry.showReportDialog({
          eventId: event.event_id,
          user,
          title: $t('Si è verificato un errore inaspettato'),
          subtitle: $t('Il reparto è stato notificato.'),
          subtitle2: $t('Per aiutare a identificare il problema descrivi cosa è successo qui sotto.'),
          labelName: $t('Operatore'),
          labelComments: $t('Cosa è successo?'),
          labelClose: $t('Chiudi'),
          labelSubmit: $t('Invia report'),
          successMessage: $t('Feedback inviato. Grazie!'),
        });
      }
      return event;
    },
  });
}

app.use(VuePatternfly);
app.use(Pf3Compat);
app.use(Pf5Compat);
app.use(router);
app.use(i18n);
app.use(appGlobals);
app.mount('#app');
