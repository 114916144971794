<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="title">
    <div v-if="wizard.askOs || wizard.askDurataFatturazione" class="row">
      <div v-if="wizard.askOs" class="col-md-3">
        <h3>{{ $t('Sistema Operativo') }}</h3>
        <wizard-question-os
          v-model="wizard.os"
          required
        />
      </div>
      <div v-if="wizard.askDurataFatturazione" class="col-md-3">
        <h3>{{ $t('Ricorrenza ') }}:</h3>
        <toggle
          v-model="wizard.fatturazioneAnnuale"
          :on-value="true"
          :off-value="false"
          :on-text="$t('Annuale')"
          :off-text="$t('Mensile')"
          off-class="btn-success"
        />
      </div>
    </div>

    <pf-flex align-items="flex-start">
      <pf-flex-item grow style="max-width: 800px">
        <div v-if="wizard.path === 'cloud'">
          <h3>{{ $t('Configura le caratteristiche del server') }}</h3>
          <wizard-configuratore-cloud
            v-model:cpu-qtn="wizard.cpuCloudQt"
            v-model:ram-qtn="wizard.ramCloudQt"
            v-model:storage-qtn="wizard.storageCloudQt"
          />
        </div>

        <div v-else class="question-wrapper">
          <h3>{{ $t('Seleziona il prodotto') }}</h3>
          <wizard-question-prodotti
            v-model="wizard.sceltaProdottoInfrastruttura"
            v-model:qt="wizard.storageQt"
            :ask-qt="wizard.askStorageQt"
            :prodotti="wizard.opzioniProdottiInfrastruttura()"
            name="sceltaProdottoInfrastruttura"
            show-feature
            :variant="wizard.path === 'storage' ? 'list' : 'table'"
            required
          />
        </div>
      </pf-flex-item>
      <pf-flex-item grow>
        <div v-if="wizard.askNomeServer">
          <h3>{{ $t('Nome del server') }}</h3>
          <wizard-question-nomeserver
            v-model="wizard.nomeServer"
          />
        </div>

        <template v-if="wizard.path === 'host'">
          <div class="question-wrapper">
            <h3>{{ $t('Vuoi attivare 32 GB di RAM aggiuntivi?') }}</h3>
            <wizard-question-prodotto
              v-model="wizard.addOn32Ram"
              :product="wizard.getProdotto(wizard.codiciProdottiAddon.addOn32Ram)"
              show-prezzo
              :on-text="$t('Sì')"
              :off-text="$t('No')"
            />
          </div>
          <div class="question-wrapper">
            <h3>{{ $t('Vuoi attivare anche un 8 IP dedicati aggiuntivi?') }}</h3>
            <wizard-question-prodotto
              v-model="wizard.addOn8Ip"
              :product="wizard.getProdotto(wizard.codiciProdottiAddon.addOn8Ip)"
              show-prezzo
              :on-text="$t('Sì')"
              :off-text="$t('No')"
            />
          </div>
        </template>

        <div v-if="wizard.askFirewall" class="question-wrapper">
          <h3>{{ $t('Vuoi attivare anche un firewall dedicato?') }}</h3>
          <wizard-question-prodotto
            v-model="wizard.addOnFirewall"
            :product="wizard.firewall"
            show-prezzo
            :off-text="$t('No, utilizzo quello a bordo del server')"
          />
        </div>
      </pf-flex-item>
    </pf-flex>
  </wizard-form>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import Toggle from '../../toggle.vue';
import WizardQuestionOs from '../questions/wizard-question-os.vue';
import wizardQuestionProdotto from '../questions/wizard-question-prodotto.vue';
import WizardQuestionProdotti from '../questions/wizard-question-prodotti.vue';
import WizardConfiguratoreCloud from '../questions/wizard-configuratore-cloud.vue';
import WizardQuestionNomeserver from '../questions/wizard-question-nomeserver.vue';
import WizardForm from '../wizard-form.vue';

defineOptions({
  name: 'WizardStepProdottiInfrastruttura',
});

const props = defineProps<OUIAProps>();
const ouiaProps = useOUIAProps(props);

const { wizard, hidden, isEnabled } = useWizardStep({
  isEnabled: wizard => wizard.askProdotti,
});

defineExpose({
  isEnabled,
});

const title = computed(() => wizard.path === 'cloud' ? "Configura il tuo server" : "Scelta del prodotto");
</script>
