<template>
  <pf-list-item>
    <template #heading>
      {{ server.nome }}
    </template>

    <template v-if="showFeatures || showLocation || showAdmin" #additional-info>
      <pf-list-item-additional-info expandable>
        <server-icon /> {{ $t('Caratteristiche e Amministrazione') }}

        <template #expansion>
          <div class="row">
            <div v-if="showFeatures" class="col-md-4">
              <h3>{{ $t('Caratteristiche') }}</h3>
              <p v-if="server.os">
                <strong>{{ $t('Sistema operativo') }}:</strong> {{ server.os }} {{ server.osrelease }}
              </p>
              <p v-if="server.ip4_public">
                <strong> {{ $t('Indirizzo IP') }}:</strong> {{ server.ip4_public }}
              </p>
              <p v-if="server.fqdn">
                <strong> {{ $t('Dominio') }}:</strong> {{ server.fqdn }}
              </p>
            </div>

            <div v-if="showLocation">
              <div v-if="server.server_location" class="col-md-4">
                <h3>{{ $t('Server') }}</h3>
                <p>{{ $t('Location') }}: {{ server.server_location }}</p>
              </div>
              <div v-if="server.backup_location" class="col-md-4">
                <h3>{{ $t('Backup') }}</h3>
                <p>{{ $t('Location') }}: {{ server.backup_location }}</p>
                <p>{{ allFeatures.backup.tooltip }}</p>
                <p>{{ $t('Stato') }}: {{ $t('Attivo') }} <circle-icon class="text-success" /></p>
              </div>
            </div>

            <div v-if="showAdmin" class="col-md-4">
              <h3>{{ $t('Amministrazione') }}</h3>
              <p>
                <a v-if="server.cpanel" class="btn btn-primary" :href="`https://${server.fqdn}:2087/`" target="_blank">
                  <arrow-up-right-from-square-icon /> {{ $t('Accedi a WHM') }}
                </a>
                {{ ' ' }}
                <a v-if="server.has_cockpit" class="btn btn-primary" :href="`https://${server.fqdn}/cockpit`" target="_blank">
                  <arrow-up-right-from-square-icon /> {{ $t('Accedi a Cockpit') }}
                </a>
                {{ ' ' }}
                <a v-if="server.has_console" class="btn btn-primary" :href="`/console/?id=${server.id}/`" target="popup" @click.prevent="popup(`/console/?id=${server.id}/`)">
                  <VirtualMachineIcon /> {{ $t('Apri console') }}
                </a>
              </p>
            </div>
          </div>
        </template>
      </pf-list-item-additional-info>

    </template>
  </pf-list-item>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { Server } from '@/resources';
import { allFeatures } from './product-feature.vue';
import ArrowUpRightFromSquareIcon from '@vue-patternfly/icons/arrow-up-right-from-square-icon';
import ServerIcon from '@vue-patternfly/icons/server-alt-icon';
import VirtualMachineIcon from '@vue-patternfly/icons/virtual-machine-icon';
import CircleIcon from '@vue-patternfly/icons/circle-icon';

const props = defineProps<{
  server: Server;
}>();

const showFeatures = computed(() =>  !!props.server.os || !!props.server.fqdn || !!props.server.ip4_public);
const showLocation = computed(() =>  !!props.server.server_location || !!props.server.backup_location);
const showAdmin = computed(() =>  Boolean(props.server.fqdn && (props.server.cpanel || props.server.has_cockpit)) || props.server.has_console);


function  popup(url: string, options = 'width=1250,height=1000') {
  window.open(url, 'popup', options);
}
</script>
