<template>
  <page :name="$t('Offerta fai da te')" :description="$t('Costruisci la tua offerta personalizzata')">
    <wizard
      :searched-domain="domainName"
      :available-domain="domainAvailable"
    />
  </page>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import Page from '../../components/page.vue';
import Wizard from '../../components/wizard/wizard.vue';
import { pop } from '@common/utils';
import { useRoute } from 'vue-router';

const route = useRoute();

const domainAvailable = computed(() => route.query.domainAvailable === "true" || route.query.domainAvailable === "1");
const domainName = computed(() => pop(route.query.domainName));
</script>
