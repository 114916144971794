<template>
  <login-layout>
    <header v-if="!isInIframe" class="login-pf-header">
      <h1 v-if="invite.azienda">{{ $t("Registrati per accettare l'invito di {0}", [invite.azienda]) }}</h1>
      <h1 v-else>{{ $t('Crea il tuo Account Artera') }}</h1>
    </header>

    <div v-if="invite.azienda">
      {{
        $t("Effettuando la registrazione verrai collegato all'account di {0} e sarai abilitato a:", [invite.azienda])
      }}
      <ul>
        <li v-if="invite.grants">{{ $t('Accedere alla sua area personale dalla tua utenza') }}</li>
        <li v-if="invite.email_subscriptions?.includes('technical')">
          {{ $t("Ricevere in copia le notifiche tecniche dell'azienda") }}
        </li>
        <li v-if="invite.email_subscriptions?.includes('billing')">
          {{ $t("Ricevere in copia le notifiche amministrative dell'azienda") }}
        </li>
        <li v-if="invite.email_subscriptions?.includes('marketing')">
          {{ $t("Ricevere in copia le notifiche commerciali dell'azienda") }}
        </li>
      </ul>
    </div>

    <form ref="form" @submit.prevent="submit">
      <form-group :name="$t('Nome e cognome / Ragione Sociale')" no-label>
        <x-input
          id="signupRagSoc"
          v-model="name"
          type="text"
          name="ragione_sociale"
          :placeholder="$t('Nome e cognome / Ragione Sociale')"
          required
          :disabled="sending"
          autocomplete="organization"
        />
      </form-group>

      <form-group :name="$t('Email')" no-label>
        <x-input
          id="signupEmail"
          v-model="email"
          type="email"
          name="email"
          :placeholder="$t('Email')"
          required
          :disabled="sending"
          :readonly="!!invite.azienda || emailReadOnly"
          autocomplete="email"
        />
        <input v-if="$route.query.verified" type="hidden" name="verified" :value="$route.query.verified">
      </form-group>

      <form-group :name="$t('Password')" no-label>
        <x-input
          id="signupPassword"
          type="password"
          name="password"
          :placeholder="$t('Password')"
          required
          :disabled="sending"
          autocomplete="new-password"
          minlength="8"
        />
      </form-group>

      <div class="signup-checkboxes">
        <div class="form-group login-pf-settings">
          <label class="checkbox-label">
            <input type="checkbox" name="ack_privacy" required :disabled="sending">
            {{ ' ' }}
            <i18n-t keypath="Accetto la {0}" tag="span">
              <a href="https://www.artera.net/it/informativa-privacy/" target="_blank" rel="noopener">{{
                $t('Privacy Policy')
              }}</a>.
            </i18n-t>
          </label>
        </div>

        <div class="form-group login-pf-settings">
          <label class="checkbox-label">
            <input type="checkbox" name="ack_marketing" :disabled="sending">
            {{
              $t('Accetto di ricevere altri messaggi: notizie, contenuti didattici e contenuti relativi ai prodotti offerti')
            }}
          </label>
        </div>
      </div>

      <div class="login-buttons">
        <pf-button type="submit" variant="primary" block large :disabled="sending">
          {{ isInIframe ? $t('Iscriviti ora') : $t('Procedi') }}
          <div v-if="sending" class="spinner spinner-xs" />
        </pf-button>

        <template v-if="!emailReadOnly">
          <div class="separator">
            <span>{{ $t('oppure') }}</span>
          </div>

          <div ref="googleLoginButton" style="margin-top: 10px" />

          <a v-if="linkedInLoginUrl" :href="linkedInLoginUrl" :target="isInIframe ? '_blank' : undefined" class="btn-linkedin btn-block btn-lg" :class="{ disabled: sending }" @click="sending ? $event.preventDefault() : null">
            <img src="@/assets/linkedin-in.png" width="30" height="30" alt="LinkedIn" style="margin-right: 4px">
            <span>
              {{ $t('Accedi con LinkedIn') }}
            </span>
          </a>
        </template>
      </div>
    </form>

    <p v-if="!isInIframe" class="login-pf-signup">
      {{ $t('Hai già un account?') }}
      <router-link :to="{ name: 'login' }">{{ $t('Log In') }}</router-link>
    </p>
  </login-layout>
</template>

<style>
.footer-arteranet body,
.sito-footer body,
.sito-registradominio body,
.landing-shared-hosting body {
  background-color: transparent;
}
</style>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  background-color: transparent;
}

.login-pf-page .login-pf-header {
  align-items: center;

  img {
    width: 100%;
    max-width: 640px;
    height: auto;
  }
}

.promo-hosting-premium,
.home-arteranet,
.sito-home {
  form {
    padding: 40px;

    .form-control {
      font-family: 'Montserrat';
      font-size: 14px;
      line-height: 17px;
      border: none;
      background: #F4F5F7;
      border-radius: 8px;
      padding: 25px 20px;
      color: #878787;
      margin-bottom: 8px;
      box-shadow: none;
      transition: none;
    }

    label {
      font-family: 'Montserrat';
      font-size: 12px;
      line-height: 15px;
      color: #0B132A;
      font-weight: 400;
    }



    @media (max-width: 991) {
      .signup-checkboxes {
        float: none;
        width: 100%;
        margin-bottom: 20px;
      }

      .btn-primary {
        float: none;
        width: 100%;
      }
    }
  }
}

.promo-hosting-premium {

  .signup-checkboxes {
    .form-group {
      margin-bottom: 0;
    }
  }

  .btn-primary {
    margin-top: 10px;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    border:0;
    color: #FFFFFF;
    background: #F58357;
    position: relative;
    transition: ease-in-out;
    -webkit-transition: ease-in-out;
    border-radius: 20px;
    float: right;

    &:hover {
      background: linear-gradient(90deg, #fb9669, #cf4c1b);
    }
  }
}

.home-arteranet .btn-primary,
.sito-home .btn-primary{
  background: #FFFFFF;
  color: #222;
  border-color: #222;
  border-radius: 20px;

  &:hover {
    background: #222;
    color: #FFFFFF;
  }
}

.footer-arteranet,
.sito-footer,
.sito-registradominio {
  background-color: #EE824F;

  form {
    font-family: 'Roboto', sans-serif;
    background-color: #3d3d3d;
    letter-spacing: 0;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    /*font-size: 1.35rem;*/
    /*line-height: 1.5;*/
    color: #fff;

    .form-group {
      margin-bottom: 15px;
    }

    button.btn {
      margin-top: 20px;
      background-image: none;
      padding: 6px 25px;
      background-color: #EE824F;
      border-color: #222;
      border-radius: 20px;
      color:#fff;

      &:hover {
        background-color: #222;
        border-color: #222;
        color: #fff;
      }

      &.theme-color {
        background-color: #d3d3d3;
        border-color: #EE824F;
        color: #EE824F;

        &:hover {
          background-color: #EE824F;
          border-color: #EE824F;
          color: #fff;
        }
      }
    }

    .btn {
      font-weight: 400;
      font-size: 13px;
      line-height: 1.8;
      color: #222;
      text-decoration: none;
      text-align: center;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
      font-size: 13px;
      position: relative;
      line-height: 1.8;
      text-align: left;
      padding: 6px 15px;
      border-radius: 10px;
      border: 1px solid #fff;
      color: #222;
      min-width: 250px;
    }

    .signup-checkboxes {

      .form-group {
        margin-bottom: 0;
      }
    }

    label {
      font-family: 'Roboto';
      font-size: 12px;
      line-height: 15px;
      color: #fff;
      font-weight: 400;
      margin-top: 6px;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }

    .login-buttons .separator {
      text-transform: capitalize;

      &:after {
        border-top: 1px solid #222;
      }

      > span {
        background-color: #3d3d3d;
        color: #fff
      }
    }
  }
}

.landing-shared-hosting {
  background-color: transparent;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    font-size: 14px;
    position: relative;
    border-radius: 4px;
    min-width: 250px;
    height: 48px;
    outline: none;
    color: #999;
    box-shadow: none;
    padding: 6px 23px;
    background: #f5f5f5;
    border-color: #eee;
  }

  label {
    color: #fff;
  }

  .btn-primary {
    width: 100%;
    background-image: none;
    background-color: red;
    border: none;
    border-radius: 2px;
    padding: 11px 28px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      background-color: #1c1c1c;
      color: #EE824F;
    }
  }
}

.landing-seeweb-swiss .btn-primary {
  font-family: sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #fff;
  background: #e52f37;
  border: 1px solid #e52f37;
  box-shadow: 0px 5px 20px 0 rgb(0 0 0 / 27%);
  letter-spacing: 1px;
  border-radius: 23px 23px;
  margin: 5px 10px 10px 0;
  display: inline-block;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  box-shadow: 0px 5px 20px 0 rgb(0 0 0 / 27%);

  &:hover {
    background-color: #e52f37;
    color: #FFFFFF;
    box-shadow: 0px 4px 10px 0 rgb(0 0 0 / 50%);
  }
}

</style>

<script lang="ts">
import { http } from '../../http';
import { setupAsyncComputed } from '@common/asyncComputed';
import { loginDestination } from '../../utils';
import LoginLayout from '../../components/login-layout.vue';
import XInput from '@/components/x-input.vue';
import { Resource } from '@/resources';
import { defineComponent, type Ref, ref } from 'vue';
import type { Invite } from '@/typings/resources';
import { useAppStore } from '@/store/app';
import { useRoute } from 'vue-router';
import { injectScript, pop } from '@common/utils';
import { useOauthUrls } from './use';
import FormGroup from '@/components/form-group.vue';

export default defineComponent({
  name: 'SignupPage',

  components: {
    LoginLayout,
    XInput,
    FormGroup,
  },

  setup() {
    const app = useAppStore();
    const route = useRoute();

    const name = ref(pop(route.query.name) ?? '');
    const email = ref(pop(route.query.email) ?? '');

    const emailReadOnly = ref(email.value !== '');
    const googleLoginButton: Ref<HTMLDivElement | null> = ref(null);

    return {
      ...useOauthUrls(),
      name,
      email,
      emailReadOnly,
      googleLoginButton,
      isInIframe: app.isInIframe,
      ...setupAsyncComputed({
        invite: {
          async get(this: any): Promise<Invite> {
            if (!this.$route.query.antk) {
              return {};
            }

            const invite = await new Resource('invites').get(this.$route.query.antk);
            email.value = invite.email;
            emailReadOnly.value = true;
            invite.secret = this.$route.query.antk;

            if (invite.username) {
              this.$router.push({
                name: 'login',
                query: this.$route.query,
              });
            }

            return invite;
          },
          default: {} as Invite,
        },
      }),
    };
  },

  data() {
    return {
      sending: false,
    };
  },

  async mounted() {
    try {
      const qRef = pop(this.$route.query.ref);
      const afid = pop(this.$route.query.afid);
      const aftk = pop(this.$route.query.aftk);

      if (qRef) {
        localStorage.setItem('ref', qRef);
      }

      if (afid && aftk) {
        localStorage.setItem('afid', afid);
        localStorage.setItem('aftk', aftk);
      }
    } catch (e) {
      // ignore security error in case of block on third party cookies
      if (!(e instanceof DOMException) || e.name !== 'SecurityError') {
        throw e;
      }
    }

    this.initGoogleClient();
  },

  methods: {
    async initGoogleClient() {
      if (typeof import.meta.env.GOOGLE_CLIENT_ID !== 'string') {
        return;
      }

      try {
        await injectScript('https://accounts.google.com/gsi/client');
      } catch (e) {
        // Failed to load
        return;
      }

      const google: typeof import('google-one-tap') | undefined = (window as any).google;
      if (!google) {
        return;
      }

      google.accounts.id.initialize({
        client_id: import.meta.env.GOOGLE_CLIENT_ID,
        callback: async({credential}: {credential: string}) => {
          const data = new FormData();
          data.append('google_token', credential);
          data.append('check', await this.fetchToken());

          const response = (await http.post('/api/auth/login', data)).data;
          if (response.result === 'ok') {
            const destination = loginDestination(this.$route.query);
            if (this.isInIframe) {
              window.open(destination, '_blank');
            } else {
              this.$router.push(destination);
            }
          } else if (response.name) {
            this.name = response.name;
            this.email = response.email;
            this.emailReadOnly = true;
          }
        },
      });

      if (this.googleLoginButton) {
        google.accounts.id.renderButton(this.googleLoginButton, { theme: "outline", size: "large" });
      }
    },

    async submit() {
      this.sending = true;

      try {
        const data = new FormData(this.$refs.form as HTMLFormElement);
        data.append('check', await this.fetchToken());

        try {
          const afid = localStorage.getItem('afid');
          const aftk = localStorage.getItem('aftk');
          if (afid && aftk) {
            data.append('afid', afid);
            data.append('aftk', aftk);
          }
        } catch (e) {
          // ignore security error in case of block on third party cookies
          if (!(e instanceof DOMException) || e.name !== 'SecurityError') {
            throw e;
          }
        }

        if (this.invite.secret) {
          data.append('antk', this.invite.secret);
        }

        const prospect_source = pop(this.$route.query.prospect_source);
        if (prospect_source) {
          data.append('prospect_source', prospect_source);
        }

        const response = await http.post('/api/auth/signup', data);

        let url: URL | null = null;
        if (this.isInIframe) {
          window.open(`https://www.artera.net/it/thankyou-admin?prospect_source=${prospect_source}`, '_blank');
          return;
        } else if(response.data.goto) {
          url = new URL(response.data.goto, window.location.href);
        } else if (response.data.result == 'ok') {
          this.$router.push(loginDestination(this.$route.query));
          return;
        }

        if (!url) {
          throw new Error('Invalid response');
        }

        const qRef = pop(this.$route.query.ref);
        if (qRef) {
          url.searchParams.append('ref', qRef);
        }

        if (prospect_source) {
          url.searchParams.append('prospect_source', prospect_source);
        }

        this.$router.push(url.toString().substring(url.origin.length));
      } finally {
        this.sending = false;
      }
    },

    async fetchToken() {
      return (await http.post('/api/auth/token')).data.token;
    },
  },
});
</script>
