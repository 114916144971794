<template>
  <ul class="radio-wrapper">
    <li class="radio-description" style="width: 100%">
      <label>
        <input
          type="radio"
          :checked="modelValue.length === 0"
          @change="reset"
        >
        {{ $t('Nessuno') }}
      </label>
    </li>
    <li v-for="opt of wizard.opzioniTecnologie" :key="opt" class="radio-description">
      <label>
        <input
          type="checkbox"
          name="tecnologie[]"
          :value="opt"
          :checked="modelValue.includes(opt)"
          @change="change"
        >
        {{ opt }}
      </label>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { useWizardQuestion } from '../composition';

defineProps<{
  modelValue: (typeof wizard.opzioniTecnologie[number])[];
}>();

const { wizard } = useWizardQuestion();

function change(e: Event) {
  if (e.target instanceof HTMLInputElement) {
    if (e.target.checked) {
      wizard.tecnologie.push(e.target.value as typeof wizard.opzioniTecnologie[number]);
    } else {
      wizard.tecnologie.splice(wizard.tecnologie.indexOf(e.target.value as typeof wizard.opzioniTecnologie[number]), 1);
    }
  }
}

function reset(e: Event) {
  wizard.tecnologie = [];
}
</script>
