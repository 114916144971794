import { onBeforeMount, watch as vueWatch, watchEffect, reactive, computed, getCurrentInstance, ref, toValue, type ComputedRef, type WatchStopHandle, type Ref, type WatchOptions, toRaw } from 'vue';
import { sleep } from './utils';
import type { WatchSource } from 'vue';
import axios from 'axios';
import { isDefined } from '@vueuse/shared';
import isEqual from 'lodash-es/isEqual';

export enum AsyncComputedState {
  Pending = 'pending',
  Updating = 'updating',
  Success = 'success',
  Error = 'error',
}

export interface AsyncComputedStateObject {
  hasEverRun: boolean;
  error?: unknown;
  state: AsyncComputedState;
  success: boolean;
  pending: boolean;
  updating: boolean;
  ready: () => Promise<void>;
  update: () => Promise<void>;
}

export interface AsyncComputed<T> {
  result: ComputedRef<T>;
  state: AsyncComputedStateObject;
}

type AsyncComputedGetFn<T, D = T, V = any> = (prev: T | D, watchVal?: V, prevWatchVal?: V) => MaybePromise<T | D>;

interface AsyncComputedDefinition<T, D = T, V = any> {
  get: AsyncComputedGetFn<T, D>;
  default?: D;
  lazy?: boolean;
  debounce?: number;
  options?: WatchOptions | undefined;
  watch?: WatchSource<V>,
}

type AsyncComputedDefinitionVariants<T, D = T, V = any> =
  AsyncComputedDefinition<T, D, V>
  | MaybePromise<T>
  | AsyncComputed<T>;

type AsyncComputedDefinitions = Record<string, AsyncComputedDefinitionVariants<any>>;

export type ResolvedComputedPromise<T> =
  T extends AsyncComputedDefinition<infer U, infer D> ? (D extends never[] ? Awaited<U> : Awaited<U> | D) :
  T extends AsyncComputedDefinition<infer U> ? Awaited<U> :
  T extends AsyncComputed<infer U> ? U :
  T extends Promise<infer U> ? U :
  T extends () => infer U ? U :
  never;

type AsyncComputedComponentProperties<T> =
  T extends undefined ? Record<string, any> & {
    asyncComputed: Record<string, AsyncComputedStateObject>;
  } :
  {
    [K in keyof T]: ComputedRef<ResolvedComputedPromise<T[K]>>;
  } & {
    asyncComputed: Record<string, AsyncComputedStateObject>;
  };

function isAsyncComputed(computed: any): computed is AsyncComputed<any> {
  return typeof computed === 'object' && Object.keys(computed).length === 2 && 'result' in computed && 'state' in computed;
}

export function setupAsyncComputed<T extends AsyncComputedDefinitions>(definitions?: T): AsyncComputedComponentProperties<T> {
  const instance = getCurrentInstance()?.proxy;

  const computed: Record<string, ComputedRef> = {};
  const computedStates: Record<string, AsyncComputedStateObject> = {};

  // eslint-disable-next-line prefer-const
  for (let [name, computedProps] of Object.entries(definitions || {})) {
    if (!isAsyncComputed(computedProps)) {
      if (typeof computedProps === 'function') {
        computedProps = {
          get: computedProps,
          default: undefined,
        };
      }

      computedProps = asyncComputed(computedProps.get.bind(instance), computedProps.default, computedProps.watch || undefined, {
        lazy: computedProps.lazy,
        debounce: computedProps.debounce,
        deep: computedProps.deep,
      });
    }

    computed[name] = computedProps.result;
    computedStates[name] = computedProps.state;
  }

  return {
    ...computed,
    asyncComputed: computedStates,
  } as any;
}

type AsyncComputedOptions = Pick<WatchOptions, 'deep'> & {
  debounce?: number;
  lazy?: boolean;
};

/**
 * Definisce un asyncComputed che ritorna un array ed il cui default è un array vuoto dello stesso tipo.
 * "debounce" works better in combination with "watch"
 */
export function asyncComputedList<T, V = unknown>(func: AsyncComputedGetFn<T[]>, watch?: WatchSource<V>, options?: AsyncComputedOptions | undefined): AsyncComputed<T[]> {
  const def: ReturnType<Awaited<typeof func>> = [];
  return asyncComputed(func, def, watch, options);
}

export function asyncComputed<T, D = T, V = unknown>(func: AsyncComputedGetFn<T, D>, def?: D, watch?: WatchSource<V>, options?: AsyncComputedOptions | undefined): AsyncComputed<T | D>;
export function asyncComputed(func: AsyncComputedGetFn<any>, def?: any, watch?: WatchSource<unknown>, options: AsyncComputedOptions = {}): AsyncComputed<unknown> {
  const lazy = options.lazy ?? false;
  const debounce = options.debounce ?? 0;
  const watchDeep = options.deep ?? false;

  const ctx: {
    lastCallee: symbol | null;
    stopLast: WatchStopHandle | null;
    hasEverRequested: boolean;
    resultData: Ref<any>;
    prevValue: any;
  } = {
    lastCallee: null,
    stopLast: null,
    hasEverRequested: false,
    resultData: ref(def),
    prevValue: def,
  };

  let resolveReadyPromise: undefined | (() => void) = undefined;
  const readyPromise = new Promise<void>(resolve => (resolveReadyPromise = resolve));

  const stateObject: AsyncComputedStateObject = reactive({
    hasEverRun: false,
    state: AsyncComputedState.Pending,
    success: computed(() => stateObject.state === AsyncComputedState.Success) as unknown as boolean,
    pending: computed(() => stateObject.state === AsyncComputedState.Pending) as unknown as boolean,
    updating: computed(() => stateObject.state === AsyncComputedState.Updating) as unknown as boolean,
    async ready() {
      if (stateObject.state !== AsyncComputedState.Pending) {
        return;
      }
      return await readyPromise;
    },
    error: null,

    update() {
      return new Promise<void>((resolve, reject) => {
        ctx.hasEverRequested = true;
        if (ctx.stopLast) {
          ctx.stopLast();
          ctx.stopLast = null;
        }

        const watchFn = async (watchVal?: unknown, prevWatchVal?: unknown) => {
          if (resolveReadyPromise === undefined && prevWatchVal !== undefined && isEqual(toRaw(watchVal), toRaw(prevWatchVal))) {
            resolve();
            return;
          }

          const me = Symbol('retry');
          const firstCallee = ctx.lastCallee === null;

          // debouncing with watchEffect should not drop first call
          if (!debounce || watch) {
            ctx.lastCallee = me;
          }

          if (Boolean(debounce)) {
            // we cannot short-circuit first call with watchEffects otherwise no "effects" would be tracked in later calls
            if (watch || !firstCallee) {
              await sleep(debounce);
            }
            if (watch && ctx.lastCallee !== me) {
              resolve();
              return;
            }
          }

          if (debounce && !watch) {
            ctx.lastCallee = me;
          }

          setState(AsyncComputedState.Updating);

          const onFullfilled = (value: any) => {
            resolveReadyPromise?.();
            resolveReadyPromise = undefined;

            if (ctx.lastCallee === me) {
              setState(AsyncComputedState.Success);
              ctx.resultData.value = value;
              ctx.prevValue = value;
              stateObject.hasEverRun = true;
            }
            resolve();
          };

          const onRejected = (error: any) => {
            resolveReadyPromise?.();
            resolveReadyPromise = undefined;

            if (ctx.lastCallee === me) {
              setState(AsyncComputedState.Error, error);
              stateObject.hasEverRun = true;
            }
            if (axios.isAxiosError(error) && error.response && [401, 403, 404, 410].includes(error.response.status)) {
              resolve();
            } else {
              reject(error);
            }
          };

          let maybePromise;
          try {
            maybePromise = func(ctx.prevValue, watchVal, prevWatchVal);
          } catch (error) {
            onRejected(error);
            return;
          }

          if (maybePromise instanceof Promise) {
            maybePromise.then(onFullfilled, onRejected);
          } else {
            onFullfilled(maybePromise);
          }
        };

        ctx.stopLast = isDefined(watch)
          ? vueWatch(watch, watchFn, { immediate: true, deep: watchDeep })
          : watchEffect(watchFn);
      });
    },
  });

  function setState(state: AsyncComputedState, error: any = null) {
    stateObject.state = state;
    stateObject.error = error;
  }

  const result = computed(() => {
    if (!ctx.hasEverRequested) {
      stateObject.update();
    }
    return toValue(ctx.resultData);
  });

  if (!lazy) {
    onBeforeMount(stateObject.update);
  }

  return {
    result,
    state: stateObject,
  };
}
