<template>
  <form role="form">
    <form-group v-for="ccp of categories" :name="ccp.category">
      <x-input
        v-model="ccp.markup"
        :name="ccp.category"
        type="number"
        min="0"
        step="1"
        required
        append="%"
        class="markup"
      />
    </form-group>
    <pf-button variant="primary" @click="save">{{ $t('Salva') }}</pf-button>{{ ' ' }}
    <pf-button @click="emit('close')">{{ $t('Annulla') }}</pf-button>
  </form>
</template>

<style scoped>
.markup {
  max-width: 100px;
}
</style>

<script lang="ts" setup>
import { ref } from 'vue';
import { type CategoryCustomPrice, CategoryCustomPricesResource } from '@/resources';
import { cloneDeep } from 'lodash-es';
import FormGroup from './form-group.vue';
import XInput from './x-input.vue';

const props = defineProps<{
  categoryCustomPrices: CategoryCustomPrice[];
}>();

const categories = ref(cloneDeep(props.categoryCustomPrices));

const emit = defineEmits<{
  (e: 'submit'): void;
  (e: 'close'): void;
}>();

async function save() {
  categories.value = categories.value.map(item => ({
      category: item.category,
      markup: Number(item.markup),
  }));
  await new CategoryCustomPricesResource().save(categories.value);
  emit('submit');
}
</script>
