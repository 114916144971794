<template>
  <page
    :name="$t('Scadenza')"
    :title="title"
    :state="paymentState"
  >
    <template #breadcrumbs>
      <router-link v-slot="{ navigate }" custom :to="{ name: 'invoices' }">
        <li>
          <a @click="navigate">{{ $t('Fatture') }}</a>
        </li>
      </router-link>
    </template>

    <payment-report
      v-if="payment"
      :result="Number($route.query.res)"
      :error="$pop($route.query.err)"
      :success="$t('Saldo scadenza confermato')"
      route="invoices"
      :link="$t('Torna alla lista delle fatture')"
    >
      <p>{{ $t('Scadenza al {0}', [$date(payment.data)]) }}</p>

      <form v-if="payment.amount > 0" method="post" action="/api/pay_invoice" @submit="submit">
        <fieldset :disabled="!payment.id">
          <input type="hidden" name="payment_id" :value="payment.id">
          <payment-methods
            v-model="method"
            :totale-offerta-ivato="payment.amount"
            :currency="login.currency"
            invoice
          />

          <h3 class="subtitle">
            {{ $t('Totale') }}: {{ $currency(payment.amount, login.currency) }} <small>{{ $t('IVA inclusa') }}</small>
          </h3>

          <pf-button type="submit" variant="primary" :disabled="!method">
            {{ $t('Procedi al pagamento') }}
          </pf-button>{{ ' ' }}<em>{{ $t("Verrai reindirizzato alla pagina di pagamento. L'operazione può durare qualche secondo.") }}</em>
        </fieldset>
      </form>
    </payment-report>
    <pf-notification v-else type="danger">
      {{ $t("Errore: Pagamento non trovato") }}
    </pf-notification>
  </page>
</template>

<style scoped>
.form-group.methods label {
  margin-right: 15px;
}
</style>

<script lang="ts" setup>
import { ref, type Ref, computed } from 'vue';
import Page from '../components/page.vue';
import PaymentReport from '../components/payment-report.vue';
import PaymentMethods, { type Method} from '../components/payment-methods.vue';
import { asyncComputed } from '@common/asyncComputed';
import { useLoginStore } from '@/store/login';
import { usePaypalStore } from '@/store/paypal';
import { PaymentsResource } from '@/resources';
import { pop } from '@common/utils';
import { useRoute } from 'vue-router';

const route = useRoute();
const login = useLoginStore();

const { state: paymentState, result: payment } = asyncComputed(async () => {
  const id = pop(route.params.id)
  if (login.id_azienda && id) {
    return await new PaymentsResource().get(id);
  }
  return null;
});

const method: Ref<Method | undefined> = ref();

function submit(e: Event) {
  if (!method.value) {
    e.preventDefault();
  }
  if (method.value === 'axerve-paypal') {
    const paypal = usePaypalStore();
    paypal.save();
  }
};

const title = computed(() => {
  return `${payment.value?.fattura ? `${payment.value?.tipo} nr. ${payment.value.fattura}: ` : ''}${payment.value?.descrizione}`;
});
</script>
