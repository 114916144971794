<template>
  <template v-if="visible">
    <router-link v-slot="{ navigate, href }" :to="granted ? to : {}" custom>
      <li v-if="menu" v-bind="($attrs as any)" class="list-group-item" :class="{disabled: !granted, active}" data-ouia-component-type="V-PF3/MenuItem">
        <a :href="href" :title="title" :target="target" @click.prevent="granted ? navigate() : undefined" @keypress.enter.prevent="granted ? navigate() : undefined">
          <span v-if="$slots.icon" class="fa">
            <slot name="icon" />
          </span>
          <span class="list-group-item-value">
            <capture-text @change="$event => title = $event.toString().trim()">
              <slot />
            </capture-text>
            <slot />
          </span>
        </a>
        {{ ' ' }}
        <form-help-tooltip
          v-if="!granted"
          :text="disabledMessage"
        />
      </li>

      <a v-else-if="granted" v-bind="$attrs" :href="href" :target="target" :title="title" @click="navigate" @keypress.enter="navigate()">
        <slot />
      </a>

      <span v-else v-bind="($attrs as any)" class="disabled" :title="title">
        <slot />
        {{ ' ' }}
        <form-help-tooltip :text="disabledMessage" />
      </span>
    </router-link>
  </template>
</template>

<style lang="scss">
.nav-pf-vertical {
  .list-group-item.nav-group {
    &,
    &:active,
    &:hover {
      > a,
      > a:focus {
        font-weight: normal;
      }

      > a:hover {
        font-weight: 600;
      }
    }
  }

  .list-group-item.disabled {
    a {
      cursor: not-allowed;
      color: #717171;
    }

    .pficon,
    .fa {
      color: #515151;
    }

    &,
    &:active,
    &:hover {
      cursor: initial;
      background-color: transparent;

      > a,
      > a:focus {
        background-color: transparent;
        color: #717171;
        font-weight: normal;

        .pficon,
        .fa {
          color: #515151;
        }
      }
    }
  }

  .form-help-tooltip {
    padding: 0 15px 0 10px;
    align-items: center;
    display: flex;
  }
}
</style>

<script lang="ts" setup>
import { routeIsGranted, routeIsVisible } from '@/utils';
import { $t } from '@/i18n';
import { useRouter, type RouteLocationRaw } from 'vue-router';
import { computed, ref, type HTMLAttributes } from 'vue';
import { useChildrenTracker } from '@common/use';
import { GrantedLinkKey } from './nav-group.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import CaptureText from '@common/pf5-compat/CaptureText.vue';

export interface Props extends /* @vue-ignore */ HTMLAttributes {
  to: RouteLocationRaw;
  forceDisabled?: boolean;
  disabledMessage?: string;
  menu?: boolean;
  vertical?: boolean;
  target?: string;
  title?: string;
}

defineOptions({
  inheritAttrs: false,
});

useChildrenTracker(GrantedLinkKey);

const props = withDefaults(defineProps<Props>(), {
  disabledMessage: $t('Non hai i permessi per visualizzare questa pagina. Richiedili ad un utente amministratore.'),
});

const router = useRouter();
const route = computed(() => router.resolve(props.to));
const granted = computed(() => routeIsGranted(route.value) && !props.forceDisabled);
const visible = computed(() => routeIsVisible(route.value));
const title = ref(props.title);
const active = computed(() =>router.currentRoute.value.name === route.value.name);

defineExpose({
  visible,
  active,
});
</script>
