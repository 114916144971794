<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Scelta dello stack')">
    <pf-flex align-items="flex-start">
      <pf-flex-item v-if="wizard.askStack" grow style="max-width: 700px">
        <h3>{{ $t('Quale stack vuoi attivare?') }}</h3>
        <label>
          <input v-model="wizard.stack" type="radio" name="stack" :value="null">
          {{ $t('Nessuno') }}
        </label>
        <p>{{ $t("Il server verrà fornito senza la configurazione di backup, monitoraggio e senza supporto tecnico.") }}</p>
        <wizard-question-stack
          v-model="wizard.stack"
          required
        />
      </pf-flex-item>

      <!-- ADDON -->
      <pf-flex-item grow>
        <template v-if="wizard.askEssentialOs">
          <h3>{{ $t('Sistema operativo') }}</h3>
          <p>{{ $t('Quale sistema operativo vorresti che ti venisse installato?') }}</p>
          <x-input
            v-model="wizard.essentialOs"
            type="text"
            name="essentialOs"
            placeholder="Sistema operativo"
            required
          />
        </template>
        <template v-else-if="wizard.askCockpitConfig">
          <h3>{{ $t('Sistema operativo') }}</h3>
          <p>{{ $t('Quale sistema operativo vorresti che ti venisse installato?') }}</p>
          <wizard-question-options
            v-model="wizard.cockpitOs"
            :options="wizard.cockpitOperativeSystems"
            name="cockpitOs"
            required
          />

          <h3>{{ $t('Web Server') }}</h3>
          <p>{{ $t('Quale web server vorresti che ti venisse installato?') }}</p>
          <wizard-question-options
            v-model="wizard.cockpitWs"
            :options="wizard.webServers"
            name="cockpitWs"
            required
          />
        </template>
        <template v-else-if="wizard.askAddonCpanel">
          <div>
            <h3>{{ $t('Vuoi attivare una licenza Imunify360?') }}</h3>
            <wizard-question-prodotto
              v-model="wizard.addonImunify"
              :product="wizard.getProdotto(wizard.codiceProdottoImunify)"
              show-prezzo
              show-utenti
            />
          </div>
          <div>
            <h3>{{ $t('Vuoi attivare una licenza LiteSpeed?') }}</h3>
            <wizard-question-prodotto
              v-model="wizard.addonLitespeed"
              :product="wizard.getProdotto(wizard.codiciProdottiAddon.liteSpeed)"
              show-prezzo
            />
          </div>
        </template>
        <template v-else-if="wizard.askAddonWindows">
          <div>
            <h3>{{ $t('Vuoi attivare una licenza terminal server?') }}</h3>
            <wizard-question-prodotto
              v-model="wizard.addonTerminalServer"
              v-model:qt="wizard.terminalServerQt"
              :product="wizard.getProdotto(wizard.codiciProdottiAddon.terminalServer)"
              show-prezzo
              askQt
            />
          </div>
          <div>
            <h3>{{ $t('Vuoi attivare una licenza SQL Server?') }}</h3>
            <label>
              <input v-model="wizard.codiceLicenzaSql" type="radio" name="codiceProdottoSql" :value="undefined">
              {{ $t('No') }}
            </label>
            <wizard-question-prodotti
              v-model="wizard.codiceLicenzaSql"
              :prodotti="wizard.codiciProdottiSql"
              :qt="Math.round(wizard.cpuCloudQt / 2)"
              askQt
              fixed-qt
              :price-help="$t('Prezzo calcolato in base al numero di cpu del server')"
              name="codiceProdottoSql"
            />
          </div>
        </template>
      </pf-flex-item>
    </pf-flex>
  </wizard-form>
</template>

<style scoped>
.question-wrapper {
  display: flex;
}
</style>

<script lang="ts" setup>
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';
import XInput from '@/components/x-input.vue';

import WizardQuestionProdotti from '../questions/wizard-question-prodotti.vue';
import WizardQuestionProdotto from '../questions/wizard-question-prodotto.vue';
import WizardQuestionStack from '../questions/wizard-question-stack.vue';
import WizardQuestionOptions from '../questions/wizard-question-options.vue';
import WizardForm from '../wizard-form.vue';

defineOptions({
  name: 'WizardStepStack',
});

const props = defineProps<OUIAProps>();
const ouiaProps = useOUIAProps(props);

const { wizard, hidden, isEnabled } = useWizardStep({
  isEnabled: wizard => wizard.askStack,
});

defineExpose({
  isEnabled,
});
</script>
