<template>
  <wizard-summary-option
    v-if="wizard.askAddonWindows"
    :name="$t('Terminal server')"
    editStep="WizardStepStack"
    ouia-id="wizard-summary-terminalserver"
  >
    <strong>{{ wizard.siNo(wizard.addonTerminalServer) }} <template v-if="wizard.addonTerminalServer">{{ $t('{0} utenti', [wizard.terminalServerQt]) }}</template></strong>
  </wizard-summary-option>

  <wizard-summary-option
    v-if="wizard.askAddonWindows"
    :name="$t('Licenze SQL')"
    :value="wizard.codiceLicenzaSql ?? $t('No')"
    editStep="WizardStepStack"
    ouia-id="wizard-summary-sql"
  />
</template>

<script lang="ts" setup>
import WizardSummaryOption from './wizard-summary-option.vue';
import { useWizard } from '../composition';

const wizard = useWizard();
</script>
