<template>
  <page
    v-model:filter="q"
    :name="$t('I tuoi prodotti')"
    :title="$t('I tuoi prodotti')"
    :empty="products.length == 0"
    :state="[paymentsState, productOrderState]"
  >
    <pf-toolbar ref="toolbar" view="list" views="">
      <template #filter>
        <x-input v-model="q" type="search" :placeholder="$t('Filtra')" />
      </template>

      <pf-dropdown menu-right :text="$t('Raggruppa per') + ': ' + groupByOption[groupBy]">
        <li v-for="(value, key) in groupByOption" :key="key">
          <a role="menuitem" @click.prevent="groupBy = key">
            {{ value }}
          </a>
        </li>
      </pf-dropdown>
    </pf-toolbar>

    <p>{{ $t('Eventuali disdette o modifiche sono da comunicarsi entro e non oltre la data di scadenza riportata di seguito.') }}</p>

    <table v-for="(rgroup, index) in groupedProducts" :key="index" class="table" :data-ouia-id="`product-table-${index}`">
      <thead>
        <tr>
          <th colspan="8">
            <h3>
              <template v-if="groupBy == 'renewal_date'">
                <template v-if="index == 'test'">{{ $t('Prodotti in prova') }}</template>
                <template v-else-if="index == 'canceled'">{{ $t('Prodotti scaduti da più di {0} giorni', [retentionDays]) }}</template>
                <template v-else-if="index == 'gracePeriod'">{{ $t('Prodotti scaduti da meno di {0} giorni', [retentionDays]) }}</template>
                <template v-else-if="index == 'scadenza3'">{{ $t('Prodotti che scadranno nei prossimi tre mesi') }}</template>
                <template v-else-if="index == 'scadenza12'">{{ $t('Prodotti che scadranno entro fine anno') }}</template>
                <template v-else-if="index == 'scadenzaFuture'">{{ $t('Altri con scadenza futura') }}</template>
                <template v-else>{{ $t('In scadenza entro fine mese') }}</template>
              </template>
              <template v-else>{{ index }}</template>
            </h3>
          </th>
        </tr>
        <tr>
          <th>
            <sort-link v-if="groupBy !== 'domain'" v-model="s" v-model:reverse="r" field="dominio">
              {{ $t('Dominio') }}
            </sort-link>
            <template v-else>{{ $t('Dominio') }}</template>
          </th>
          <th>
            <sort-link v-model="s" v-model:reverse="r" field="nome">
              {{ $t('Nome') }}
            </sort-link>
          </th>
          <th class="price">
            <sort-link v-model="s" v-model:reverse="r" field="prezzo">
              {{ $t('Importo') }}
            </sort-link>
          </th>
          <th>{{ $t('Durata') }}</th>
          <th class="scadenza">
            <sort-link v-model="s" v-model:reverse="r" field="data_fine">
              {{ $t('Scadenza') }}
            </sort-link>
          </th>
          <th class="rinnovo">
            {{ $t('Rinnovo') }}
          </th>
          <th style="max-width: 240px">
            {{ $t('Azioni') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in rgroup" :key="product.id">
          <td>{{ product.dominio }}</td>
          <td>
            {{ product.nome }}<span v-if="product.qt > 1"> (x{{ product.qt }})</span>{{ ' ' }}<label v-if="product.nfr" class="label-primary" :title="$t('Prodotto not for resale')">NFR</label>
            <template v-if="product.note_cliente">
              <br>
              {{ $t('Note') }}: {{ product.note_cliente }}
            </template>
            <template v-if="product.last_bw_upgrade">
              <br>
              <i>{{ $t('Upgrade di traffico automatico (in data {0})', [$date(product.last_bw_upgrade)]) }} - <template v-if="product.free_bw_upgrade">{{ $t('Primo mese gratuito') }}</template></i>
            </template>
          </td>
          <td class="price">
            {{ $currency(product.prezzo, login.currency) }}
            <template v-if="product.last_bw_upgrade">
              <br>
              <i v-if="product.free_bw_upgrade">{{ $currency(0, login.currency) }}</i>
              <i v-else>{{ $currency(product.bw_upgrade_price, login.currency) }}</i>
            </template>
          </td>
          <td>
            {{
              product.durata == 'Annuale'
                ? $t('Annuale')
                : product.durata == 'Mensile'
                  ? $t('Mensile')
                  : $t('Una Tantum')
            }}
          </td>
          <td>
            {{ $date(product.data_fine) }} <small>({{ daysFromNow(product.data_fine) }})</small>
          </td>
          <td v-if="product.free_period > 0">
            <span v-if="product.daysToExpiration > 0" :class="`text-${renewClass(!product.disdetto)}`">
              {{ product.disdetto ? $t('Prodotto in prova in cancellazione al termine del test') : $t('Prodotto in prova che verrà rinnovato al termine del test') }}
            </span>
            <span v-else :class="`text-${renewClass(!product.disdetto)}`">
              {{ product.disdetto ? $t('Periodo di prova terminato. Prodotto in cancellazione') : $t('Periodo di prova terminato. Prodotto in fatturazione') }}
            </span>
          </td>
          <td v-else-if="product.daysToExpiration < 0">
            <span :class="`text-${renewClass(!product.disdetto)}`">{{ product.disdetto ? $t('Prodotto scaduto in cancellazione') : $t('Prodotto in fase di rinnovo') }}</span>
          </td>
          <td v-else-if="product.disdetto">
            <span class="text-danger">{{ $t('Disdetto') }}</span>
          </td>
          <td v-else-if="product.hasPendingPayments">
            <span class="text-danger">{{ $t('Pagamenti arretrati') }}</span>
          </td>
          <td v-else class="cell-toggle">
            <template v-if="product.durata === 'Una tantum'">
              {{ $t('Una tantum') }}
            </template>
            <toggle
              v-else
              :disabled="!login.grants.billing"
              :model-value="product.rinnovo_automatico"
              on-class="btn-primary"
              :on-text="$t('Automatico')"
              :on-value="true"
              :off-value="false"
              :off-text="$t('Su richiesta')"
              no-inline
              size="sm"
              @update:model-value="setChangingProduct(product, 'autorenew', Boolean($event))"
            />
            <form-help-tooltip v-if="!login.grants.billing" :text="$t('Non hai i permessi per modificare la tipologia di rinnovo del prodotto. Richiedili ad un utente amministratore.')" />
          </td>
          <td class="btn-actions">
            <granted-link
              v-if="product.hasPendingPayments"
              :to="{ name: 'invoices' }"
              class="btn btn-xs btn-danger"
            >
              {{ $t('Vai alla sezione fatture') }} {{ ' ' }}
              <form-help-tooltip
                v-if="product.hasPendingPayments"
                :text="$t('La fattura nr. {0} che include il rinnovo di questo prodotto non è ancora stata saldata', [
                  payments[product.id].fatture,
                ])"
              />
            </granted-link>

            <template v-else>
              <pf-button x-small @click="productNotePopup = product">
                {{ $t('Note') }}
              </pf-button>
              <template v-if="product.durata != 'Una tantum'">
                <pf-tooltip v-if="product.gracePeriod && product.disdetto">
                  <granted-link
                    :to="{ name: 'product_renew', params: { id: product.id } }"
                    class="btn btn-xs btn-primary"
                    disabled-message="Non hai i permessi per riattivare il prodotto. Richiedili ad un utente amministratore."
                  >
                    <triangle-exclamation-icon />
                    {{ $t('Riattiva prodotto') }}
                  </granted-link>
                  <template #content>{{ $t('Clicca per procedere alla riattivazione di questo prodotto, i giorni rimasti per poterlo confermare sono {0}', [product.gracePeriod]) }}</template>
                </pf-tooltip>

                <pf-button
                  v-else-if="product.daysToExpiration >= 0"
                  class="btn-renewal"
                  :variant="product.disdetto ? 'success' : 'danger'"
                  x-small
                  :disabled="!login.grants.billing"
                  @click.prevent="setChangingProduct(product, 'state', product.disdetto)">
                  {{ product.disdetto ? $t('Rinnova') : $t('Non rinnovare') }} {{ ' ' }}
                  <form-help-tooltip v-if="!login.grants.billing" :text="$t('Non hai i permessi per disdire il prodotto. Richiedili ad un utente amministratore.')" />
                </pf-button>

                <pf-tooltip v-else>
                  <granted-link
                    :to="{ name: 'ticket', params: { id: product.id } }"
                    class="btn btn-xs btn-default"
                    :title="$t('Segnala tramite ticket')"
                  >
                    {{ $t('Contattaci') }} {{ ' ' }}<help-icon />
                  </granted-link>
                  <template #content>{{ $t('La data di rinnovo è passata, non è più possibile effettuare modifiche. Clicca qui per aprire un ticket al supporto e verificare la situazione.') }}</template>
                </pf-tooltip>

                <granted-link
                  v-if="product.n_upgrades && !product.disdetto"
                  :to="{ name: 'product_upgrade', params: { id: product.id } }"
                  class="btn btn-xs btn-primary"
                  disabled-message="Non hai i permessi per effettuare l'upgrade del prodotto"
                  :title="$t('Effettua upgrade del prodotto')"
                >
                  {{ $t('Upgrade') }}
                </granted-link>
              </template>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </page>

  <prodotti-popup-note
    v-if="productNotePopup"
    v-model:customer-note="productNotePopup.note_cliente"
    :product-id="productNotePopup.id"
    show
    @submit="clearCustomerNote"
    @close="clearCustomerNote"
  />

  <prodotti-popup-renewal
    v-if="changingProduct.product !== null && changingProduct.variant !== null && changingProduct.renew !== null"
    :product="changingProduct.product"
    :variant="changingProduct.variant"
    :renew="changingProduct.renew"
    show
    @submit="renewalChangeSubmit()"
    @close="setChangingProduct(null, null, null)"
  />
</template>

<style scoped lang="scss">
.table > tbody > tr > td {
  vertical-align: middle;
}
.cell-toggle > * {
  margin-top: 3px;
}
.btn-actions :deep(.btn) {
  display: inline-block;
  margin: 3px 0 3px 3px;

  &.btn-renewal{
    min-width: 100px;
  }
}
.price {
  text-align: right;
}
.scadenza {
  min-width: 130px;
}
.rinnovo {
  min-width: 190px;
}
label.label-primary {
  color: white;
  font-weight: 600;
  padding: 0 5px;
}
</style>

<script lang="ts" setup>
import { ref, type Ref, computed } from 'vue';
import { asyncComputedList } from '@common/asyncComputed';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import { any, by } from '@common/utils';
import XInput from '@/components/x-input.vue';
import Page from '../components/page.vue';
import SortLink from '../components/sort-link.vue';
import Toggle from '../components/toggle.vue';
import GrantedLink from '../components/granted-link.vue';
import ProdottiPopupNote from '../components/prodotti-popup-note.vue';
import ProdottiPopupRenewal from '../components/prodotti-popup-renewal-change.vue';
import TriangleExclamationIcon from '@vue-patternfly/icons/triangle-exclamation-icon';
import HelpIcon from "@vue-patternfly/icons/help-icon";

import { useLoginStore } from '@/store/login';
import { type ProductOrder, ProductOrdersResource, ProductsExpiringPaymentsResource } from '@/resources';
import { DateTime, Interval } from 'luxon';
import { parseDateTime, daysFromNow } from '@common/date';
import { $t as _t } from '@/i18n';

type ExtendedProductOrder = ProductOrder & {
  daysToExpiration: number;
  gracePeriod: number;
  hasPendingPayments: boolean;
};

type GroupByOption = 'renewal_date' | 'domain' | 'customer_note';
const groupByOption: Record<GroupByOption, string> = {
  'renewal_date': _t('Data di rinnovo'),
  'domain': _t('Nome a dominio'),
  'customer_note': _t('Note'),
};

const login = useLoginStore();

const { state: productOrderState, result: productOrder } = asyncComputedList(() => new ProductOrdersResource().get());

const products = computed (() => productOrder.value.map(assignProductInfo));
const { state: paymentsState, result: payments } = asyncComputedList(() => new ProductsExpiringPaymentsResource().get());

const now = DateTime.now().startOf('day');

const q = ref(''); // search query/become-partner
const s: Ref<keyof ExtendedProductOrder> = ref('data_fine'); // sort by
const r = ref(false); // reverse sort
const groupBy: Ref<GroupByOption> = ref('renewal_date');
const productNotePopup: Ref<ExtendedProductOrder | null> = ref(null);

const retentionDays = 10;
const endOfMonth = now.endOf('month');
const endOfYear = now.endOf('year');
const nextMonth = now.startOf('month').plus({ months: 1 });
const threeMonthsFuture = now.endOf('month').plus({ months: 3 });

const changingProduct: Ref<{
  product: ProductOrder|null;
  variant: 'state'|'autorenew'|null;
  renew: boolean|null;
}> = ref({
  product: null,
  variant: null,
  renew: null,
});

const sortedFilteredProducts = computed(() => {
  return products.value
    .filter(any(q.value))
    .sort(by(s.value, r.value));
});

const groupedProductsByRenewalDate = computed(() => {
  const grouped = {
    test: [] as ExtendedProductOrder[],
    canceled: [] as ExtendedProductOrder[],
    gracePeriod: [] as ExtendedProductOrder[],
    scadenza: [] as ExtendedProductOrder[],
    scadenza3: [] as ExtendedProductOrder[],
    scadenza12: [] as ExtendedProductOrder[],
    scadenzaFuture: [] as ExtendedProductOrder[],
  };

  for (const product of sortedFilteredProducts.value) {
    const dataFine = parseDateTime(product.data_fine);

    if (product.free_period > 0) {
      grouped.test.push(product);
      continue;
    }

    if (product.gracePeriod) {
      grouped.gracePeriod.push(product);
      continue;
    }

    if (product.daysToExpiration < 0) {
      grouped.canceled.push(product);
      continue;
    }

    // fine mese
    if (dataFine <= endOfMonth) {
      grouped.scadenza.push(product);
      continue;
    }
    // prossimi tre mesi
    if (Interval.fromDateTimes(nextMonth, threeMonthsFuture).contains(dataFine)) {
      grouped.scadenza3.push(product);
      continue;
    }

    // fine anno
    if (dataFine <= endOfYear) {
      grouped.scadenza12.push(product);
      continue;
    }

    // altri
    grouped.scadenzaFuture.push(product);
  }

  // unset empty array keys
  for (const k of Object.keys(grouped) as (keyof typeof grouped)[]) {
    if (grouped[k].length == 0) {
      Reflect.deleteProperty(grouped, k);
    }
  }

  return grouped;
});

const groupedProductsByDomain = computed(() => {
  const grouped: Record<string, ExtendedProductOrder[]> = {};

  let dominio = '';
  for (const product of sortedFilteredProducts.value) {
    dominio = product.dominio ?? '';
    if (!grouped[dominio]) {
      grouped[dominio] = [product];
    } else {
      grouped[dominio].push(product);
    }
  }

  return Object.fromEntries(Object.entries(grouped).sort());
});

const groupedProductsByCustomerNote = computed(() => {
  const grouped: Record<string, ExtendedProductOrder[]> = {};

  for (const product of sortedFilteredProducts.value) {
    if (!grouped[product.note_cliente ?? _t('Senza note')]) {
      grouped[product.note_cliente ?? _t('Senza note')] = [product];
    } else {
      grouped[product.note_cliente ?? _t('Senza note')].push(product);
    }
  }

  return Object.fromEntries(Object.entries(grouped).sort());
});

const groupedProducts = computed(() => {
  if (groupBy.value === 'domain') {
    return groupedProductsByDomain.value;
  }

  if (groupBy.value === 'customer_note') {
    return groupedProductsByCustomerNote.value;
  }

  return groupedProductsByRenewalDate.value;
});

const pendingPaymentsIDs = computed(() =>  Object.keys(payments.value).map(Number));

function assignProductInfo(p: ProductOrder): ExtendedProductOrder {
  const daysToExpiration = parseDateTime(p.data_fine).diff(now, ['days']).days;
  const gracePeriod = daysToExpiration < 0 && daysToExpiration > -retentionDays ? retentionDays + daysToExpiration : 0;
  const hasPendingPayments = pendingPaymentsIDs.value.includes(p.id);

  return {
    ...p,
    id: Number(p.id),
    daysToExpiration,
    gracePeriod: gracePeriod,
    hasPendingPayments,
  };
}

function renewClass(disdetto: boolean) {
  return disdetto ? 'success' : 'danger';
}

function clearCustomerNote() {
  productNotePopup.value = null;
  productOrderState.update();
}

function setChangingProduct(product: ProductOrder|null, variant:'state'|'autorenew'|null, renew: boolean|null) {
  changingProduct.value.product = product;
  changingProduct.value.variant = variant;
  changingProduct.value.renew = renew;
}

function renewalChangeSubmit() {
  setChangingProduct(null, null, null);
  productOrderState.update();
}
</script>
