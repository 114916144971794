<template>
  <div
    v-bind="ouiaProps"
    tabindex="0"
    class="btn-group btn-group-flip"
    :class="{ 'inline-with-text': !noInline }"
    @keyup="keyUp"
  >
    <template v-if="offValue !== false">
      <btn-radio
        :name="name"
        :model-value="radioValue"
        :class="[`btn-${size}`]"
        :active-class="onClass"
        inactive-class="btn-default"
        :checked-value="onValue"
        :required="required"
        :disabled="disabled"
        @update:model-value="change($event)"
      >
        {{ onText }}
      </btn-radio>
      <btn-radio
        :name="name"
        :model-value="radioValue"
        :class="[`btn-${size}`]"
        :active-class="offClass"
        inactive-class="btn-default"
        :checked-value="offValue"
        :required="required"
        :disabled="disabled"
        @update:model-value="change($event)"
      >
        {{ offText }}
      </btn-radio>
    </template>
    <template v-else>
      <btn-radio
        :name="name"
        input="checkbox"
        :model-value="radioValue"
        :class="[`btn-${size}`]"
        :active-class="onClass"
        inactive-class="btn-default"
        :checked-value="onValue"
        :required="required"
        :disabled="disabled"
        @update:model-value="change($event)"
      >
        {{ onText }}
      </btn-radio>
      <btn-radio
        :name="name"
        input="none"
        :model-value="radioValue"
        :class="[`btn-${size}`]"
        :active-class="offClass"
        inactive-class="btn-default"
        :checked-value="offValue"
        :required="required"
        :disabled="disabled"
        @update:model-value="change($event)"
      >
        {{ offText }}
      </btn-radio>
    </template>
    <pf-spinner v-if="loading" :size="size" inline />
    <slot />
  </div>
</template>

<style scoped>
.btn-group .btn:last-of-type {
  margin-right: 0.5em;
}
</style>

<script lang="ts" setup>
import { computed } from 'vue';
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import BtnRadio from './btn-radio.vue';

defineOptions({
  name: 'Toggle',
});

interface Props extends OUIAProps {
  modelValue: boolean | string | number | (boolean | string | number)[] | undefined,
  name?: string,
  required?: boolean,
  disabled?: boolean,
  loading?: boolean,
  onText?: string,
  onValue?: boolean | string | number,
  onClass?: string
  offText?: string,
  offValue?: boolean | string | number,
  offClass?: string,
  size?: 'sm' | 'md' | 'lg' | 'xl',
  noInline?: boolean,
};

const props = withDefaults(defineProps<Props>(), {
  onText: 'ON',
  onValue: '1',
  onClass: 'btn-success',
  offText: 'OFF',
  offValue: false,
  offClass: 'btn-danger',
  size: 'sm',
});

const ouiaProps = useOUIAProps(props);

const emit = defineEmits<{
  (name: 'update:modelValue', value: boolean | string | number | (boolean | string | number)[]): void;
}>();


const radioValue = computed(() => {
  if (Array.isArray(props.modelValue)) {
    return props.modelValue.includes(props.onValue) ? props.onValue : props.offValue;
  }
  return props.modelValue;
});

function keyUp(e: KeyboardEvent) {
  console.log(e.key);
  if (e.key === ' ' || e.key === 'Enter') {
    toggle();
  } else if (e.key === 'ArrowLeft') {
    change(props.onValue);
  } else if (e.key === 'ArrowRight') {
    change(props.offValue);
  }
}

function change(value: boolean | string | number | (boolean | string | number)[]) {
  if (Array.isArray(props.modelValue)) {
    const onIdx = props.modelValue.indexOf(props.onValue);
    if (value === props.onValue) {
      if (onIdx < 0) {
        emit('update:modelValue', [...props.modelValue, props.onValue]);
      }
    } else if (onIdx >= 0) {
      value = [...props.modelValue];
      value.splice(onIdx, 1);
      emit('update:modelValue', value);
    }
    return;
  }
  emit('update:modelValue', value);
}

function toggle() {
  if (props.modelValue === props.onValue) {
    change(props.offValue);
  } else {
    change(props.onValue);
  }
}
</script>
