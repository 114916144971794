<template>
  <div class="riepilogo-wrapper">
    <ul v-if="wizard.askLocation" >
      <wizard-summary-location />
    </ul>

    <ul v-if="wizard.askProdotti">
     <wizard-summary-option
        v-if="wizard.askOs && !wizard.askEssentialOs && !wizard.askCockpitConfig"
        :name="$t('Sistema operativo')"
        :value="wizard.os"
        editStep="WizardStepProdottiInfrastruttura"
        ouia-id="wizard-summary-infrastruttura-os"
      />

      <wizard-summary-option
        :name="wizard.path === 'cloud' ? $t('Risorse') : $t('Prodotto')"
        editStep="WizardStepProdottiInfrastruttura"
        ouia-id="wizard-summary-infrastruttura-prodotto"
      >
        <template v-if="wizard.path === 'cloud'">
          {{ wizard.cpuCloudQt }} <cpu-icon title="cpu" /> {{ wizard.ramCloudQt }} GB <memory-icon title="ram" /> {{ wizard.storageCloudQt }} GB <volume-icon title="storage" />
        </template>
        <strong v-else>
          {{ wizard.sceltaProdottoInfrastruttura }}
        </strong>
      </wizard-summary-option>

      <wizard-summary-option
          v-if="wizard.askNomeServer"
          :name="$t('Nome')"
          :value="wizard.nomeServer"
          editStep="WizardStepProdottiInfrastruttura"
          ouia-id="wizard-summary-infrastruttura-nome"
      />

      <template v-if="wizard.path === 'host'">
        <wizard-summary-host-addon />
      </template>

      <wizard-summary-option
        v-if="wizard.askStack"
        :name="$t('Stack')"
        editStep="WizardStepStack"
        ouia-id="wizard-summary-infrastruttura-stack"
      >
        <strong>{{ wizard.stack ?? $t('Nessuno') }}
        <template v-if="wizard.stack">({{ wizard.codiceProdottoStack }} <template v-if="wizard.askEssentialOs">{{ wizard.essentialOs }}</template> <template v-else-if="wizard.askCockpitConfig">{{ wizard.cockpitOs }} - {{ wizard.cockpitWs }}</template>)
        </template>
        </strong>
      </wizard-summary-option>

      <wizard-summary-option
        v-if="configurazione"
        :name="$t('Configurazione')"
        editStep="WizardStepConfigurazione"
        ouia-id="wizard-summary-infrastruttura-tecnologie"
      >
        <strong>{{ $t("Personalizzata") }}</strong>
        {{ ' ' }}
        <form-help-tooltip :text="configurazione" />
      </wizard-summary-option>

      <wizard-summary-licenze-win v-if="wizard.askAddonWindows" />
      <wizard-summary-addon-cpanel v-else-if="wizard.askAddonCpanel" />
    </ul>

    <ul v-if="wizard.os === 'linux' && (wizard.askSiteTransfer || wizard.askMailTransfer)" >
      <wizard-summary-transfer />
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { $t as _t } from '@/i18n';
import WizardSummaryLocation from './wizard-summary-location.vue';
import WizardSummaryTransfer from './wizard-summary-transfer.vue';
import WizardSummaryOption from './wizard-summary-option.vue';
import WizardSummaryHostAddon from './wizard-summary-host-addon.vue';
import WizardSummaryLicenzeWin from './wizard-summary-licenze-win.vue';
import WizardSummaryAddonCpanel from './wizard-summary-addon-cpanel.vue';
import CpuIcon from '@vue-patternfly/icons/cpu-icon';
import MemoryIcon from '@vue-patternfly/icons/memory-icon';
import VolumeIcon from '@vue-patternfly/icons/volume-icon';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import { useWizard } from '../composition';

const wizard = useWizard();

const configurazione = computed(() => {
  if (!(wizard.askTecnologie || wizard.askPhp || wizard.askDb)) {
    return null;
  }

  const conf = [];
  if (wizard.askPhp) {
    conf.push(_t("PHP: {0}", [wizard.php ?? _t('Non necessario')]));
  }
  if (wizard.askDb) {
    conf.push(_t("Database: {0}", [wizard.db ?? _t('Non necessario')]));
  }
  if (wizard.tecnologie.length) {
    conf.push(_t("Applicativi: {0}", [wizard.tecnologie.join(', ')]));
  }

  return conf.join(' - ');
});
</script>
