<template>
  <wizard-summary-option
    v-if="wizard.askLocation && wizard.sceltaLocationDeploy"
    :name="$t('Location principale')"
    :value="wizard.deployLocations[wizard.sceltaLocationDeploy]"
    editStep="WizardStepLocation"
    ouia-id="wizard-summary-location-deploy"
  />
  <wizard-summary-option
    v-if="wizard.askLocation && wizard.askLocationBackup && wizard.sceltaLocationBackup"
    :name="$t('Location backup')"
    :value="wizard.backupLocations[wizard.sceltaLocationBackup]"
    editStep="WizardStepLocation"
    ouia-id="wizard-summary-location-backup"
  />
</template>

<script lang="ts" setup>
import WizardSummaryOption from './wizard-summary-option.vue';
import { useWizard } from '../composition';

const wizard = useWizard();
</script>
