<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Email')">
    <div class="question-wrapper">
      <p v-if="wizard.path === 'cloud'">
        {{ $t("L'attivazione di un cloud dedicato non comprende il servizio email, se non viene attivata anche una licenza cPanel o Plesk.") }}
      </p>
      <p v-if="wizard.path === 'cloud'">
        {{ $t('In questo caso è possibile attivare il servizio email aggiuntivo su hosting condiviso configurato appositamente per la gestione della posta elettronica.') }}
      </p>

      <div v-if="wizard.path !== 'mail'">
        <label>
          <input v-model="wizard.codiceProdottoMail" type="radio" name="codiceProdottoMail" value>
          {{ $t('Non desidero attivare il servizio email') }}
        </label>
      </div>
      <div class="product-wrapper">
        <div>
          <wizard-question-prodotti
            v-model="wizard.codiceProdottoMail"
            :prodotti="wizard.codiciProdottiMail"
            name="codiceProdottoMail"
            :required="wizard.path === 'mail'"
            show-feature
            variant="table"
          />
        </div>
      </div>
    </div>
  </wizard-form>
</template>

<script lang="ts" setup>
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import WizardQuestionProdotti from '../questions/wizard-question-prodotti.vue';
import WizardForm from '../wizard-form.vue';

defineOptions({
  name: 'WizardStepMail',
});

const props = defineProps<OUIAProps>();
const ouiaProps = useOUIAProps(props);

const { wizard, hidden, isEnabled } = useWizardStep({
  isEnabled: wizard => wizard.askMail,
});

defineExpose({
  isEnabled,
});
</script>
