<template>
  <form role="form" @submit.prevent="changePassword">
    <fieldset :disabled="!login.id">
      <div class="row">
        <form-group :name="_t('Password')" class="col-md-6 col-lg-4">
          <x-input
            id="anagraficaResetPassword"
            v-model="password"
            type="password"
            minlength="8"
            required
            autocomplete="new-password"
          />
        </form-group>

        <form-group :name="_t('Conferma Password')" class="col-md-6 col-lg-4">
          <x-input
            id="anagraficaConfirmPassword"
            v-model="confirmPassword"
            type="password"
            minlength="8"
            required
            autocomplete="off"
          />
        </form-group>
      </div>

      <pf-button type="submit" variant="primary" :disabled="!password || password != confirmPassword || sending">
        {{ _t('Cambia Password') }}
      </pf-button>
    </fieldset>
  </form>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { $t as _t } from '@/i18n';
import { useAppStore } from '@/store/app';
import { useLoginStore } from '@/store/login';
import { Resource } from '@/resources';
import XInput from './x-input.vue';
import FormGroup from './form-group.vue';

const password = ref('');
const confirmPassword = ref('');
const sending = ref(false);
const login = useLoginStore();

async function changePassword() {
  const app = useAppStore();

  sending.value = true;
  try {
    if (!login.id) {
      return;
    }
    await new Resource('login').put(login.id, {
      password: password.value,
    });
    app.toast(_t('Password modificata correttamente.'), 'success');
  } catch {
    app.toast(_t('Errore: password non modificata. Contatta il nostro supporto per maggiori informazioni.'), 'danger');
  } finally {
    sending.value = false;
  }
  password.value = '';
  confirmPassword.value = '';
};
</script>
