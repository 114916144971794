<template>
  <ul class="radio-wrapper">
    <li v-if="nullLabel">
      <label>
        <input
          type="radio"
          :name="name"
          :required="required"
          :value="undefined"
          :checked="modelValue === '' || modelValue === undefined"
          @change="emit('update:modelValue', undefined)"
        >
        {{ nullLabel }}
      </label>
    </li>
    <li v-for="o in normalizedOptions" :key="o.key">
      <label v-if="o">
        <input
          type="radio"
          :name="name"
          :required="required"
          :value="o.key"
          :checked="o.key === modelValue"
          @change="emit('update:modelValue', o.key)"
        >
        {{ o.value }}
      </label>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  modelValue: string | undefined;
  options: readonly string[] | Record<string, string>;
  name: string;
  required?: boolean;
  nullLabel?: string;
}>();

const emit = defineEmits<{
  (name: 'update:modelValue', v: string|undefined): void;
}>();

type NormalizedOption = {
  key: string;
  value: string;
}

const normalizedOptions = computed(() => {
  const norm: NormalizedOption[] = [];
  if (Array.isArray(props.options)) {
    for (const key of props.options) {
      norm.push({key, value: key});
    }
  } else {
    for (const [key, value] of Object.entries(props.options)) {
      norm.push({key, value});
    }
  }
  return norm;
});
</script>
