<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Location')">
    <div class="question-wrapper">
      <h3>
        {{ $t('Location principale') }}
        <form-help-tooltip :text="_t('NB: Garantiamo l\'attivazione nello stato (Svizzera o Unione Europea) selezionato. Consideriamo la specifica location indicata solo come scelta preferenziale.')"/>
      </h3>
      <wizard-question-location v-model="wizard.sceltaLocationDeploy" name="deployLocation" :opzioni-location="wizard.deployLocations" required />
    </div>

    <div v-if="wizard.askLocationBackup" class="question-wrapper">
      <h3>{{ $t('Location di backup') }}</h3>
      <wizard-question-location v-model="wizard.sceltaLocationBackup" name="backupLocation" :opzioni-location="wizard.backupLocations" required />
    </div>
  </wizard-form>
</template>

<script lang="ts" setup>
import { $t as _t} from '@/i18n';
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';
import WizardQuestionLocation from '../questions/wizard-question-location.vue';
import WizardForm from '../wizard-form.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';

defineOptions({
  name: 'WizardStepLocation',
});

const props = defineProps<OUIAProps>();
const ouiaProps = useOUIAProps(props);

const { wizard, hidden, isEnabled } = useWizardStep({
  isEnabled: wizard => wizard.askLocation,
});

defineExpose({
  isEnabled,
});
</script>
