<template>
  <div v-if="wizard.askMail || wizard.askMailTransfer" class="riepilogo-wrapper">
    <ul v-if="wizard.askMail">
      <wizard-summary-location v-if="wizard.path === 'mail'" />

      <wizard-summary-option
        v-if="wizard.path !== 'mail' && wizard.codiceProdottoMail === ''"
        :name="$t('Servizio mail')"
        :value="$t('Non attivare')"
        editStep="WizardStepMail"
        ouia-id="wizard-summary-mail-attivazione"
      />

      <wizard-summary-option
        v-if="wizard.codiceProdottoMail"
        :name="$t('Prodotto')"
        :value="wizard.codiceProdottoMail"
        editStep="WizardStepMail"
        ouia-id="wizard-summary-mail-prodotto"
      />
    </ul>

    <ul v-if="wizard.askMailTransfer">
      <wizard-summary-transfer />
    </ul>
  </div>
</template>


<script lang="ts" setup>
import WizardSummaryOption from './wizard-summary-option.vue';
import WizardSummaryLocation from './wizard-summary-location.vue';
import WizardSummaryTransfer from './wizard-summary-transfer.vue';
import { useWizard } from '../composition';

const wizard = useWizard();
</script>
