<template>
  <pf-modal form :show="show" :title="$t('Disabilitare autenticazione a due fattori?')" @close="emit('update:show', false)" @submit.prevent="disable">
    <form-group :name="$t('Questo ridurrà la sicurezza del tuo account. Sei sicuro di voler disabilitare l\'autenticazione a 2 fattori?')">
    </form-group>

    <form-group :name="$t('Conferma codice di authenticazione a due fattori')">
      <x-input id="twoFAVerifyCode" v-model="verifyCode" required />
    </form-group>

    <template #footer>
      <pf-button @click="emit('update:show', false)">
        {{ $t('Annulla') }}
      </pf-button>

      <pf-button type="submit" variant="primary">
        {{ $t('Procedi') }}
      </pf-button>
    </template>
  </pf-modal>
</template>

<script lang="ts" setup>
import { http } from '@/http';
import { $t as _t } from '@/i18n';
import { ref } from 'vue';
import XInput from './x-input.vue';
import FormGroup from './form-group.vue';

defineProps<{
  show: boolean;
  enabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:show', value: boolean): void
  (e: 'update:enabled', value: boolean): void
}>();

const verifyCode = ref('');

async function disable() {
  const data = new FormData();
  data.append('totp', verifyCode.value);
  const response = await http.post('/api/auth/disabletotp', data);
  if (response.data.level === 'success') {
    emit('update:show', false);
    emit('update:enabled', false);
  }
}
</script>
