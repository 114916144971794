import { createI18n } from 'vue-i18n';
import { Settings as LuxonSettings } from 'luxon';
import axios from 'axios';

const messageModules = import.meta.glob('../locale/*/LC_MESSAGES/messages.po');

type Language = 'it' | 'en' | 'de' | 'fr';

function isLanguage(lang: string): lang is Language {
  return ['it', 'en', 'de', 'fr'].includes(lang);
}

function detectLang(): Language {
  let urlLanguage = null;
  if (window) {
    const url = new URL(window.location.href);
    urlLanguage = url.searchParams.get('lang');
  }

  if (urlLanguage && isLanguage(urlLanguage)) {
    return urlLanguage;
  }

  for (const language of navigator.languages) {
    const lang = language.substring(0, 2);
    if (isLanguage(lang)) {
      return lang;
    }
  }
  // default
  return 'en';
}

function langToLocale(lang: Language) {
  if (lang === 'en') {
    return 'en_GB';
  }
  if (lang === 'de') {
    return 'de_CH';
  }
  if (lang === 'fr') {
    return 'fr_CH';
  }
  return null;
}

const i18n = createI18n({
  legacy: false,
  locale: '',
  fallbackLocale: 'it',
  fallbackFormat: true,
  fallbackWarn: true,
  missingWarn: true,
});

const loadedLanguages = new Set<string>();

function setI18nLanguage(lang: Language) {
  if (lang === i18n.global.locale.value) {
    return;
  }

  LuxonSettings.defaultLocale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.documentElement.setAttribute('lang', lang);

  (async () => {
    const fallback = ['de', 'fr'].includes(lang) ? 'en' : 'it';

    if (!loadedLanguages.has(lang)) {
      let locale = langToLocale(lang);
      if (locale !== null) {
        const messages: any = await messageModules[`../locale/${locale}/LC_MESSAGES/messages.po`]();
        i18n.global.setLocaleMessage(lang, messages.default);
      }

      locale = langToLocale(fallback);
      if (locale !== null) {
        const messages: any = await messageModules[`../locale/${locale}/LC_MESSAGES/messages.po`]();
        i18n.global.setLocaleMessage(fallback, messages.default);
      }
      loadedLanguages.add(lang);
    }

    i18n.global.locale.value = lang;
    i18n.global.fallbackLocale.value = fallback;
    i18n.global.missingWarn = lang === 'it';
  })();
}

setI18nLanguage(detectLang());
if (typeof window !== 'undefined') {
  window.addEventListener('languagechange', () => setI18nLanguage(detectLang()));
}

export const $t = i18n.global.t;
export const $tm = i18n.global.tm;

export default i18n;
