<template>
  <wizard-summary-option
    v-if="wizard.askLocation && wizard.sceltaLocationDeploy"
    :name="$t('32 GB RAM addizionali')"
    :value="wizard.siNo(wizard.addOn32Ram)"
    editStep="WizardStepProdottiInfrastruttura"
    ouia-id="wizard-summary-host-addon-ram"
  />
  <wizard-summary-option
    v-if="wizard.askLocation && wizard.askLocationBackup && wizard.sceltaLocationBackup"
    :name="$t('8 IP aggiuntivi')"
    :value="wizard.siNo(wizard.addOn8Ip)"
    editStep="WizardStepProdottiInfrastruttura"
    ouia-id="wizard-summary-host-addon-ips"
  />
</template>

<script lang="ts" setup>
import WizardSummaryOption from './wizard-summary-option.vue';
import { useWizard } from '../composition';

const wizard = useWizard();
</script>
