<template>
  <payment-cashback
    v-if="enableCashback"
    v-model="cashback"
    :totale-offerta-ivato="totaleOffertaIvato"
    :currency="currency"
  />

  <input
    v-if="!allowedMethods.length || companyState.updating"
    class="input-hidden"
    type="radio"
    required
    name="method"
    :checked="false"
    @click.prevent
  />

  <div v-if="companyState.updating" class="blank-slate-pf">
    <div class="spinner spinner-lg blank-slate-pf-icon" />
    <h3 class="blank-slate-pf-main-action">
      {{ $t('Caricamento metodi di pagamento...') }}
    </h3>
  </div>
  <div v-else>
    <label class="control-label" style="margin-top: 1em">{{ $t('Metodo di pagamento') }}</label>

    <div v-if="allowedMethods.includes('custom')" class="radio">
      <label>
        <input
          id="payCustom"
          :checked="modelValue == 'custom'"
          type="radio"
          name="method"
          value="custom"
          required
          @change="change"
        >
        {{ paymentCustomLabel }}
      </label>
    </div>

    <template v-else>
      <div v-if="allowedMethods.includes('paypal')" class="radio">
        <label>
          <input
            id="payPayPal"
            :checked="modelValue == 'paypal'"
            type="radio"
            name="method"
            value="paypal"
            required
            @change="change"
          >
          PayPal
          <paypal-icon />
        </label>
      </div>

      <div v-if="allowedMethods.includes('axerve-paypal')" class="radio">
        <label>
          <input
            id="payPayPal"
            :checked="modelValue == 'axerve-paypal'"
            type="radio"
            name="method"
            value="axerve-paypal"
            required
            @change="change"
          >
          PayPal
          <paypal-icon />
        </label>
      </div>

      <template v-if="allowedMethods.includes('axerve-cc')">
        <div class="radio">
          <label>
            <input
              id="payCc"
              :checked="modelValue == 'axerve-cc'"
              type="radio"
              name="method"
              value="axerve-cc"
              required
              @change="change"
            >
            {{ $t('Carta di credito') }}
            <cc-mastercard-icon />{{ ' ' }}<cc-visa-icon />{{ ' ' }}
          </label>
        </div>
        <div v-if="askRecurring">
          <label>
            <input type="checkbox" name="enable_recurring_payments"> {{ $t('Autorizza i pagamenti ricorrenti automatici fino ad un massimo di 10.000 €/CHF') }}
            <form-help-tooltip :text="$t('Selezionando questa opzione e completando il pagamento, tutte le prossime scadenze di pagamento, fino ad un massimo di 10.000 €/CHF, verranno automaticamente saldate.')" />
          </label>
        </div>
        <div v-else-if="login.has_recurring_payments && invoice">
          <span>{{ $t('NB: Ti ricordiamo che sono attivi i pagamenti automatici. Questa scadenza verrà saldata automaticamente dal sistema con il metodo da te scelto.') }}</span>
        </div>
      </template>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { ref, type Ref, computed, watch } from 'vue';
import { useCompany } from '../utils';
import { useLoginStore } from '@/store/login';
import { $t } from '@/i18n';
import PaymentCashback from './payment-cashback.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import PaypalIcon from '@vue-patternfly/icons/paypal-icon';
import CcVisaIcon from '@vue-patternfly/icons/cc-visa-icon';
import CcMastercardIcon from '@vue-patternfly/icons/cc-mastercard-icon';
import { ORDINI_TESTINGMODE, PAYMENTMETHOD } from '@/common/consts';

export type Method = typeof PAYMENTMETHOD[number] | 'custom';

const props = withDefaults(defineProps<{
  modelValue: Method | null | undefined,
  invoice?: boolean,
  freePeriod?: number,
  testingMode?: typeof ORDINI_TESTINGMODE[number],
  enableCashback?: boolean,
  totaleOffertaIvato: number,
  currency: 'CHF' | 'EUR',
}>(), {
  freePeriod: 0,
  testingMode: 'rinnova',
});

const emit = defineEmits<{
  (name: 'update:modelValue', value: Method): void;
}>();

const login = useLoginStore();
const { state : companyState, result: company } = useCompany();
const cashback = ref(0);

const allowedMethods: Ref<Method[]> = computed(() => !props.invoice ? ['custom'] : company.value.metodi_pagamento);
const askRecurring = computed(() => !login.has_recurring_payments && (props.modelValue === 'axerve-cc' || props.modelValue === 'axerve-paypal'));

const paymentCustomLabel = computed(() =>  {
  if (props.freePeriod) {
    return props.testingMode === 'rinnova' ?
      $t('GRATIS. Se non disdici prima ti arriverà fattura tra {0} giorni', [props.freePeriod]) :
      $t('GRATIS per {0} giorni. Se non ci chiederei il rinnovo i servizi verranno sospesi e cancellati tra {0} giorni', [props.freePeriod]);
  }

  if (!company.value.fatturazione_mensile) {
    return company.value.pagamento;
  }

  return `${$t(company.value.pagamento)} - ${$t('incluso in fatturazione mensile')}`;
});

watch(
  () => allowedMethods.value.length,
  (v) => {
    if (v === 1) {
      emit('update:modelValue', allowedMethods.value[0]);
    }
  },
  { immediate: true }
);


function change(e: Event) {
  if (e.target instanceof HTMLInputElement) {
    emit('update:modelValue', e.target.value as Method);
  }
};
</script>
