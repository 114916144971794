<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Servizi aggiuntivi')">
    <div v-if="wizard.askAddonSla" class="question-wrapper">
      <h3>{{ $t("Vuoi attivare l'assistenza premium?") }}</h3>
      <wizard-question-prodotto
        v-model="wizard.addonSla"
        :product="wizard.getProdotto(wizard.codiciProdottiAddon.sla)"
        show-prezzo
      />
    </div>

    <div v-if="wizard.askSsl" class="question-wrapper">
      <h3>{{ $t('Vuoi attivare un certificato SSL?') }}</h3>
      <p>
        {{ $t("Tutti i nostri servizi comprendono l'attivazione di un certificato SSL \"Let's Encrypt\" gratuito per attivare la navigazione cifrata in HTTPS. Oppure puoi scegliere un certificato standard a pagamento con garanzia assicurativa inclusa. Oppure attivare un certificato EV che ti permetterà di rendere più evidente all'interno dei browser questo grado di sicurezza oltre ad avere l'assicurazione in caso di violazione dell'algoritmo. Un certificato EV è particolarmente indicato per tutti i siti di Ecommerce.") }}
      </p>
      <label>
        <input v-model="wizard.codiceSsl" type="radio" name="codiceSsl" value>
        {{ $t("Certificato Let's Encrypt incluso e gratuito") }}
      </label>
      <wizard-question-prodotti
        v-model="wizard.codiceSsl"
        :prodotti="wizard.codiciProdottiAddon.ssl"
        name="codiceSsl"
        show-feature
        required
      />
    </div>

    <div v-if="wizard.askAddonStorage && (wizard.path === 'cloud' || wizard.path === 'host')" class="question-wrapper">
      <h3>{{ $t('Vuoi attivare uno storage aggiuntivo?') }}</h3>

      <label>
        <input v-model="wizard.codiceAddonStorage" type="radio" name="codiceAddonStorage" value>
        {{ $t("No") }}
      </label>
      <wizard-question-prodotti
        v-model="wizard.codiceAddonStorage"
        v-model:qt="wizard.qtAddonStorage"
        :prodotti="wizard.codiciProdottiAddon.addOnStorage[wizard.path]"
        show-feature
        ask-qt
        name="codiceAddonStorage"
      />
    </div>

    <template v-if="wizard.path === 'altro'">
      <div class="question-wrapper">
        <h3>{{ $t('Vuoi attivare una licenza terminal server?') }}</h3>
        <wizard-question-prodotto
          v-model="wizard.addonTerminalServer"
          v-model:qt="wizard.terminalServerQt"
          :product="wizard.getProdotto(wizard.codiciProdottiAddon.terminalServer)"
          :group-required="!atLeastOneAddon"
          show-prezzo
          askQt
        />
      </div>

      <div class="question-wrapper">
        <h3>{{ $t('Vuoi attivare un il servizio Atlas di archiviazione delle mail?') }}</h3>
        <wizard-question-prodotto
          v-model="wizard.addonAtlas"
          :product="wizard.getProdotto(wizard.codiciProdottiAddon.atlas)"
          :group-required="!atLeastOneAddon"
          show-prezzo
        />
      </div>
    </template>
  </wizard-form>
</template>

<style scoped>
:deep(.features .list-view-pf-additional-info-item) {
  width: 100%;
}
</style>

<script lang="ts" setup>
import { computed } from 'vue';
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import WizardQuestionProdotti from '../questions/wizard-question-prodotti.vue';
import WizardQuestionProdotto from '../questions/wizard-question-prodotto.vue';
import WizardForm from '../wizard-form.vue';

defineOptions({
  name: 'WizardStepAddon',
});

const props = defineProps<OUIAProps>();
const ouiaProps = useOUIAProps(props);

const { wizard, hidden, isEnabled } = useWizardStep({
  isEnabled: wizard => wizard.askSsl || wizard.askAddonSla || wizard.askAddonStorage || wizard.path === 'altro',
});

defineExpose({
  isEnabled,
});

const atLeastOneAddon = computed(() => wizard.path === 'altro' && !(wizard.addonTerminalServer || wizard.codiceSsl || wizard.addonAtlas));
</script>
