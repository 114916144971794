<template>
  <div v-for="(stack, k) of wizard.stacks" :key="k" class="stack">
    <template v-if="stack.os.includes(wizard.os)">
      <label class="capitalized">
        <input
          type="radio"
          name="stack"
          :required="required"
          :value="k"
          :checked="modelValue === k"
          @change="emit('update:modelValue', k)"
          :disabled="stack.minRam > wizard.ramCloudQt"
        >
        {{ k }} <form-help-tooltip v-if="stack.minRam > wizard.ramCloudQt" class="disabled-info" :text="$t('Opzione non disponbile: RAM minima necessaria {0} GB', [stack.minRam])" />
      </label>
      <p>{{ stack.descrizione }}</p>

      <wizard-price-tag v-if="modelValue !== k" :label="$t('A partire da')" :product="wizard.minPrice(wizard.stacks[k].codici)"/>

      <div class="stack-products">
        <wizard-question-prodotti
          v-if="modelValue === k"
          v-model="wizard.codiceProdottoStack"
          :prodotti="stack.codici"
          name="codiceProdottoStack"
          required
        />
      </div>
    </template>
  </div>
</template>

<style scoped>
.stack {
  margin-bottom: 20px;
}

.stack-products {
  padding-left: 30px;
}

.capitalized {
  text-transform: capitalize;
  padding-left: 4px;
}
</style>

<script setup lang="ts">
import { type OpzioniStack } from '../wizard.vue';
import { useWizard } from '../composition';
import WizardQuestionProdotti from '../questions/wizard-question-prodotti.vue';
import WizardPriceTag from '../wizard-price-tag.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';

defineProps<{
  modelValue: OpzioniStack | null | undefined ;
  required?: boolean;
}>();

const emit = defineEmits<{
  (name: 'update:modelValue', v: OpzioniStack): void;
}>();

const wizard = useWizard();
</script>
