<template>
  <page :name="$t('Conferma offerta')" :title="title" :state="[offerState, companyState]">
    <template #breadcrumbs>
      <router-link v-slot="{ navigate }" custom :to="{ name: 'offers' }">
        <li>
          <a @click="navigate">{{ $t('Offerte') }}</a>
        </li>
      </router-link>
    </template>
    <payment-report
      :result="reportQueryData.result"
      :error="reportQueryData.error"
      route="offers"
      :success="$t('Offerta confermata')"
      :link="$t('Torna alle offerte')"
      v-if="company"
    >
      <div v-if="askBilling" class="checkout-step">
        <h2>
          {{ $t('Dati di fatturazione') }}
          <span v-if="company.hasCompleteBillingData"><ok-icon /></span>
        </h2>
        <billing-form v-if="!company.hasCompleteBillingData" embed-for="order" @submit="refresh" />
      </div>

      <x-form
        method="post"
        action="/api/pay_offer"
        :prevent-submit="!canOrder"
        :submit-text="submitText"
      >
        <div class="checkout-step">
          <h2>{{ $t('Riepilogo offerta') }}</h2>
          <input type="hidden" name="idOfferta" :value="offer.id">
          <offer-detail
            :offerta="offer"
          />
          <payment-methods
            v-if="offer.stato_ordine !== 'concluso'"
            v-model="method"
            enable-cashback
            :totale-offerta-ivato="offer.gran_totale_ivato"
            :currency="offer.currency"
            :free-period="offer.free_period"
            :testing-mode="offer.testing_mode"
          />
        </div>
      </x-form>
    </payment-report>
  </page>
</template>

<style scoped>
.checkout-step {
  margin-bottom: 30px;
}

.checkout-step h2 {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.checkout-step h2 span {
  font-size: 15px;
}
</style>

<script lang="ts" setup>
import { ref, type Ref, computed, provide } from 'vue';
import { until, invoke } from '@vueuse/core';

import Page from '../components/page.vue';
import BillingForm from '../components/billing-form.vue';
import OfferDetail from '../components/offer-detail.vue';
import PaymentReport from '../components/payment-report.vue';
import PaymentMethods, { type Method } from '../components/payment-methods.vue';
import XForm from '../components/x-form.vue';
import { asyncComputed } from '@common/asyncComputed';
import { $t } from '../i18n';
import { type Company, CompanyResource, CompanyInjectionKey, OffersResource } from '@/resources';
import { pop } from '@common/utils';
import OkIcon from '@vue-patternfly/icons/ok-icon';
import { useRoute } from 'vue-router';

const props = defineProps<{
  id: string,
}>();

const askBilling = ref(false);

const { state: offerState, result: offer } = asyncComputed(async() => await new OffersResource().get(props.id));

const { state: companyState, result: company } = asyncComputed(
  async function() {
    await until(() => offerState.updating || offerState.pending).toBe(false);
    if (offerState.error) {
      throw new Error('Error loading offer');
    }
    return await new CompanyResource().get(offer.value.id_azienda);
  },
  null,
);

const method: Ref<Method | undefined> = ref();

invoke(async() => {
  await until(() => company.value?.id).toBeTruthy();
  askBilling.value = !company.value?.hasCompleteBillingData;
});

provide(CompanyInjectionKey, company.value ?? {} as Company);

const canOrder = computed(() => company.value?.hasCompleteBillingData !== false && offer.value.stato_ordine !== 'concluso');
const submitText = computed(() => {
  if (offer.value.stato_ordine === 'concluso') {
    return $t('Ordine già confermato');
  }
  return $t('Conferma offerta');
});

const route = useRoute();

const reportQueryData = computed(() => {
  const result = pop(route.query.res);
  return {
    result: result ? Number(result) : undefined,
    error: pop(route.query.err) ?? undefined,
  };
});

async function refresh() {
  await Promise.all([
    offerState.update(),
    companyState.update(),
  ]);
};

const title = computed(() => {
  if (!offer.value) {
    return
  }
  return $t('Conferma offerta: {0}', [offer.value.titolo_offerta]);
});
</script>
