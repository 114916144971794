<template>
  <login-layout>
    <header class="login-pf-header">
      <h1>{{ $t('Accedi alla Webmail') }}</h1>
    </header>

    <form :action="webmail" method="post" :aria-disabled="!webmail">
      <form-group :name="$t('Casella Email')" no-label>
        <x-input
          id="webmailEmail"
          v-model="email"
          name="user"
          type="email"
          placeholder="Email"
          required
          autocomplete="username"
        />
      </form-group>

      <form-group :name="$t('Password')" no-label>
        <x-input
          id="webmailPassword"
          name="pass"
          type="password"
          :placeholder="$t('Password')"
          autocomplete="current-password"
        />
      </form-group>

      <pf-button :disabled="!webmail" type="submit" variant="primary" large block>{{ $t('Log In') }}</pf-button>
    </form>

    <div class="separator">
      <h1>{{ $t('Posta certificata (PEC)') }}</h1>
      <a class="btn btn-default btn-block btn-lg" href="https://mail.arterapec.it/" target="_blank">
        {{ $t('Accedi') }}
        <arrow-up-right-from-square-icon />
      </a>
    </div>

    <p class="login-pf-signup">
      {{ $t('Vuoi accedere all\'area riservata?') }}
      <router-link :to="{ name: 'login' }">{{ $t('Log In') }}</router-link>
    </p>
  </login-layout>
</template>

<style scoped>
.separator::before {
  display: block;
  content: "";
  width: 50%;
  margin: 20px auto;
  border-top: 1px solid #bbb;
}
</style>

<script lang="ts">
import LoginLayout from '../components/login-layout.vue';
import XInput from '@/components/x-input.vue';
import { defineComponent } from 'vue';
import ArrowUpRightFromSquareIcon from '@vue-patternfly/icons/arrow-up-right-from-square-icon';
import FormGroup from '@/components/form-group.vue';

export default defineComponent({
  name: 'WebmailPage',

  components: {
    LoginLayout,
    ArrowUpRightFromSquareIcon,
    XInput,
    FormGroup,
  },

  data() {
    return {
      email: '',
    };
  },

  computed: {
    webmail() {
      const m = (/.@([^\s]+\.[^\s]+)$/).exec(this.email);
      if (!m || !m[1]) {
        return;
      }
      return `https://mail.${m[1]}:2096/login/`;
    },
  },
});
</script>
