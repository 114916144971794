import { defineStore } from 'pinia';
import type { LocationQuery, Router } from 'vue-router';
import { http } from '@/http';
import { useLoginStore } from './login';
import { unref, type Ref, type Component } from 'vue';

export const useAppStore = defineStore('app', {
  state: () => ({
    loading: 0,
    maintenance: false,
    notifications: null as Ref<Component | null> | null,
    router: null as Ref<Router> | null,
    isInIframe: window.location !== window.parent.location,
  }),

  actions: {
    async logout(withRef = false) {
      await http.post('/api/auth/logout');

      const login = useLoginStore();
      login.$reset();

      const route = unref(this.router?.currentRoute);

      if (route?.meta.requiresAuth) {
        const query: LocationQuery = {};
        if (withRef && route.fullPath !== '/') {
          query.res = route.fullPath;
        }
        this.router?.push({ name: 'login', query });
      }
    },

    route() {
      return unref(this.router?.currentRoute);
    },

    toast(message: string, type: string) {
      return (this.notifications as any)?.add(message, type);
    },
  },
});
