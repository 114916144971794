<template>
  <wizard-summary-option
    v-if="wizard.askAddonCpanel"
    name="Imunify360"
    :value="wizard.siNo(wizard.addonImunify)"
    editStep="WizardStepStack"
    ouia-id="wizard-summary-imunify"
  />

  <wizard-summary-option
    v-if="wizard.askAddonCpanel"
    name="Litespeed"
    :value="wizard.siNo(wizard.addonLitespeed)"
    editStep="WizardStepStack"
    ouia-id="wizard-summary-litespeed"
  />
</template>

<script lang="ts" setup>
import WizardSummaryOption from './wizard-summary-option.vue';
import { useWizard } from '../composition';

const wizard = useWizard();
</script>
