<template>
  <page :name="$t('Dashboard')" :state="[productsState, paymentsState]" hide-breadcrumbs ignore-failed>
    <div class="dashboard-pf-page">
      <div class="row cards-pf">
        <h2 class="subtitle">
          {{ $t('Di cosa hai bisogno?') }}
        </h2>

        <div class="row">
          <div v-if="login.is_reseller && !login.complete_skill" class="shortcut col-md-6 col-lg-3">
            <pf-card :title="$t('Aiutaci a passarti più clienti')">
              <router-link :to="{ name: 'my_skills' }">
                <puzzle-piece-icon />
                {{ $t('Compila le tue skill') }}
              </router-link>
            </pf-card>
          </div>
          <div class="shortcut col-md-6 col-lg-3">
            <pf-card :title="$t('Acquistare un servizio / creare un\'offerta')">
              <router-link :to="{ name: 'diy-offer' }">
                <wand-magic-icon />
                {{ $t('Vai al wizard') }}
              </router-link>
            </pf-card>
          </div>
          <div class="shortcut col-md-6 col-lg-3">
            <pf-card :title="$t('Assistenza / Consulenza tecnica')">
              <router-link :to="{ name: 'tickets' }">
                <ticket-icon />
                {{ $t('Apri un ticket') }}
              </router-link>
            </pf-card>
          </div>
          <div class="shortcut col-md-6 col-lg-3">
            <pf-card :title="$t('Fissare un appuntamento')">
              <a href="https://artera.appointlet.com/b/sergio-ravera" target="_blank">
                <calendar-day-icon />
                {{ $t('Decidi tu quando') }}
              </a>
            </pf-card>
          </div>
          <div v-if="askPartner" class="shortcut col-md-12 col-lg-3">
            <pf-card :title="$t('Diventare partner Artera')">
              <router-link :to="{ name: 'become_partner' }">
                <handshake-icon />
                {{ $t('Compila i tuoi dati e diventa partner') }}
              </router-link>
            </pf-card>
          </div>
        </div>
      </div>

      <div v-if="hostingTransfer.length > 0" class="row">
        <div class="col-lg-12">
          <h2 class="subtitle">
            <triangle-exclamation-icon /> {{ $t('Trasferimenti richiesti in attesa di dati') }}
          </h2>

          <div class="row">
            <div class="col-lg-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="panel-subtitle">
                    {{ $t('Per poter procedere con il trasferimento di questi servizi ci occorrono alcuni dati') }}
                  </div>
                </div>

                <div class="list-group list-view-pf bordered">
                  <pf-list-group-item v-for="product in hostingTransfer" :key="product.id">
                    <pf-list-item>
                      <template #heading>
                        <span :title="product.dominio">{{ product.dominio }}</span>
                      </template>
                      <template #additional-info>
                        <pf-list-item-additional-info expandable>
                          {{ $t('Inserisci le informazioni e i dati di accesso necessari a procedere') }}
                          <template #expansion>
                            <form-hosting-transfer
                              :hosting="product"
                              :ask-site-transfer="product.missing_data && product.transfer_site_required"
                              :ask-mail-transfer="product.missing_mail_data && product.transfer_mail_required"
                              @submit="productsState.update"
                            />
                          </template>
                        </pf-list-item-additional-info>
                      </template>
                    </pf-list-item>
                  </pf-list-group-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="registrantData.length > 0" class="row">
        <div class="col-lg-12">
          <h2 class="subtitle">
            <triangle-exclamation-icon /> {{ $t('Prodotti in fase di attivazione con dati mancanti') }}
          </h2>

          <div class="row">
            <div class="col-lg-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="panel-subtitle">
                    {{ $t('Per completare la registrazione di questi nomi a dominio ci occorrono alcuni dati') }}
                  </div>
                </div>

                <div class="list-group list-view-pf bordered">
                  <pf-list-group-item v-for="product in registrantData" :key="product.id">
                    <pf-list-item>
                      <template #heading>
                        <span :title="product.dominio">{{ $t('Registrazione dominio {0}', [product.dominio]) }}</span>
                      </template>
                      <template #additional-info>
                        <pf-list-item-additional-info expandable>
                          {{ $t('Inserisci i dati del registrante') }}
                          <template #expansion>
                            <form-domain-registrant :domain="product" @submit="productsState.update" />
                          </template>
                        </pf-list-item-additional-info>
                      </template>
                    </pf-list-item>
                  </pf-list-group-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="testing.length > 0" class="row">
        <div class="col-lg-12">
          <h2 class="subtitle">
            {{ $t('Prodotti in prova') }}
          </h2>
          <div class="row">
            <div class="col-lg-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="panel-subtitle">
                    {{ $t('Questi prodotti sono in prova gratuita') }}
                  </div>
                </div>
                <div class="list-group list-view-pf bordered max-height">
                  <pf-list-group-item v-for="product in testing" :key="product.id">
                    <pf-list-item class="row">
                      <dl class="col-md-2">
                        <dt>{{ $t('Ordine') }}</dt>
                        <dd>#{{ product.id_ordine }}</dd>
                      </dl>

                      <dl class="col-md-4">
                        <dt>{{ $t('Prodotto') }}</dt>
                        <dd>{{ product.nome }}</dd>
                      </dl>

                      <dl v-if="product.dominio" class="col-md-2">
                        <dt>{{ $t('Dominio') }}</dt>
                        <dd>{{ product.dominio }}</dd>
                      </dl>


                      <dl class="col-md-3">
                        <dt v-if="parseDateTime(product.data_fine).diff(now, ['days']).days < 0">{{ $t('Periodo di prova scaduto') }}</dt>
                        <dt v-else> {{ $t('Periodo di prova in corso') }}</dt>
                        <dd v-if="product.disdetto" class="text-danger">{{ $t('Questo prodotto in prova verrà sospeso e poi cancellato al termine del periodo di test.') }}</dd>
                        <dd v-else class="text-success">{{ $t('Questo prodotto in prova verrà rinnovato e fatturato al termine del periodo di test.') }}</dd>
                        {{ $t('Giorni rimanenti') }}: {{ Math.round(parseDateTime(product.data_fine).diff(now, ['days']).days) }}
                      </dl>

                      <dl class="col-md-1 action">
                        <dd v-if="product.disdetto">
                          <pf-button
                            variant="success"
                            :disabled="!login.grants.billing"
                            @click.prevent="setChangingProduct(product, 'state', true)">
                            {{ $t('Conferma') }} {{ ' ' }}
                            <form-help-tooltip v-if="!login.grants.billing" :text="$t('Non hai i permessi per confermare il prodotto. Richiedili ad un utente amministratore.')" />
                          </pf-button>
                        </dd>
                        <dd v-else class="col-md-1">
                          <pf-button
                            variant="danger"
                            :disabled="!login.grants.billing"
                            @click.prevent="setChangingProduct(product, 'state', false)">
                            {{ $t('Non rinnovare') }} {{ ' ' }}
                            <form-help-tooltip v-if="!login.grants.billing" :text="$t('Non hai i permessi per disdire il prodotto. Richiedili ad un utente amministratore.')" />
                          </pf-button>
                        </dd>
                      </dl>
                    </pf-list-item>
                  </pf-list-group-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->

      <div v-if="pending.length > 0" class="row">
        <div class="col-lg-12">
          <h2 class="subtitle">
            {{ $t('Prodotti in fase di attivazione') }}
          </h2>

          <div class="row">
            <div class="col-lg-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="panel-subtitle">
                    {{ $t('I nostri tecnici provvederanno ad attivare i seguenti prodotti a breve.') }}
                  </div>
                </div>

                <div class="list-group list-view-pf bordered max-height">
                  <pf-list-group-item v-for="product in pending" :key="product.id">
                    <pf-list-item class="row">
                      <dl class="col-md-2">
                        <dt>{{ $t('Ordine') }}</dt>
                        <dd>#{{ product.id_ordine }}</dd>
                      </dl>

                      <dl class="col-md-5">
                        <dt>{{ $t('Prodotto') }}</dt>
                        <dd>{{ product.nome }}</dd>
                      </dl>

                      <dl v-if="product.dominio" class="col-md-5">
                        <dt>{{ $t('Dominio') }}</dt>
                        <dd>{{ product.dominio }}</dd>
                      </dl>
                    </pf-list-item>
                  </pf-list-group-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->

      <div class="row">
        <div class="col-lg-12">
          <h2 class="subtitle">
            {{ $t('Nei prossimi giorni') }}
          </h2>
        </div>

        <div class="col-lg-6">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title">
                {{ $t('Prodotti scaduti o in scadenza nei prossimi 30 giorni') }}
              </h3>
            </div>

            <ul v-if="expiring.length" class="list-group max-height">
              <li v-for="product in expiring" :key="product.id" class="list-group-item">
                <span class="pull-right">{{ $datefromnow(product.data_fine) }}</span>
                {{ product.nome }} {{ product.dominio }}
              </li>
            </ul>
            <div v-else class="empty-panel">
              {{ $t('Nessun prodotto in scadenza') }}
            </div>

            <div class="panel-footer text-right">
              <router-link to="/products">
                {{ $t('Visualizza tutti') }} <arrow-right-icon />
              </router-link>
            </div>
          </div>
        </div>

        <div v-if="login.grants.billing" class="col-lg-6">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title">
                {{ $t('Prossimi pagamenti') }}
              </h3>
            </div>

            <div v-if="payments.length" class="list-group clickable-list max-height">
              <router-link
                v-for="payment in payments"
                :key="payment.id"
                :to="{ name: 'payment', params: { id: payment.id } }"
                class="list-group-item"
              >
                <circle-arrow-right-icon class="pull-right" />

                <span class="pull-right">
                  <span v-if="parseDateTime(payment.data).diff(now, ['days']).days < -30" class="text-danger">Insoluto - possibile sospensione servizi </span>
                  <span v-else-if="parseDateTime(payment.data).diff(now, ['days']).days < 0" class="text-danger">Insoluto </span>
                  {{ $datefromnow(payment.data) }}
                </span>
                <span v-if="payment.fattura">{{ $t('Fattura nr. {0}:', [payment.fattura]) }} </span>
                {{ payment.descrizione }} = {{ $currency(payment.amount, login.currency) }}
              </router-link>
            </div>

            <div v-else class="empty-panel">
              {{ $t('Nessun pagamento in scadenza') }}
            </div>

            <div class="panel-footer text-right">
              <router-link to="/invoices"> {{ $t('Visualizza tutti') }} <arrow-right-icon /> </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->
    </div>
  </page>

  <prodotti-popup-renewal
    v-if="changingProduct.product !== null && changingProduct.variant !== null && changingProduct.renew !== null"
    :product="changingProduct.product"
    :variant="changingProduct.variant"
    :renew="changingProduct.renew"
    show
    @submit="renewalChangeSubmit()"
    @close="setChangingProduct(null, null, null)"
  />
</template>

<style lang="scss" scoped>
@import '~patternfly/patternfly/color-variables';

.panel-body,
.list-group.max-height {
  max-height: 329px;
  overflow-y: auto;
}

.panel > .list-view-pf.bordered {
  background-color: $color-pf-black-200;
}

.panel .list-view-pf .action dd {
  padding: 0;
}

.cards-pf {
  padding-left: 20px;
  padding-right: 20px;

  > .row {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.empty-panel {
  padding: 15px;
}
</style>

<script lang="ts" setup>
import { ref, type Ref, computed } from 'vue';

import { asyncComputedList } from '@common/asyncComputed';
import { by } from '@common/utils';

import Page from '@/components/page.vue';
import FormDomainRegistrant from '../components/form-domain-registrant.vue';
import FormHostingTransfer from '../components/form-hosting-transfer.vue';
import ProdottiPopupRenewal from '../components/prodotti-popup-renewal-change.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import TriangleExclamationIcon from '@vue-patternfly/icons/triangle-exclamation-icon';
import ArrowRightIcon from '@vue-patternfly/icons/arrow-right-icon';
import PuzzlePieceIcon from '@vue-patternfly/icons/puzzle-piece-icon';
import TicketIcon from '@vue-patternfly/icons/ticket-icon';
import WandMagicIcon from '@vue-patternfly/icons/wand-magic-icon';
import CalendarDayIcon from '@vue-patternfly/icons/calendar-day-icon';
import HandshakeIcon from '@vue-patternfly/icons/handshake-icon';
import CircleArrowRightIcon from '@vue-patternfly/icons/circle-arrow-right-icon';

import { useLoginStore } from '@/store/login';
import { ProductOrdersResource, type ProductOrder, PaymentsResource, type Payment } from '@/resources';
import { DateTime } from 'luxon';
import { parseDateTime } from '@common/date';

type MissingDataProductOrder = Omit<ProductOrder, 'dominio'> & {dominio: string};

const login = useLoginStore();

const { state: productsState, result: products } = asyncComputedList(() => new ProductOrdersResource({ sort: 'data_fine' }).get());
const { state: paymentsState, result: payments } = asyncComputedList(() => {
  if (!login.grants.billing) {
    return [] as Payment[];
  }
  return new PaymentsResource({ exiring: 30 }).get();
});

const now = DateTime.now();

const changingProduct: Ref<{
  product: ProductOrder|null;
  variant: 'state'|'autorenew'|null;
  renew: boolean|null;
}> = ref({
  product: null,
  variant: 'state',
  renew: null,
});

const testing = computed(() => products.value.filter(p => p.free_period > 0).sort(by('data_fine')));

const registrantData = computed(() => {
  const domains: MissingDataProductOrder[] = products.value.filter(p => p.missing_data && p.registrazione_dominio && p.dominio !== null && !p.disdetto) as MissingDataProductOrder[];

  return domains.sort(by('data_fine'));
});

const hostingTransfer = computed(() => {
  const hosting: MissingDataProductOrder[] = products.value.filter( p => (p.missing_data && p.transfer_site_required || p.missing_mail_data && p.transfer_mail_required) && p.dominio !== null && !p.disdetto) as MissingDataProductOrder[];

  return hosting.sort(by('data_fine'));
});

const pending = computed(() => {
  return products.value
    .filter(p => p.stato_tecnico === 'attivare')
    .sort(by('data_fine'));
});

const expiring = computed(() => {
  return products.value
    .filter(p => parseDateTime(p.data_fine).diff(now, ['days']).days < 31)
    .sort(by('data_fine'));
});

const askPartner = computed(() => {
  return !login.is_reseller && !login.has_parent;
});

function setChangingProduct(product: ProductOrder|null, variant: 'state'|'autorenew'|null, renew: boolean|null) {
  changingProduct.value.product = product;
  changingProduct.value.variant = variant;
  changingProduct.value.renew = renew;
}

function renewalChangeSubmit() {
  setChangingProduct(null, null, null);
  productsState.update();
}
</script>
