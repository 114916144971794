<template>
  <pf-modal
    :show="show"
    :title="title"
    confirm-button=""
    cancel-button=""
    @cancel="onCancel"
  >
    <p v-if="variant === 'autorenew'">{{ renew ? $t('Confermi di voler attivare il rinnovo automatico di questo prodotto?') : $t('Confermi di voler disattivare il rinnovo automatico di questo prodotto?') }}</p>
    <p v-else>{{ renew ? $t('Confermi di voler rinnovare il prodotto alla sua data di scadenza?') : $t('Confermi di voler disdire il prodotto alla sua data di scadenza?') }}</p>
    <x-form
      encoding="json"
      method="put"
      :submit-text="$t('Confermo')"
      @submit="onSubmit"
    >
      <form-group :name="$t('Inserisci il tuo nome e cognome')">
        <x-input
          v-model="name"
          name="name"
          type="text"
          required
        />
      </form-group>

      <form-group v-if="askReason" :name="$t('Inserisci la motivazione')">
        <select
          v-model="reason"
          name="reason"
          class="form-control"
          required
        >
          <option :value="undefined">{{ $t('Seleziona') }}</option>
          <option v-for="(label, key) in cancellationReasons" :key="key" :value="key">
            {{ label }}
          </option>
        </select>
      </form-group>
    </x-form>
  </pf-modal>
</template>

<script lang="ts" setup>
import { ref, computed, type Ref } from 'vue';
import { useAppStore } from '@/store/app';
import { $t as _t } from '@/i18n';
import XForm from '../components/x-form.vue';
import XInput from './x-input.vue';
import FormGroup from './form-group.vue';
import { useLoginStore } from '@/store/login';
import { type ProductOrder, ProductOrdersResource, type PublicCancellationReason, type RenewalInfo } from '@/resources';

defineOptions({
  name: 'CustomerNotePopup',
});

interface ModalProps {
  onClose?: (() => any) | undefined;
  onConfirm?: (() => any) | undefined;
};

interface Props extends /* @vue-ignore */ ModalProps {
  product: ProductOrder;
  variant: 'state'|'autorenew';
  renew: boolean;
  show?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (name: 'submit'): void;
  (name: 'cancel'): void;
}>();

const login = useLoginStore();

const cancellationReasons: Record<PublicCancellationReason, string> = {
  'Cambio agenzia': login.is_reseller ? _t("Fine collaborazione: annullamento per fine del rapporto con il cliente.") : _t("Fine collaborazione: annullamento per fine del rapporto con l'agenzia."),
  'Chiusura attività': _t("Chiusura attività: l'azienda ha cessato le sue attività."),
  'Gestione interna': _t("Gestione interna: il servizio è stato internalizzato."),
  'Inutilizzo': _t("Inutilizzo: il servizio non è più utilizzato."),
  'Costi': _t("Costo elevato: Il prezzo è considerato troppo alto."),
  'Problemi tecnici': _t("Problemi tecnici: il servizio presenta difficoltà che ne compromettono l’uso."),
  'Assistenza': _t("Assistenza carente: supporto tecnico insufficiente o inadeguato."),
};

const name = ref('');
const reason: Ref<PublicCancellationReason|undefined> = ref(undefined);

async function onSubmit() {
  if (!name.value || (askReason.value && !reason.value)) {
    return
  }

  const renewalInfo: RenewalInfo = props.variant === 'autorenew' ?
    {
      rinnovo_automatico: props.renew,
      nominativo: name.value,
      motivazione_disdetta: props.renew ? undefined : reason.value,
    } :
    {
      stato_amministrativo: props.renew ? 'attivo' : 'non rinnovare',
      nominativo: name.value,
      motivazione_disdetta: props.renew ? undefined : reason.value,
    }
  const app = useAppStore();

  try {
    await new ProductOrdersResource().put(props.product.id, renewalInfo);
    app.toast(_t('Operazione completata'), 'success');
  } catch (e) {
    app.toast(_t("Non è stato possibile completare l'operazione. Riprova più tardi o apri un ticket all'assistenza clienti"), 'warning');
  }

  emit('submit');
}

function onCancel() {
  emit('cancel');
}

const title = computed(() => {
  if (props.variant === 'autorenew') {
    return props.renew ? _t("Attivazione del rinnovo automatico") : _t("Disattivazione del rinnovo automatico");
  }

  return props.renew ? _t('Conferma del rinnovo del prodotto') : _t('Disdetta del prodotto');
})

const askReason = computed(() => props.variant === 'state' && !props.renew);
</script>
