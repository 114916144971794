<template>
  <template v-if="variant === 'list'">
    <div v-for="prodotto of wizard.getProdotti(prodotti)" :key="prodotto.codice">
      <label>
        <input
          type="radio"
          :name="name"
          :required="required"
          :value="prodotto.codice_wizard"
          :checked="modelValue === prodotto.codice_wizard"
          @change="change($event)"
        >
        {{ prodotto.nome }}
      </label>
      <div class="radio-description">
        <div v-if="showFeature" class="features">
          <product-details :product="prodotto" />
        </div>

        <div v-if="askQt && modelValue === prodotto.codice_wizard">
          <label>{{ $t('Quantità:') }} <x-input type="number" name="qt" required min="1" :model-value="qt" @update:model-value="emit('update:qt', Number($event))" :disabled="fixedQt" /></label>
        </div>

        <div>
          <wizard-price-tag :product="prodotto" :qt="qt"/> <form-help-tooltip v-if="priceHelp" :text="priceHelp" />
        </div>
      </div>
    </div>
  </template>

  <table v-else>
    <thead>
      <tr>
        <th colspan="2" />
        <th v-for="prodotto of productWithFeatures" :key="prodotto.codice">
          <label>
            <div>{{ prodotto.nome }}</div>
            <div>
              <input
                type="radio"
                :name="name"
                :required="required"
                :value="prodotto.codice_wizard"
                :checked="modelValue === prodotto.codice_wizard"
                @change="change($event)"
              >
            </div>
          </label>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="2" />
        <td v-for="prodotto of productWithFeatures" :key="prodotto.codice">
          <wizard-price-tag :product="prodotto" variant="table" /> <form-help-tooltip v-if="priceHelp" :text="priceHelp" />
        </td>
      </tr>
      <template v-for="(feature, key) of allFeatures" :key="key">
        <tr v-if="showRow(key)">
          <td style="min-width:30px; border-right: 0;"><component :is="feature.icon" /></td>
          <td style="text-align:left;">
            {{ feature.label }}
            <form-help-tooltip v-if="feature.tooltip" :text="feature.tooltip" />
          </td>
          <template v-for="prodotto of productWithFeatures" :key="prodotto.codice">
            <td><product-feature :feature="key" :product="prodotto" /></td>
          </template>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
th, td {
  text-align: center;
  padding: 10px;
  border-right: 1px solid #d3d3d3;
}

td {
  border-bottom: 1px solid #d3d3d3;
  text-transform: capitalize;
  min-width: 150px;
}

th > label {
  display: block;
  cursor: pointer;
}

.features {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1000px;
  list-style: none;
  font-size: 12px;
  padding-bottom: 10px;

  :deep(.list-view-pf-additional-info-item) {
    width: 24%;

    strong {
      font-size: inherit;
    }
  }
}
</style>

<script setup lang="ts">
import { computed } from 'vue';
import { $t as _t} from '@/i18n';
import { useWizardQuestion } from '../composition';
import { type ProductFeatures } from '@/resources';
import WizardPriceTag from '../wizard-price-tag.vue';
import ProductDetails from '../../../components/product-details.vue';
import ProductFeature, { allFeatures } from '../../product-feature.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import XInput from '@/components/x-input.vue';

const props = withDefaults(defineProps<{
  modelValue: string|undefined;
  name: string;
  prodotti: readonly string[];
  qt?: number;
  required?: boolean;
  showFeature?: boolean;
  askQt?: boolean;
  fixedQt?: boolean;
  priceHelp?: string;
  variant?: 'table' | 'list';
}>(), {
  qt: 1,
  variant: 'list',
});

const emit = defineEmits<{
  (name: 'update:modelValue', v: string): void;
  (name: 'update:qt', v: number): void;
}>();

const { wizard } = useWizardQuestion();
const productWithFeatures = computed(() => wizard.getProdotti(props.prodotti));

function showRow(feature: keyof ProductFeatures ) {
  return productWithFeatures.value.filter(p => p[feature] || p[feature] === 0).length;
}

function change(e: Event) {
  if (e.target instanceof HTMLInputElement) {
    emit('update:modelValue', e.target.value);
  }
}
</script>
