<template>
  <div class="wizard-actions">
    <pf-button
      v-if="wizard.canConfirmOffer"
      component="label"
      variant="success"
      large
      :class="{ disabled: wizard.sending }"
      @click="wizard.submit('confirm')">

      <circle-check-icon />
      {{ $t("Conferma l'offerta") }}
      <input type="radio" v-model="wizard.action" class="input-hidden" name="action" value="confirm" required>
    </pf-button>

    <pf-button
      v-if="!wizard.fatturaMe"
      component="label"
      variant="primary"
      large
      :class="{ disabled: wizard.sending }"
      @click="wizard.submit('mailcustomer')">

      <envelope-icon />
      {{ $t("Salva l'offerta e inviala via mail al cliente per conferma") }}
      <input type="radio" v-model="wizard.action" class="input-hidden" name="action" value="mailcustomer" required>
    </pf-button>

    <pf-button
      component="label"
      variant="primary"
      large
      :class="{ disabled: wizard.sending }"
      @click="wizard.submit('mailme')">

      <envelope-icon />
      {{ $t("Salva l'offerta e inviala via mail solamente a me") }}
      <input type="radio" v-model="wizard.action" class="input-hidden" name="action" value="mailme" required>
    </pf-button>

    <pf-button
      component="label"
      variant="primary"
      large
      :class="{disabled: wizard.sending }"
      @click="wizard.submit('pdf')">

      <file-pdf-icon />
      {{ $t("Salva l'offerta e scarica PDF senza inviarla via mail") }}
      <input type="radio" v-model="wizard.action" class="input-hidden" name="action" value="pdf" required>
    </pf-button>
  </div>
</template>

<style lang="scss" scoped>
.wizard-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  :deep(.btn) {
    margin-top: 10px;
  }
}
</style>

<script lang="ts" setup>
import { useWizard } from '../composition';
import CircleCheckIcon from '@vue-patternfly/icons/circle-check-icon';
import EnvelopeIcon from '@vue-patternfly/icons/envelope-icon';
import FilePdfIcon from '@vue-patternfly/icons/file-pdf-icon';

const wizard = useWizard();
</script>
