<template>
  <wizard-summary-option
    v-if="wizard.askSiteTransfer || wizard.askMailTransfer"
    :name="$t('Nuova attivazione')"
    editStep="WizardStepConfigurazione"
    ouia-id="wizard-summary-newservice-transfer"
  >
    <strong>{{ wizard.siNo(wizard.newService ?? false) }}</strong>
  </wizard-summary-option>

  <wizard-summary-option
    v-if="!wizard.newService && wizard.askSiteTransfer && wizard.siteTransfer !== undefined "
    :name="$t('Richiesto trasferimento sito')"
    editStep="WizardStepConfigurazione"
    ouia-id="wizard-summary-site-transfer"
    >
    <strong>{{ wizard.siNo(wizard.siteTransfer) }}</strong>
  </wizard-summary-option>

  <wizard-summary-option
    v-if="!wizard.newService && wizard.askMailTransfer && wizard.mailTransfer !== undefined "
    :name="$t('Richiesto trasferimento posta')"
    editStep="WizardStepConfigurazione"
    ouia-id="wizard-summary-mail-transfer"
    >
    <strong>{{ wizard.siNo(wizard.mailTransfer) }}</strong>
  </wizard-summary-option>
</template>

<script lang="ts" setup>
import WizardSummaryOption from './wizard-summary-option.vue';
import { useWizard } from '../composition';

const wizard = useWizard();
</script>
