<template>
  <page :name="$t('Partner')" :description="$t('Compila il form e diventa partner Artera')">
    <div class="partner-wrapper">
      <div class="partner-description">
        <p>{{ $t('Compila questo form indicandoci le tue preferenze per diventare Partner Artera') }}</p>
      </div>

      <div class="partner-form">
        <form role="form" @submit.prevent="send">
          <form-group
            :name="$t('Modalità')"
            required
            :help-tooltip="$t('In modalità \'Freelance\' saremo noi a fatturare ai tuoi clienti e tu riceverai una provvigione per ogni acquisto fatto dai tuoi clienti. In modalità \'whitelabel\' i servizi saranno intestati a te e fatturati a prezzo scontato.')"
          >
            <select v-model="partner.modalita" name="modalita" class="form-control" required>
              <option value="whitelabel">
                {{ $t('Whitelabel') }}
              </option>
              <option value="freelance">
                {{ $t('Freelance') }}
              </option>
              <option value="whitelabel-freelance">
                {{ $t('Whitelabel e Freelance') }}
              </option>
            </select>
          </form-group>

          <form-group
            :name="$t('Fatturazione mensile')"
            :help-tooltip="$t('Attivando questa opzione riceverai al massimo una fattura al mese, alla fine del mese, che comprenderà tutti i servizi rinnovati in quel mese.')"
            >
            <div>
              <toggle v-model="partner.fatturazione_mensile" name="fatturazione_mensile" />
            </div>
          </form-group>

          <form-group
            :name="$t('Sconto provvigioni in fattura')"
            :help-tooltip="$t('Attivando questa opzione le provvigioni maturate e caricate come \'Credito Artera\' verranno scalate automaticamente in fattura')"

          >
            <div>
              <toggle v-model="partner.provvigioni_in_fattura" name="provvigioni_in_fattura" />
            </div>
          </form-group>

          <pf-button type="submit" :disabled="sending" variant="primary">
            {{ $t('Invia') }}
          </pf-button>
        </form>
      </div>
    </div>
  </page>
</template>

<style>
.partner-form {
  max-width: 400px;
}
</style>

<script lang="ts" setup>
import { ref } from 'vue';
import Page from '../components/page.vue';
import FormGroup from '../components/form-group.vue';
import Toggle from '../components/toggle.vue';
import { useLoginStore } from '@/store/login';
import { http } from '../http';
import { useRouter } from 'vue-router';

const login = useLoginStore();
const router = useRouter();

const partner = ref<{
  modalita: 'whitelabel' | 'freelance' | 'whitelabel-freelance' | null,
  fatturazione_mensile: boolean,
  provvigioni_in_fattura: boolean,
}>({
  modalita: null,
  fatturazione_mensile: true,
  provvigioni_in_fattura: true,
});

const sending = ref(false);


async function send(e: Event) {
  if (!(e.target instanceof HTMLFormElement)) {
    return;
  }

  sending.value = true;

  try {
    const data = new FormData(e.target);
    await http.post('/api/become_partner/update', data);

    partner.value = {
      modalita: null,
      fatturazione_mensile: true,
      provvigioni_in_fattura: true,
    };

    await login.refresh();
  } finally {
    sending.value = false;
  }

  router.push('/');
};
</script>
