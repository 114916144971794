<template>
  <page :name="$t(`Ordine #{id}`, {id: order.id}) || $t('Ordine')" :title="$t(`Ordine #{id}`, {id: order.id})" :state="asyncComputed">
    <template #breadcrumbs>
      <router-link v-if="ref.breadcrumb" v-slot="{ navigate }" custom :to="{ name: ref.route }">
        <li>
          <a @click="navigate">{{ ref.breadcrumb }}</a>
        </li>
      </router-link>
    </template>

    <payment-report
      :result="Number($pop($route.query.res))"
      :error="$pop($route.query.err)"
      :route="ref.route"
      :link="domains.length ? null : ref.backlink"
      :success="$t('Ordine confermato')"
      :message="confirmationMessage"
      :order="order"
    />

    <div v-if="domains.length && order.stato == 'concluso'" class="transfers">
      <h3>{{ $t('Per completare la registrazione o il trasferimento di questi nomi a dominio ci occorrono alcuni dati') }}</h3>
      <pf-list-view :rows="domains" :items-per-page="0" stacked key-name="id" class="catalog">
        <template #default="{row}">
          <pf-list-item>
            <template #heading>
              <span :title="row.dominio">{{ $t('Registrazione dominio {0}', [row.dominio]) }}</span>
            </template>
            <template #additional-info>
              <pf-list-item-additional-info expandable>
                {{ $t('Inserisci i dati del registrante') }}
                <template #expansion>
                  <form-domain-registrant :domain="row" @submit="asyncComputed.order.update" />
                </template>
              </pf-list-item-additional-info>
            </template>
          </pf-list-item>
        </template>
      </pf-list-view>
    </div>

    <div v-if="hostingTransfers.length && order.stato == 'concluso'" class="transfers">
      <h3>{{ $t('Hai richiesto il trasferimento di alcuni dei servizi presenti in questo ordine') }}</h3>
      <pf-list-view :rows="hostingTransfers" :items-per-page="0" stacked key-name="id" class="catalog">
        <template #default="{row}">
          <pf-list-item>
            <template #heading>
              <span :title="row.dominio">{{ row.dominio }}</span>
            </template>
            <template #additional-info>
              <pf-list-item-additional-info expandable>
                {{ $t('Inserisci le informazioni e i dati di accesso necessari a procedere') }}
                <template #expansion>
                  <form-hosting-transfer
                    :hosting="row"
                    :ask-site-transfer="row.missing_data && row.transfer_site_required"
                    :ask-mail-transfer="row.missing_mail_data && row.transfer_mail_required"
                    @submit="asyncComputed.order.update" />
                </template>
              </pf-list-item-additional-info>
            </template>
          </pf-list-item>
        </template>
      </pf-list-view>
    </div>
  </page>
</template>

<style>
.transfers {
  margin-top: 50px;
}
</style>

<script lang="ts">
import Page from '../components/page.vue';
import PaymentReport from '../components/payment-report.vue';
import FormDomainRegistrant from '../components/form-domain-registrant.vue';
import FormHostingTransfer from '../components/form-hosting-transfer.vue';
import { setupAsyncComputed } from '@common/asyncComputed';
import { type Order, OrdersResource } from '@/resources';
import { defineComponent } from 'vue';
import { pop } from '@common/utils';
import { $t } from '@/i18n';

export default defineComponent({
  name: 'OrderResultPage',

  components: {
    Page,
    PaymentReport,
    FormDomainRegistrant,
    FormHostingTransfer,
  },

  setup() {
    return {
      ...setupAsyncComputed({
        order: {
          get(this: any) {
            return new OrdersResource().get(this.$route.params.id);
          },
          default: {
            id: 0,
            products: [],
          } as any as Order,
        },
      }),
    };
  },

  data(this: void) {
    return {
      method: null,
    };
  },

  computed: {
    ref() {
      const ref = {
        route: 'dashboard',
        backlink: $t('Vai alla dashboard'),
        breadcrumb: null as string | null,
      };

      const queryRef = pop(this.$route.query.ref);

      if (!queryRef) {
        return ref;
      }

      ref.route = queryRef;

      if (ref.route == 'catalog') {
        ref.breadcrumb = $t('Listino');
        ref.backlink = $t('Torna al listino');
      }

      return ref;
    },

    confirmationMessage() {
      return this.domains.length || this.hostingTransfers.length
        ? $t('Il tuo ordine è confermato e verrà elaborato al più presto. Per velocizzare le operazioni compila con i dati richiesti i form che trovi qui sotto.')
        : $t('Il tuo ordine è in elaborazione: riceverai a breve un contatto da parte dei nostri tecnici.');
    },

    domains() {
      return this.order.products.filter(p => p.missing_data && p.registrazione_dominio);
    },

    hostingTransfers() {
      return this.order.products.filter(p => (p.missing_data && p.transfer_site_required) || (p.missing_mail_data && p.transfer_mail_required));
    },
  },
});
</script>
