<template>
  <p v-if="showPrezzo">
    <wizard-price-tag :product="product" :product-instead="productInstead" />
  </p>
  <p v-if="showUtenti">
    {{ $t('Licenza per {0} utenti', [product.utenti]) }}
  </p>
  <input v-if="groupRequired !== undefined" type="checkbox" :checked="groupRequired" required class="required-hidden" />
  <pf-toggle
    :model-value="modelValue"
    :required="required"
    :on-value="true"
    :off-value="false"
    :off-text="offText"
    :on-text="onText"
    @update:model-value="emit('update:modelValue', Boolean($event))"
  />

  <div v-if="askQt && modelValue">
    <label>{{ $t('Quantità:') }} <x-input type="number" name="qt" required min="1" :model-value="qt" @update:model-value="emit('update:qt', Number($event))" :disabled="fixedQt" /></label>
  </div>
</template>

<style>
.required-hidden {
  position: relative;
  width: 1px;
  height: 1px;
  left: 25px;
}
</style>

<script setup lang="ts">
import { $t as _t } from '@/i18n';
import { useWizardQuestion } from '../composition';
import WizardPriceTag from '../wizard-price-tag.vue';
import { default as PfToggle } from '../../toggle.vue';
import type { ProductPriceInfo } from '../wizard.vue';
import XInput from '@/components/x-input.vue';

withDefaults(defineProps<{
  modelValue: boolean;
  required?: boolean;
  groupRequired?: boolean;
  showPrezzo?: boolean;
  showUtenti?: boolean;
  product: ProductPriceInfo;
  productInstead?: ProductPriceInfo;
  onText?: string
  offText?: string,
  askQt?: boolean;
  qt?: number;
  fixedQt?: boolean;
}>(), {
  qt: 1,
  onText: _t('Sì'),
  offText: _t('No'),
  groupRequired: undefined,
});

const emit = defineEmits<{
  (name: 'update:modelValue', v: boolean): void;
  (name: 'update:qt', v: number): void;
}>();

useWizardQuestion();
</script>
