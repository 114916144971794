<template>
  <page
    :name="$t('Rinnovo')"
    :title="`Pagamento ${isRecupero ? $t('recupero/conferma ') : $t('rinnovo ')}${
      !products || products.length > 1 ? $t('gruppo') : $t('prodotto')
    }`"
    :state="productsState"
    :empty="!products || !products.length"
  >
    <template #breadcrumbs>
      <router-link v-slot="{ navigate }" custom :to="{ name: 'products' }">
        <li>
          <a @click="navigate">{{ $t('Prodotti') }}</a>
        </li>
      </router-link>
    </template>

    <payment-report
      :result="Number($route.query.res)"
      :error="$pop($route.query.err)"
      :success="isRecupero ? $t('Recupero/Conferma servizi completato con successo') : $t('Rinnovo servizi completato con successo')"
      route="products"
      :link="$t('Torna alla lista dei prodotti')"
    >
      <template v-if="products.length == 1">
        <h4 v-for="p in products" :key="p.id">
          {{ p.nome }} [{{ p.codice }}] {{ p.dominio }}
          <template v-if="p.qt > 1"> (x{{ p.qt }}) </template>
          <template v-if="products.length > 1">
            - {{ $currency(p.importo, login.currency) }} <small>{{ $t('IVA inclusa') }}</small>
          </template>
        </h4>
      </template>

      <ul v-for="p in products" v-else :key="p.id">
        <li>
          {{ p.nome }} [{{ p.codice }}] {{ p.dominio }}
          <template v-if="p.qt > 1"> (x{{ p.qt }}) </template>
          <template v-if="products.length > 1">
            - {{ $currency(p.importo, login.currency) }} <small>{{ $t('IVA inclusa') }}</small>
          </template>
        </li>
      </ul>

      <form v-if="importoTotale > 0" method="post" action="/api/pay_product" @submit="submit">
        <fieldset>
          <input v-for="id in ids" :key="id" type="hidden" name="ids[]" :value="id">

          <payment-methods
            v-model="method"
            :totale-offerta-ivato="importoTotale"
            :currency="login.currency"
            />

          <h3 v-if="importoTotale > 0" class="subtitle">
            {{ $t('Totale: {0}', [formatCurrency(importoTotale, login.currency)])
            }}<small>{{ $t('IVA inclusa') }}</small>
          </h3>

          <pf-button type="submit" variant="primary" :disabled="!method">
            {{ $t('Conferma il rinnovo') }}
          </pf-button>
        </fieldset>
      </form>
    </payment-report>
  </page>
</template>

<style scoped>
.form-group.methods label {
  margin-right: 15px;
}
</style>

<script lang="ts" setup>
import { ref, type Ref, computed } from 'vue';
import { asyncComputedList } from '@common/asyncComputed';
import { formatCurrency } from '@common/utils';

import Page from '../components/page.vue';
import PaymentReport from '../components/payment-report.vue';
import PaymentMethods, { type Method} from '../components/payment-methods.vue';
import { useLoginStore } from '@/store/login';
import { ProductOrdersResource, type ProductOrderDetail } from '@/resources';
import { useRoute } from 'vue-router';

const login = useLoginStore();
const route = useRoute();

const ids = route.params.id.toString().split('-');
const method: Ref<Method | undefined> = ref();

const { state: productsState, result: products } = asyncComputedList(async() => {
  if (ids) {
    return new ProductOrdersResource().get(ids.join('-'));
  }
  return [] as ProductOrderDetail[];
});

const isRecupero = computed(() => products.value.length && ['scaduto', 'non rinnovare'].includes(products.value[0].stato_amministrativo));

const importoTotale = computed(() => products.value.reduce((sum, product) => sum + product.importo, 0));

function submit(e: Event) {
  if (!method.value) {
    e.preventDefault();
  }
};
</script>
