<template>
  <page :name="$t('Upgrade')" :title="$t('Upgrade Prodotto')" :state="productsState">
    <template #breadcrumbs>
      <router-link v-slot="{ navigate }" custom :to="{ name: 'products' }">
        <li>
          <a @click="navigate">{{ $t('Prodotti') }}</a>
        </li>
      </router-link>
    </template>

    <payment-report
      v-if="product"
      :result="Number($route.query.res)"
      :error="$pop($route.query.err)"
      :success="$t('Upgrade servizi confermato')"
      route="products"
      :link="$t('Torna alla lista dei prodotti')"
    >
      <h4>
        <i18n-t
          keypath="Stai effettuando l'upgrade del prodotto {0} in scadenza il {1}">
          <strong>{{ product.nome }} [{{ product.codice }}] {{ product.dominio }}</strong>
          {{ $date(product.data_fine) }}
        </i18n-t>
      </h4>

      <pf-notification v-if="product.upgradable_products.length === 0" type="danger" role="alert">
        <strong>{{ $t('ATTENZIONE') }}</strong>
        {{ $t("Non è possibile effettuare l'upgrade automatico con relativo storno per questo prodotto.") }}<br>
        <i18n-t keypath="Aprire un {0} al supporto clienti specificando l'errore per maggiore assistenza." tag="span">
          <router-link :to="{ name: 'tickets' }">
            {{ $t('ticket') }}
          </router-link>
        </i18n-t>
      </pf-notification>

      <form
        v-else-if="valoreStorno >= 0"
        method="post"
        action="/api/pay_product_upgrade"
        @submit="submit"
      >
        <fieldset :disabled="!product.id">
          <form-group :name="$t('Seleziona il nuovo prodotto')">
            <div v-for="pu in product.upgradable_products" :key="pu.id" class="radio">
              <label>
                <input v-model="idProductTo" type="radio" name="id_product_to" :value="pu.id">
                {{ pu.nome }} <small>[{{ pu.codice }}]</small> <strong>{{ formatCurrency(pu.prezzo, login.currency) }}</strong>
              </label>
            </div>
          </form-group>

          <template v-if="selected">
            <input type="hidden" name="id_migrated_from" :value="product.id">

            <pf-notification>
              {{ $t('Il prodotto "{0}" verrà disdetto', [product.nome]) }}.<br>
              {{ $t('Procederemo all\'upgrade a "{0}".', [selected.nome]) }}
            </pf-notification>

            <div style="max-width: 800px">
              <h3>{{ $t('Riepilogo:') }}</h3>
              <ul>
                <li class="row">
                  <span class="col-xs-12 col-sm-4"><strong>{{ $t('Costo upgrade (IVA esclusa)') }}:</strong></span>
                  <span class="col-xs-12 col-sm-2 value-cell">{{ formatCurrency(selected.prezzo, login.currency) }}</span>
                </li>

                <li v-if="valoreStorno > 0" class="row">
                  <span class="col-xs-12 col-sm-4"><strong>{{ $t('Storno periodo non usufruito (IVA inclusa)') }}:</strong></span>
                  <span class="col-xs-12 col-sm-2 value-cell">-{{ formatCurrency(valoreStorno, login.currency) }}</span>
                </li>

                <li v-if="totale > 0" class="row">
                  <span class="col-xs-12 col-sm-4"><strong>{{ $t('Totale (IVA inclusa)', []) }}</strong></span>
                  <span class="col-xs-12 col-sm-2 value-cell">{{ formatCurrency(totale, login.currency) }}</span>
                </li>
              </ul>
            </div>

            <payment-methods
              v-model="method"
              enable-cashback
              :totale-offerta-ivato="totale"
              :currency="login.currency"
            />

            <pf-button type="submit" variant="primary" :disabled="!enabled">
              {{ $t('Conferma l\'upgrade') }}
            </pf-button>
          </template>
        </fieldset>
      </form>
    </payment-report>
    <pf-notification v-else type="danger">
      {{ $t("Errore: Prodotto non trovato") }}
    </pf-notification>
  </page>
</template>

<style scoped>
.btn-actions .btn {
  text-align: left;
  min-width: 280px;
  width: auto;
  margin: 5px 0;
}
.btn-actions .btn.btn-primary {
  background-color: #204d74;
  border: 0;
}
.product-notice {
  margin: 0 5px 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.value-cell {
  text-align: right;
}
</style>

<script lang="ts" setup>
import { ref, computed, type Ref } from 'vue';
import { asyncComputed } from '@common/asyncComputed';
import { formatCurrency } from '@common/utils';
import Page from '../components/page.vue';
import PaymentReport from '../components/payment-report.vue';
import PaymentMethods, { type Method} from '../components/payment-methods.vue';
import { useLoginStore } from '@/store/login';
import FormGroup from '@/components/form-group.vue';
import { ProductOrdersResource } from '@/resources';
import { pop } from '@common/utils';
import { useRoute } from 'vue-router';

const route = useRoute();
const login = useLoginStore();

const method: Ref<Method | undefined> = ref();
const idProductTo: Ref<number | null> = ref(null);

const { state: productsState, result: product } = asyncComputed(async() => {
  const id = pop(route.params.id)
  if (!id) {
    return null;
  }
  const products = await new ProductOrdersResource().get(id);
  return products[0];
});

const enabled = computed(() => method.value && selected.value && product.value?.id);
const selected = computed(() => product.value?.upgradable_products.find(p => p.id == idProductTo.value));
const totale = computed(() => selected.value ? selected.value.importo : 0 - valoreStorno.value);

const valoreStorno = computed(() => product.value?.valore_storno ?? 0);

function submit(e: Event) {
  if (!enabled.value) {
    e.preventDefault();
  }
};
</script>
