<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Intestazione')">
    <div class="question-wrapper">
      <h3>{{ $t('A chi vuoi intestare il preventivo?') }}</h3>
      <toggle
        v-model="wizard.fatturaMe"
        :on-value="true"
        :off-value="false"
        :on-text="$t('Direttamente me')"
        :off-text="$t('Ad un mio cliente')"
        off-class="btn-success"
      />
    </div>

    <div v-if="!wizard.fatturaMe" class="question-wrapper">
      <h3>{{ $t('A quale cliente vuoi intestare il preventivo?') }}</h3>
      <div v-if="!ready" class="blank-slate-pf">
        <div class="spinner spinner-lg blank-slate-pf-icon" />
        <h3 class="blank-slate-pf-main-action">
          {{ $t('Stiamo caricando la tua lista clienti...') }}
        </h3>
        <p class="blank-slate-pf-secondary-action">
          {{ $t('Ancora pochi istanti e potrai continuare il tuo preventivo.') }}
        </p>
      </div>
      <ul v-else-if="wizard.customers.length > 0" class="radio-wrapper">
        <li v-for="c of wizard.customers" :key="c.id">
          <label>
            <input
              type="radio"
              name="idClienteFinale"
              :value="c.id"
              required
              :checked="wizard.customer?.id === c.id"
              @change="wizard.idClienteFinale = Number(($event.target as HTMLInputElement).value)"
            >
            {{ c.ragione_sociale }}
          </label>
        </li>
      </ul>
      <div v-if="ready && !wizard.customers.length">
        {{ $t('Nessun cliente trovato, utilizza il form qui sotto per inserire un nuovo prospect') }}
        <input type="text" required style="display: none">
      </div>
    </div>

    <div v-if="!wizard.fatturaMe" class="question-wrapper">
      <pf-button @click="openFromProspect = true"> {{ $t("Oppure crea un'azienda prospect") }}</pf-button>
      <pf-modal
        v-if="openFromProspect"
        show
        :title="$t('Inserisci un nuovo prospect')"
        confirm-button=""
        cancel-button=""
        @cancel="openFromProspect = false"
      >
        <form-prospect :key="formKey" v-model:id-prospect="wizard.idClienteFinale" modal @submit="refreshCustomers" />
      </pf-modal>
    </div>

    <div v-else class="question-wrapper">
      <h3>{{ $t("Si tratta di un'offerta di prodotti da rivendere a un cliente finale?") }}</h3>
      <!-- on è false, off è true -->
      <toggle
        v-model="wizard.notForResale"
        :on-value="false"
        :off-value="true"
        :on-text="$t('Sì')"
        :off-text="$t('No: modalità \'not for resale\'')"
      />
    </div>
  </wizard-form>
</template>

<style lang="scss" scoped>
.radio-wrapper {
  max-width: 100%;
}
@media (min-width: $screen-xs-max) {
  .radio-wrapper > li {
    width: 30%;
  }
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import Toggle from '../../toggle.vue';
import FormProspect from '../../form-prospect.vue';
import WizardForm from '../wizard-form.vue';

defineOptions({
  name: 'WizardStepClienti',
});

const props = defineProps<OUIAProps>();
const ouiaProps = useOUIAProps(props);

const { wizard, hidden, isEnabled } = useWizardStep({
  isEnabled: wizard => true,
});

const openFromProspect =ref(false);
const formKey =ref(0); // utilizzata per effettuare il reset del form

const ready = computed(() => wizard.customersState.state === 'success');

function refreshCustomers() {
  formKey.value += 1;
  wizard.customersState.update();
  openFromProspect.value = false;
}

defineExpose({
  isEnabled,
});
</script>
