<template>
  <form role="form" method="post" @submit.prevent="send">
    <input type="hidden" name="id" :value="hosting.id">

    <template v-if="askSiteTransfer">
      <h4>{{ $t('Per poter effettuare il trasferimento del sito {0}, come richiesto, abbiamo bisogno di queste informazioni e dati di accesso.', [hosting.dominio]) }}</h4>

      <form-group :name="$t('Tipo di accesso')" required>
        <p>{{ $t("Indicaci le modalità di accesso all'attuale provider di hosting") }}</p>
        <pf-select v-model="transferData.access_type" name="access_type" required>
          <option value="ftp">{{ $t('Accesso tramite FTP') }}</option>
          <option value="ssh">{{ $t('Accesso tramite SSH') }}</option>
          <option value="panel">{{ $t('Accesso al pannello di controllo (esp. cPanel, WHM, Plesk...)') }}</option>
          <option value="webaccess">{{ $t('Accesso web (esp. Aruba, Register...)') }}</option>
        </pf-select>
      </form-group>

      <form-group :name="transferData.access_type === 'ftp' || transferData.access_type === 'ssh' ? $t('Host') : $t('Url')" required>
        <p v-if="transferData.access_type === 'ftp' || transferData.access_type === 'ssh'">{{ $t("Indicaci l'indirizzo IP o il dominio per effettuare l'accesso") }}</p>
        <p v-else>{{ $t("Indicaci l'URL per accedere al pannello") }}</p>
        <x-input
          v-model="transferData.url"
          type="text"
          name="url"
          required
        />
      </form-group>

      <form-group :name="$t('Utente')" required>
        <x-input
          v-model="transferData.user"
          type="text"
          name="user"
          required
        />
      </form-group>

      <form-group :name="$t('Password')" required>
        <x-input
          v-model="transferData.password"
          type="password"
          name="password"
          required
        />
      </form-group>

      <h3>{{ $t('Database') }}</h3>
      <form-group :name="$t('Nome database')" required>
        <x-input
          v-model="transferData.dbName"
          type="text"
          name="dbName"
          required
        />
      </form-group>

      <form-group :name="$t('Utente database')" required>
        <x-input
          v-model="transferData.dbUser"
          type="text"
          name="dbUser"
          required
        />
      </form-group>

      <form-group :name="$t('Password database')" required>
        <x-input
          v-model="transferData.dbPassword"
          type="password"
          name="dbPassword"
          required
        />
      </form-group>

      <form-group :name="$t('URL di phpMyAdmin')">
        <x-input
          v-model="transferData.pmaUrl"
          type="text"
          name="pmaUrl"
        />
      </form-group>

      <form-group :name="$t('CMS')">
        <label v-for="c in cms">
          <input
            type="radio"
            name="cms"
            v-model="transferData.cms"
            :value="c"
          > {{ c }}
        </label>
      </form-group>

      <form-group :name="$t('Manutenzione')" required>
        <p>{{ $t('Per procedere con il trasferimento del sito, è necessario accordarsi sulle tempistiche e mettere il sito in manutenzione, o possiamo procedere in qualsiasi momento e senza preavviso?') }}</p>
        <label><input type="radio" v-model="transferData.maintenanceSite" name="maintenanceSite" :value="true" required> {{ $t('Sì, dobbiamo accordarci') }}</label>
        <label><input type="radio" v-model="transferData.maintenanceSite" name="maintenanceSite" :value="false" required> {{ $t('No, procedete pure in qualsiasi momento') }}</label>
      </form-group>

      <form-group :name="$t('Sottodomini e sottocartella')">
        <p>{{ $t("Indicaci tutti gli eventuali sottodomini e sottocartelle da trasferire") }}</p>
        <textarea
          v-model="transferData.subdomains"
          name="subdomains"
          :rows="5"
        />
        <p>{{ $t('IMPORTANTE: Accertati di aver inserito la lista completa di sottodomini e cartelle da trasferire per evitare la perdita di dati importanti.') }}</p>
      </form-group>
    </template>

    <template v-if="askMailTransfer">
      <h4>{{ $t('Per poter effettuare il trasferimento della posta del dominio {0}, come richiesto, abbiamo bisogno di queste informazioni.', [hosting.dominio]) }}</h4>

      <form-group :name="$t('Server IMAP')" required>
        <x-input type="text" v-model="transferData.imap" required :placeholder="$t('Server IMAP')" />
      </form-group>

      <form-group :name="$t('Caselle email')" required>
        <p>{{ $t('Inserisci tutte le caselle di posta elettronica che dobbiamo trasferire, con le relative password') }}</p>
        <div v-for="(mail, index) in transferData.mails" class="mail_list">
          <x-input type="text" v-model="mail.name" required :placeholder="$t('Casella')" :append="`@${hosting.dominio}`"/>
          <x-input type="password" v-model="mail.password" required :placeholder="$t('Password')"/>
          <pf-button :disabled="transferData.mails.length < 2" @click="removeMail(index)" small variant="danger">
            <trash-icon />
          </pf-button>
        </div>
        <p>{{ $t('IMPORTANTE: Accertati di aver inserito tutte le caselle da trasferire per evitare la perdita di dati importanti.') }}</p>
        <pf-button @click="addMail()">+ {{ $t('Aggiungi casella')}}</pf-button>
      </form-group>

      <form-group :name="$t('Tempistiche di trasferimento della posta')" required>
        <p>{{ $t('Per procedere con il trasferimento della posta, è necessario accordarsi sulle tempistiche, o possiamo procedere in qualsiasi momento e senza preavviso?') }}</p>
        <label><input type="radio" v-model="transferData.maintenanceMail" name="maintenanceMail" :value="true" required> {{ $t('Sì, dobbiamo accordarci') }}</label>
        <label><input type="radio" v-model="transferData.maintenanceMail" name="maintenanceMail" :value="false" required> {{ $t('No, procedete pure in qualsiasi momento') }}</label>
      </form-group>
    </template>

    <form-group :name="$t('Note')">
      <p>{{ $t("Scrivi qui tutte le altre informazioni che possono esserci utili per trasferire il sito") }}</p>
      <textarea
        v-model="transferData.note"
        name="note"
        :rows="5"
      />
    </form-group>

    <pf-button type="submit" variant="primary" :disabled="sending">
      {{ $t('Invia') }}
    </pf-button>{{ ' ' }}<em>* {{ $t('Campi obbligatori') }}</em>
  </form>
</template>

<style scoped>
form {
  max-width: 800px;
}
textarea {
  width: 100%;
  display: block;
}
label {
  display: block;
}

.mail_list{
  display: flex;

  .x-input{
    margin-bottom: 5px;
    padding-right: 30px;
  }
  button{
    margin-bottom: 5px;
  }
}
</style>

<script lang="ts" setup>
import { $t as _t } from '@/i18n';
import { type Ref, ref } from 'vue';
import { type HostingTransfer, HostingTransferResource } from '@/resources';
import XInput from './x-input.vue';
import FormGroup from './form-group.vue';
import TrashIcon from '@vue-patternfly/icons/trash-icon';

const props = defineProps<{
  askSiteTransfer: boolean,
  askMailTransfer: boolean,
  hosting: {
    id: number,
    dominio: string,
  };
}>();

const emit = defineEmits<{
  (e: 'submit'): void;
}>();

const cms = ['WordPress', 'Magento', 'PrestaShop', 'Joomla', _t('Altro')];

const sending = ref(false);

type HostingTransferForm = Omit<HostingTransfer, 'maintenanceSite'|'maintenanceMail'> & {maintenanceSite: boolean|undefined, maintenanceMail: boolean|undefined};

const transferData: Ref<HostingTransferForm> = ref({
  id: props.hosting.id,
  access_type: 'ftp',
  url: '',
  user: '',
  password: '',
  dbName: '',
  dbUser: '',
  dbPassword: '',
  pmaUrl: '',
  cms: '',
  maintenanceSite: undefined,
  subdomains: '',
  imap: '',
  mails: [{
    name: '',
    password: '',
  }],
  maintenanceMail: undefined,
  note: '',
});

async function send(e: Event) {
  if (!(e.target instanceof HTMLFormElement)) {
    return;
  }

  if (!confirm(_t("Stai per inviare la richiesta di trasferimento del sito e della posta elettronica. Assicurati di aver inserito tutti i dati necessari in modo corretto e completo. Confermi di voler inviare i dati inseriti?"))) {
    return;
  }

  sending.value = true;

  try {
    await new HostingTransferResource().post(transferData.value);
    emit('submit');
  } finally {
    sending.value = false;
  }
}

function addMail() {
  transferData.value.mails.push({
    name: '',
    password: '',
  });
}

function removeMail(index: number) {
  transferData.value.mails.splice(index, 1);
}
</script>
