<template>
  <div class="panel panel-default">
    <div class="panel-heading">
      <template v-if="enabled">
        <h3 class="panel-title">
          {{ $t('Riscuoti il credito maturato di {0}', [formatCurrency(total, currency)]) }}
        </h3>
        <div class="panel-subtitle">
          {{
            $t(
              'Allegaci la fattura che dovremo pagarti relativa al credito maturato, se necessario inserisci eventuali note e invia la richiesta. Il reparto amministrativo ti contatterà dandoti conferma una volta che la tua pratica verrà evasa.',
            )
          }}
        </div>
      </template>
      <template v-else>
        <h3 class="panel-title">{{ $t('Credito maturato: {0}', [formatCurrency(total, currency)]) }}</h3>
        <div class="panel-subtitle">
          {{ $t('Il credito potrà essere riscosso una volta maturati almeno {0}', [formatCurrency(collectFrom, currency)]) }}
        </div>
      </template>
    </div>
    <div class="row">
      <div class="col-md-6 col-md-push-6 dati-fatturazione">
        <h3>{{ $t("Dati per l'intestazione della fattura") }}</h3>
        <p>
          DHH Switzerland SA<br>
          Via Motta 18<br>
          CH-6830 {{ $t('Chiasso') }} – {{ $t('Svizzera') }}<br>
          {{ $t('N. IVA') }}: CHE-227.758.591<br>
        </p>
      </div>
      <div class="col-md-6 col-md-pull-6">
        <div v-if="sending" class="loading-state-pf">
          <div class="spinner spinner-lg blank-slate-pf-icon" />
          <h3 class="blank-slate-pf-main-action">
            {{ $t('Attendere. Invio in corso...') }}
          </h3>
        </div>

        <form v-else role="form" class="form-collect" @submit.prevent="send">
          <form-group class="attachment-group" :name="$t('Allega la fattura')">
            <label class="btn btn-default" :class="{'disabled': !enabled}">
              <paperclip-icon />
              {{ $t('Sfoglia') }}
              <input
                type="file"
                ref="inputAttachments"
                name="attachments"
                :disabled="!enabled"
                style="opacity: 0; position: absolute"
                @change="refreshAttachments"
              >
            </label>

            <template v-if="attachments.length">
              <span v-for="(file, index) in attachments" :key="index" class="label label-default">
                <paperclip-icon />
                {{ file.name }} ({{ $size(file.size) }})
                <a href="#" class="pf-remove-button" @click.prevent="attachments.splice(index, 1)">
                  <span class="pficon pficon-close" aria-hidden="true" />
                  <span class="sr-only">{{ $t('Rimuovi') }}</span>
                </a>
              </span>
            </template>

            <component :is="enabled ? 'a' : 'span'" v-else href="/rest/commissions_receipt" class="link">
              <form-help-tooltip :text="$t('Clicca su questo link per generare una ricevuta completa di tutte le informazioni che ci occorrono per poterti effettuare il pagamento delle provvigioni. Controlla le informazioni e inviacela poi tramite questo form.')" />
              {{ $t('Genera una ricevuta') }}
            </component>
          </form-group>

          <form-group
            :name="$t('Importo richiesto')"
            :help-tooltip="$t('Inserisci come importo l\'importo totale della fattura COMPRENSIVO DI IVA. Puoi richiedere da un minimo di {0} ad un massimo di {1}', [formatCurrency(collectFrom, currency), formatCurrency(total, currency)])"
            required
          >
            <x-input
              v-model="importo"
              name="importo"
              type="number"
              :min="collectFrom"
              :max="total"
              step="0.01"
              :disabled="!enabled"
              :placeholder="$t('Importo')"
              required
            />
          </form-group>

          <form-group :name="$t('IBAN')" required>
            <x-input
              v-model="newIban"
              name="iban"
              :disabled="!enabled"
              :placeholder="$t('IBAN per pagamento')"
              required
            />
          </form-group>

          <form-group :name="$t('Messaggio')">
            <textarea
              v-model="message"
              class="form-control"
              :disabled="!enabled"
              :placeholder="$t('Aggiungi un messaggio alla richiesta')"
              rows="2"
            />
          </form-group>
          <pf-button type="submit" variant="primary" :disabled="!attachments.length">
            <paper-plane-icon /> {{ $t('Invia la richiesta') }}
          </pf-button>
        </form>
      </div>
    </div>
  </div>
</template>

<style>
.loading-state-pf {
  margin-top: 15px;
}

.attachment-group {
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: 5px;
  }
}

.form-collect {
  max-width: 592px;
  margin: 15px;
}

.dati-fatturazione > * {
  margin-left: 15px;
}
</style>

<script lang="ts" setup>
import { ref, type Ref } from 'vue';
import { useAppStore } from '@/store/app';
import { $t as _t } from '@/i18n';
import { formatCurrency } from '@common/utils';
import { CreditDocumentsResource } from '@/resources';
import XInput from './x-input.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import PaperclipIcon from '@vue-patternfly/icons/paperclip-icon';
import PaperPlaneIcon from '@vue-patternfly/icons/paper-plane-icon';
import FormGroup from './form-group.vue';

const props = defineProps<{
  enabled: boolean;
  total: number;
  collectFrom: number;
  currency: 'CHF' | 'EUR';
  iban: string | null;
}>();

const emit = defineEmits<{
  (e: 'submit', event: Event): void
}>();

const app = useAppStore();

const sending = ref(false);
const message = ref('');
const importo = ref(props.total);
const newIban = ref(props.iban ?? '');
const attachments: Ref<File[]> = ref([]);
const inputAttachments: Ref<HTMLInputElement | undefined> = ref();

async function send(e: Event) {
  if (!inputAttachments?.value?.files || importo.value === null || newIban.value === null) {
    return;
  }

  sending.value = true;

  try {
    await new CreditDocumentsResource().save({
      tipo: 'provvigioni',
      importo: importo.value,
      iban: newIban.value,
      messaggio: message.value,
      allegati: inputAttachments?.value?.files,
    });

    importo.value = 0;
    message.value = '';
    attachments.value = [];
  } finally {
    sending.value = false;
  }

  app.toast(_t('Fattura ricevuta correttamente e credito scalato. Verrai contattato dalla nostra amministrazione per il pagamento.'), 'success');
  emit('submit', e);
};

function refreshAttachments() {
  if (!inputAttachments?.value?.files) {
    return;
  }

  for (const file of inputAttachments.value.files) {
    attachments.value.push(file);
  }
};
</script>
