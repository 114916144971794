<template>
  <x-form
    xhr
    encoding="json"
    action="/rest/my_customers"
    :submit-text="$t('Invia')"
    :submit-help="`* ${$t('Campo obbligatorio')}`"
    @submit="onSubmit"
  >
    <p>
      {{ $t(
        'Tramite questo form potrai inserire un nuovo prospect nel nostro sistema e generare offerte a lui intestate',
      ) }}
    </p>

    <billing-form embed-for="prospect" :modal="modal" />

    <h3>{{ $t('Contatto') }}</h3>
    <div class="row">
      <form-group :name="$t('Nome Contatto')" required class="col-md-6" :class="{'col-lg-4': !modal}">
        <x-input
          id="prospectFirstname"
          v-model="prospect.firstname"
          name="firstname"
          type="text"
          required
          maxlength="127"
        />
      </form-group>
      <form-group :name="$t('Cognome Contatto')" required class="col-md-6" :class="{'col-lg-4': !modal}">
        <x-input
          id="prospectLastname"
          v-model="prospect.lastname"
          name="lastname"
          type="text"
          required
          maxlength="127"
        />
      </form-group>
    </div>
    <div class="row">
      <form-group :name="$t('Email')" required class="col-md-12" :class="{'col-lg-8': !modal}">
        <x-input
          id="prospectEmail"
          v-model="prospect.email"
          name="email"
          type="email"
          required
          maxlength="127"
        />
      </form-group>
    </div>
  </x-form>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import FormGroup from './form-group.vue';
import XForm from '../components/x-form.vue';
import XInput from '../components/x-input.vue';
import BillingForm from '../components/billing-form.vue';

withDefaults(defineProps<{
  cleanFields?: boolean,
  idProspect?: number,
  modal?: boolean,
}>(), {
  cleanFields: true,
});

const emit = defineEmits<{
  (name: 'submit', e: Event|undefined, response: any): void;
  (name: 'update:idProspect', value: number): void;
}>();

const prospect = ref<{
  firstname: string,
  lastname: string,
  email: string
}>({
  firstname: '',
  lastname: '',
  email: '',
});

function onSubmit(e: Event|undefined, response: any) {
  emit('submit', e, response);
  emit('update:idProspect', response.id);
}
</script>
