<template>
   <pf-grid gutter class="configuratore-cloud">
    <pf-grid-item span="3"><cpu-icon /> {{ $t('CPU') }}:</pf-grid-item>
    <pf-grid-item span="2">
      <x-input
        type="number"
        name="cpuQtn"
        required
        :min="1"
        :max="64"
        append="Core"
        :model-value="cpuQtn"
        @update:model-value="emit('update:cpuQtn', Number($event))"
      />
    </pf-grid-item>
    <pf-grid-item span="7">
      <wizard-price-tag :product="wizard.getProdotto(wizard.codiciCloud.cpu)" :qt="cpuQtn"/>
    </pf-grid-item>

    <pf-grid-item span="3"><memory-icon /> {{ $t('RAM') }}:</pf-grid-item>
    <pf-grid-item span="2">
      <x-input
        type="number"
        name="ramQtn"
        required
        :min="1"
        :max="256"
        append="GB"
        :model-value="ramQtn"
        @update:model-value="emit('update:ramQtn', Number($event))"
      />
    </pf-grid-item>
    <pf-grid-item span="7">
      <wizard-price-tag :product="wizard.getProdotto(wizard.codiciCloud.ram)" :qt="ramQtn"/>
    </pf-grid-item>

    <pf-grid-item span="3"><volume-icon /> {{ $t('Storage') }}:</pf-grid-item>
    <pf-grid-item span="2">
      <x-input
        type="number"
        name="storageQtn"
        required
        :min="10"
        :max="5000"
        :step="10"
        append="GB"
        :model-value="storageQtn"
        @update:model-value="emit('update:storageQtn', Number($event))"
      />
    </pf-grid-item>
    <pf-grid-item span="7">
      <wizard-price-tag :product="wizard.getProdotto(wizard.codiciCloud.storage)" :qt="storageQtn"/>
    </pf-grid-item>
    <template v-if="wizard.os === 'windows'">
      <pf-grid-item span="3"><id-card-icon /> {{ $t('Licenza sistema operativo') }}:</pf-grid-item>
      <pf-grid-item span="2">
        <x-input
          type="number"
          disabled
          append="Core"
          :model-value="Math.round(cpuQtn)"
        />
      </pf-grid-item>
      <pf-grid-item span="7">
        <wizard-price-tag :product="wizard.getProdotto(wizard.codiciProdottiAddon.winServer)" :qt="Math.round(cpuQtn / 2)"/>
      </pf-grid-item>
    </template>
  </pf-grid>
  <wizard-price-tag :product="wizard.serverCloud" :label="$t('Prezzo totale server')" />
</template>

<style lang="scss" scoped>
.configuratore-cloud{
  margin: 20px 0 20px;
}
.nome_server{
  max-width: 300px;
}
</style>

<script setup lang="ts">
import XInput from '@/components/x-input.vue';
import WizardPriceTag from '../wizard-price-tag.vue';
import { useWizard } from '../composition';
import CpuIcon from '@vue-patternfly/icons/cpu-icon';
import MemoryIcon from '@vue-patternfly/icons/memory-icon';
import VolumeIcon from '@vue-patternfly/icons/volume-icon';
import IdCardIcon from '@vue-patternfly/icons/id-card-icon';

defineProps<{
  cpuQtn: number;
  ramQtn: number;
  storageQtn: number;
}>();

const emit = defineEmits<{
  (name: `update:${'cpu'|'ram'|'storage'}Qtn`, v: number): void;
}>();

const wizard = useWizard();
</script>
